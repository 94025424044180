@import '~theme/variables.less';

.content {
  height: 34px;
  background: var(--F1F4F6-333333);
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-direction: row;
}

.changer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  position: static;
  width: 167px;
  height: 26px;
  left: 142px;
  top: 4px;

  background: var(--F1F4F6-333333);
  border-radius: 8px;

  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  text-align: center;

  /* grey / 600 */

  color: var(--636E72-BCBCBC);
}

.active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 24px;

  /* gold/500 */

  background: #EFBA58;
  color: var(--empty-state-bg-color);
  border-radius: 6px;
}
