@import '~theme/variables.less';

.card {
  :global {
    .ant-card-body {
      padding-top: 16px;
    }
  }
  min-height: 360px;
  max-height: 380px;
  background-color: var(--card-bg-color);

  .title_wrapper {
    margin-bottom: 32px;

    :global {
      .ant-row {
        margin: 0 !important;
      }
      .ant-typography {
        margin-bottom: 0;
      }
    }
  }

  .positive {
    color: @positive-color !important;
  }

  .negative {
    color: @negative-color !important;
  }

  :global {
    h4.ant-typography {
      .card_title(22px);
    }
    span.ant-statistic-content-prefix {
      margin-right: 0;
    }
  }

  .middlePriceBlock {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 5px;
    margin-bottom: 4px;
  }

  .bottomPriceBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    border-top: 1px solid var(--main-border-color);
    padding-top: 24px;
    margin-top: 22px;

    > span {
      font-family: @font-family-futura;
      font-weight: 450;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
  }

  .totalReturn {
    gap: 0 5px;
    display: flex;
    flex-direction: column;
  }

  .totalReturnValue {
    display: flex;
    flex-wrap: wrap;

    > div:nth-child(1) {
      margin-right: 2px;
    }
  }

  .totalReturnExplanation {
    font-family: @font-family-roboto;
    font-size: @font-size-sm;
    line-height: 14.06px;
    text-align: end;
    margin-top: 15px;
    color: var(--total-return-explanation);
    opacity: 0.5;
  }
}
