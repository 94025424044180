.wrap{
  background: #1E1B22;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 100vh;
}
.logo{
  background: #F31112;
  display: grid;
  justify-content: center;
  align-items: center;
}
.title{
  font-family: Futura PT;
  font-size: 20px;
  line-height: 150%;
  max-width: 250px;
  text-align: center;
  color: #B2BEC3;
}
.download{
  font-family: Futura PT;
  font-size: 20px;
  line-height: 150%;

  text-align: center;

  color: #F9FAFB;
}
.anyway{
  font-family: Futura PT;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #525D61;
  margin-top: 25px;
}
