@import '~theme/variables.less';

.wrap{
  color: var(--2D3436_FFFFFF);
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin-top: 25px;
  .link{
    cursor: pointer;
    color: var(--gold-text-color);
  }
}
