@import '~theme/variables.less';

.page_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  overflow-y: auto;
}

.bg {
  background: var(--loader-bg-color);
  position: absolute;
  width: 100%;
  object-fit: cover;
  min-height: 102%;
  top: -2%;
}

.signInLeftSection {
  position: relative;
  width: 33.6%;
  height: 100%;
  background: var(--loader-container-bg-color);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 192px;
  min-height: 370px;

  > div {
    width: 100%;
  }

  & .logo {
    margin-top: 67.89px;
    text-align: center;
    height: 32.02px;
  }

  & .tagline {
    margin-top: 20px;
    text-align: center;
    height: 29px;

    path {
      fill: var(--2B2D3A-FFFFFF);
    }
  }

  & .button {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 12px;

    :global {
      .button {
        max-width: 280px;
        width: 100%;
        height: 48px;
        white-space: nowrap;
        padding: 14px 0;
        color: var(--header-sidebar-bg-color);
      }
    }
  }
}

.buttonTextSection {
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    margin-left: 6px;

    path:nth-child(1), path:nth-child(2) {
      stroke: var(--header-sidebar-bg-color);
    }
    path:nth-child(3) {
      fill: var(--header-sidebar-bg-color);
    }
  }
}

.downloadSection {
  text-align: center;
  margin-top: 12px;

  > span {
    font-size: 14px;
    line-height: 16px;
  }

  > span:nth-child(1) {
    color: var(--84949A-BCBCBC);
  }

  > span:nth-child(2) {
    cursor: pointer;
    color: #EFBA58;
  }
}

.qrCodeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;

  > div:nth-child(1) {
    color: #84949A;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.0125em;
    margin-bottom: 17px;
  }

  > div:nth-child(2) {
    border: 1px solid #CCCDD0;
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 34px;
  }

  > div:nth-child(3) {
    color: #EFBA58;
    letter-spacing: 0.75px;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    cursor: pointer;
  }
}

.footer {
  position: absolute;
  bottom: 24px;
  left: calc(50% - 95px/2 + 0.5px);
  color: var(--84949A-BCBCBC);
  font-size: 14px;
  line-height: 16px;
  height: 17px;
}

.signInRightSection {
  height: 100%;
  width: 66.6%;
  background: var(--loader-container-bg-color);
  padding: 16px 16px 16px 0;
  min-height: 370px;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: var(--login-right-section);
    border-radius: 16px;
  }

  img {
    max-width: 70%;
    height: auto;
    width: 100%;

    @media screen and (max-width: 1440px) {
      max-width: 90%;
      height: auto;
      width: 100%;
    }
    @media screen and (max-width: 872px) {
      max-width: 60vw;
      height: auto;
      width: 100%;
    }
  }
}

.changePassSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 35px;

  > div:nth-child(1) {
    font-family: @font-family-futura;
    font-weight: 450;
    font-size: 22px;
    line-height: 28px;
    color: var(--525D61-FFFFFF);
    margin-bottom: 16px;
  }

  > div:nth-child(2) {
    padding: 0 80px;
    letter-spacing: -0.0125em;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 33px;
    text-align: center;
    color: var(--84949A-BCBCBC);
  }

  > button {
    width: 280px;
    height: 48px;
  }
}
