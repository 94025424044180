@import '~theme/variables.less';

.errorText{
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  color: var(--table-header-color);
  max-width: 226px;
  text-align: center;
  margin: 0 18px;

}

span.label {
  font-family: @font-family-futura, sans-serif;
  font-size: @font-size-sm;
  line-height: @line-height-md;
  font-weight: @font-weight-500;
  text-transform: uppercase;
  user-select: none;
}

div.slider {
  width: 95%;
  margin: 0 0 40px 0;
  left: 9px;

  &:hover {
    :global {
      .ant-slider-handle {
        border: 3px solid #424A4D;
      }
    }
  }

  :global {
    .rc-slider-handle {
      background: #111;
      border: 3px solid #FAFAFA;
    }

    .rc-slider-dot {
      background: var(--FCFCFD-2D2D2D);
      border: 2px solid var(--C4C4C4-84949A);
    }

    .rc-slider-track {
      background-color: var(--424A4D-FAFAFA);
      height: 4px;
      margin-top: -0.9px;
    }

    .rc-slider-rail {
      background-color: var(--C4C4C4-84949A);
      height: 2px;
      margin-top: -0.9px;
    }
  }
}

div.input {
  width: 100%;
  font-size: @font-size-lg;
  line-height: @line-height-xxl;
  font-weight: @font-weight-400;
  border-radius: 4px;
}
:global {
  .ant-input-number-group > .ant-input-number:last-child, .ant-input-number-group-addon:last-child {
    border-radius: 0 4px 4px 0;
  }
  .ant-popover-arrow-content {
    background: var(--modal-bg-color);
  }

   .ant-input-number:hover {
     border: 1px solid var(--2d3436-FFFFFF);
     border-right: 1px solid var(--2d3436-d9d9d9);
   }
  .ant-input-number-focused {
    border: 1px solid var(--2d3436-FFFFFF);
    border-right: 1px solid var(--2d3436-d9d9d9);
    box-shadow: 0 0 0 2px var(--2D3436-919191);
    z-index: 2;
  }
}

div.dash {
  display: flex;
  justify-content: center;
}

.text-align-end {
  text-align: end;
}

.histogram {
  height: 30px;
  margin-top: 20px;
  position: relative;
  left: 9px;
  bottom: -4px;
}

:global {
  .rc-slider-mark-text:first-child {
    transform: translateX(-40%) !important;
  }
  .rc-slider-mark-text:last-child {
    transform: translateX(-60%) !important;
  }
}
