@import '~theme/variables.less';

.menu {
  max-width: 120%;
  top: 25px !important;
  overflow-y: auto;


  max-height: 80vh;
  box-shadow: 0 0 16px 0 #00000014;
  border-radius: 12px;


  :global {
    .ant-card-body {
      padding: 0;
      min-height: initial;
      background-color: var(--table-bg-color);
    }
    .ant-checkbox-wrapper {
      height: initial;
      color: var(--main-text-color);
    }
  }
}

.filter {
  .button {
    width: 108px;
    height: 24px;
    margin-left: 18px;
    padding: 0 6px 0 11px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    text-transform: none;
    border-radius: 4px;
    background-color: var(--EAEEF1-191A1C);
    border: none;
    cursor: pointer;



    img {
      fill: var(--main-text-color)
    }

    :global {
      .ant-typography {
        height: 24px;
        width: 73px;
        padding-top: 6px;
        text-align: left;

        font-size: @font-size-sm;
        line-height: @line-height-md;
        font-weight: @font-weight-500;
        color: var(--main-text-color);

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .arrow {
    margin-left: 6px;
    path {
      fill: var(--2d3436-FFFFFF);
    }
  }
}
