@import '~theme/variables.less';

.content {
  height: 32px;
  background: var(--mode-changer-bg-color);
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-direction: row;
}

.changer {
  font-family: @font-family-roboto, serif;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #636E72;
  border-radius: 6px;
  padding: 5px 20px;
  cursor: pointer;
}

.active {
  background: #2D2D2D;
  color: #fff;
}
