@import '~theme/variables.less';

.breadcrumbs {
  align-items: center;
  display: flex;

  height: 55px;

  span {
    color: var(--breadcrumps-color);
  }

  :global {
    .anticon svg {
      font-size: @font-size-lg;
      margin-right: 22px;
    }

    .ant-breadcrumb-separator {
      margin: 0 2px;
    }

    .ant-breadcrumb-link, .ant-breadcrumb-separator {
      font-family: @font-family-futura !important;
      font-style: normal !important;
      font-weight: @font-weight-500 !important;
      font-size: 22px !important;
      line-height: 28px !important;
      a {
        color: var(--breadcrumps-color);
      }
    }
  }
}
