@import '~theme/variables.less';


.not_found_container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 48px);
  position: absolute;
  width: 100%;
  left: 0;

  circle {
    fill: transparent;
  }

  .btn {
    width: 281px;
    height: 48px;
  }

 :global {
   & .ant-empty {
     text-align: center;
     justify-content: center;
     align-items: center;
     display: flex;
     flex-direction: column;
   }
 }
}

.title_container {
  margin-top: 15px;
  display: block;
}

.title {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--main-text-color);
  margin-top: 25px;
}

.subtitle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  color: var(--loader-hint-color);
}
