@import '~theme/variables.less';

.picker {
  margin: 0 10px;

  justify-content: center;
  display: flex;

  height: 32px;

  .ripples {
    position: relative;
    overflow: hidden;
    width: 32px;
  }

  .active {
    background: var(--F8E5C2-EFBA58);
    border-radius: 5px;;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: @font-size-sm;
    line-height: @line-height-base;
    align-items: center;
    text-align: center;
    color: var(--CF9322-2D2D2D);
  }

  span {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: @font-size-sm;
    line-height: @line-height-base;
    align-items: center;
    text-align: center;
    color: var(--525D61-FFFFFF);

    padding: 8px 0;
    margin: 0 2px;

    @media (max-width: 1470px) {
      margin: 0 0;
    }
    @media (min-width: 1471px) and (max-width: 1500px) {
      margin: 0 1px;
    }
    border-radius: 5px;
    cursor: pointer;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      &:not(.active) {
        background-color: var(--allocation-legend-bg-color);
      }
    }
  }
}
