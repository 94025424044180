@import '~theme/variables.less';

.loading {
  :global {
    .ant-empty {
      visibility: hidden;
    }
  }
}

.icon {
  margin-bottom: @margin-lg;
  margin-top: 2 * @margin-lg;

  path {
    fill: var(--no-trading-data-svg-background);
    stroke: var(--no-trading-data-svg-stroke);
  }
  ellipse {
    fill: var(--no-trading-data-svg-ellipse);
  }
}

.noResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: initial;
}

.empty_state_title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  display: flex;
  align-items: center;
  text-align: center;

  justify-content: center;
  color: var(--no-data-text-color);
}

.empty_state_subtitle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 8px;
  width: 218px;

  /* grey / 600 */
  justify-content: center;
  color: var(--no-data-text-color);
}
:global {
  .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0;
  }
}
.noData {
  :global {
    .ant-table-content,
    .ant-table-body {
      height: 330px;
      background: var(--card-bg-color);
      position: relative;
    }
  }
}

.sharp {
  :global {
    .ant-table-wrapper .ant-table-cell {
      height: 60%;

      &:first-child {
        padding: 5px 0 5px 25px;
      }
    }

    .ant-table {
      border-radius: 0;
    }

    .ant-table-wrapper {
      border-radius: 0;
      border: none;
    }
  }

  .table-title {
    padding: 24px 24px 12px 0;

    h4 {
      font-family: @font-family-futura;
      font-style: normal;
      font-weight: @font-weight-500;
      font-size: 22px;
      line-height: 18px;
      color: @primary-1;
    }
  }
}

:global {
  .blur {
    .ant-table {
      filter: blur(2px);
    }
  }
  .ant-table {
    background-color: var(--table-bg-color);
    border-radius: 0;
  }

  .ant-table-content {
    overflow: auto;
  }

  .ant-table-wrapper {
    .ant-table-body {
      overflow-y: auto;

      > table {
        width: 100% !important;
      }
    }

    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px;
    }

    .ant-pagination-item {
      font-family: @font-family-futura;
      font-style: normal;
      font-weight: @font-weight-400;
      font-size: 14px;
      color: @text-color;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #dcb694;
      border: none;
      color: #ffff;
    }

    .ant-table-column-sorters {
      padding: 10px;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: var(--table-hover-bg);
    }

    .ant-table-tbody > tr.hover > td {
      background: var(--table-hover-bg);
    }

    .ant-table-tbody > tr.ant-table-placeholder > td {
      background-color: var(--card-bg-color);
    }

    .ant-table-tbody > tr.ant-table-placeholder {
      height: auto;
    }

    .ant-table-cell {
      padding: @padding-xs;

      .anticon-setting {
        font-size: @font-size-lg;
      }
    }

    .ant-table-title {
      padding: 0;
      border-radius: 0;
    }

    .ant-table-thead > tr > th {
      font-family: @font-family-futura;
      font-style: normal;
      font-weight: @font-weight-500;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: var(--table-header-color);
      background-color: var(--card-bg-color);
      border-bottom: var(--isBordered);

      &:hover {
        background-color: var(--table-hover-bg);
      }

      &.ant-table-column-sort {
        font-weight: @font-weight-500;
      }
    }

    .ant-table-tbody > tr > td {
      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: @font-weight-400;
      font-size: 14px;
      line-height: 16px;
      color: var(--main-text-color);
      background-color: var(--card-bg-color);
      border-bottom: var(--isBordered);

      .ant-typography {
        color: var(--button-color);
      }
    }

    .ant-table-footer {
      padding: 0;
    }
    --isBordered: 1px solid var(--main-border-color);
  }

  .ant-table-cell-scrollbar {
    box-shadow: none;
  }
}
.table-title-color-sidebar {
  background: var(--sidebar-btn-color-active) !important;
}

.table-title-color {
  background: var(--FFFFFF-111111);
}

.table-title-history-color {
  background: var(--FFFFFF-111111);
}
.table-title {
  display: flex;

  border-bottom: var(--isBordered);

  width: 100%;

  border-radius: 0;

  justify-content: center;
  align-items: center;

  > h4.ant-typography {
    font-family: @font-family-futura;
    font-weight: @font-weight-400 !important;
    color: var(--main-text-color);
    font-size: 22px;
    line-height: 28px;
    margin-left: 24px;
    margin-bottom: 0;
  }

  > h5.ant-typography {
    font-family: @font-family-futura;
    font-weight: @font-weight-400 !important;
    color: var(--title-color);
    font-size: 18px;
    line-height: 23.91px;
    margin: 0 0 0 8px;
  }

  padding: 24px 0px;

  > .actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;

    > .action-button {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: @padding-xs @padding-md;
      cursor: pointer;

      margin: 0 10px;

      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: @font-weight-400;
      font-size: @font-size;
      line-height: 16px;
      text-align: right;
      color: @text-color-secondary;

      &:hover {
        background-color: var(--active-button-bg-color);
      }

      :global {
        .anticon {
          font-size: @font-size-lg;
        }
      }

      &.background {
        background-color: @background-color-base;
      }

      > .label {
        // margin: 0 @margin-xs;
        margin-left: @margin-xs;
        line-height: @line-height-xl;

        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }

  > svg {
    path:not(:first-of-type) {
      fill: var(--B2BEC3-BCBCBC);
    }
  }
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 45px;
}

.action-button-portfolio {
  display: flex;
  justify-content: end;
  padding-right: 24px;
  cursor: pointer;
  align-items: center;
  height: 32px;
}

.ant-typography {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 22px;
  line-height: 18px;

  color: @text-title-color;

  th.table-column-title {
    text-transform: uppercase;
    font-size: @font-size-sm;

    span {
      font-weight: @font-weight-400;
      color: @text-color-secondary;
    }
  }
}

.settingsBtn {
  -webkit-user-drag: none;
}

:global {
  .partial_loader {
    justify-content: center;
    position: absolute;
    bottom: 50px;
    z-index: 500;
    width: 100%;
    display: flex;
    left: 0;
    @media (max-width: 990px) {
      z-index: 100;
    }
  }

  .history,
  .trading,
  .fundamental {
    .ant-spin-blur {
      height: 100vh !important;
    }
  }

  .trading {
    .partial_loader {
      width: 50% !important;
    }
    .ant-table-wrapper .ant-table-thead > tr > th:hover {
      background-color: var(--table-hover-bg);
    }
  }

  .fundamental {
    .partial_loader {
      width: 100% !important;
    }
    .ant-table-wrapper .ant-table-thead > tr > th:hover {
      background-color: var(--table-hover-bg);
    }
  }
}

.blur {
  filter: blur(2px);
}

.table-footer {
  display: flex;
  justify-content: center;

  position: absolute;
  width: 100%;
  margin-top: 30px;

  .arrow-down {
    width: 38px;
    text-align: center;
    border: 1px solid @select-background;
    border-radius: 30px;
    background: @component-background;
    box-sizing: border-box;

    color: #1d1d1d;
    padding: 4px;
    font-size: @font-size-lg;
  }
}
.tableContentWidth {
  overflow-x: auto;
  position: sticky;
  bottom: -23px;
  @media (max-width: 1279px) {
    bottom: -20px;
  }
}
