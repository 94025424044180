@import '~theme/variables.less';

.mainWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 17px;
  margin-top: 24px;
}

.cardWrapper {
  border: 1px solid var(--EAEEF1_2D2D2D);
  background: var(--FFFFFF-111111);
  border-radius: 12px;
  position: relative;
  height: 360px;
  display: flex;
  flex-direction: column;
  min-width: 434px;
}

.treeMapContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}

.treeChart {
  display: block;
  height: 287px;
  width: 100%;
  padding: 0 24px;
  &.instruments {
    height: 200px;
  }
  canvas {
    min-width: 100%;
  }
}

.noResults {
  .titleName;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
}

.breadcrumbs {
  align-items: center;
  display: flex;
  margin-bottom: 13px;
  margin-left: 24px;

  span {
    font-family: @font-family-roboto;
    font-weight: @font-weight-500;
    font-size: @font-size-sm;
    line-height: @line-height-md;
    color: var(--breadcrumps-color);
  }

  :global {
    .anticon svg {
      font-size: @font-size-lg;
      margin-right: 22px;
    }

    .ant-breadcrumb-separator {
      margin: 0 2px;
    }
  }
}

.elementWrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
  padding: 9px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
}
.elementWrapperNoBorder {
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
  padding: 9px 0;
  cursor: pointer;
  border-bottom: none;
}

.excitement {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
}

.excitement_plus {
  color: @excitement-color_plus;
}

.current_bid_price {
  color: var(--525D61-E6E6E6);
  font-weight: 500;
}

.excitement_minus {
  color: @excitement-color_minus;
}

.imgAndTextWrapper {
  display: flex;
}

.wrapperText {
  margin-left: 16px;
}

.title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 18px;
  line-height: 23px;
  margin: 16px 24px 9px;
  color: var(--525D61-FFFFFF)
}

.cardContent {
  max-height: 255px;
  overflow-y: auto;
}

.titleDescr {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 14px;
  line-height: 16px;
  color: var(--2d3436-FFFFFF);
}

.titleName {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;

  /* grey / 500 */

  color: var(--84949A-B2BEC3);
}

.wrapperButton {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 24px 16px;
}


.viewAll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.viewAllButton {
  width: 100%;
  background: var(--EAEEF1-444444);
  border: 1px solid var(--EAEEF1-2D2D2D);
  height: 32px;
  border-radius: 6px;
  cursor: pointer;

  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 14px;
  color: var(--2d3436-FFFFFF);
}

.loadingName {
  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
  width: 184px;
  height: 24px;
  margin: 16px 24px;
}

.imageLoading {
  width: 32px;
  height: 32px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.chartLoading {
  width: 100%;
  height: 275px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.textLoading {
  width: 80%;
  height: 12px;
  margin-left: 24px;
  margin-bottom: 4px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.priceLoading {
  width: 60px;
  height: 12px;
  margin-bottom: 4px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}
.mainLoadingCard {
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
  padding: 9px 0;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
  &:last-child {
    border-bottom: none;
  }
}

.textWrapperAndImageLoading {
  display: flex;
  width: 100%;
}

.textWrapperLoading {
  width: 85%;
}
.buttonLoading {
  width: 100%;
  height: 32px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}
