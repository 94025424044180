@import '~theme/variables.less';

.container {
  position: relative;
  height: calc(100vh - 52px);
  overflow-y: auto;
  overflow-x: hidden;

  padding: @padding-lg 0 0;

  background-color: var(--sidebar-btn-color-active);

  h4 {
    color: var(--title-color);
    font-weight: 500;
    line-height: 28.2px;
    font-size: 22px;
    margin: 0;
  }

  .empty {
    padding-top: 43px;

    > div:nth-child(2) {
      margin-top: 18px;
    }

    .empty_text {
      text-align: center;
      white-space: pre-line;
      color: var(--84949A-BCBCBC);
      line-height: 16px;
    }
  }

  path {
    fill: var(--no-trading-data-svg-background);
    stroke: var(--no-trading-data-svg-stroke);
  }

  ellipse {
    fill: var(--no-trading-data-svg-ellipse);
  }
}

.padding0_24 {
  padding: 0 24px;
}

.storyWrap {
  margin-top: 25px;

  & > div {
    padding: 0 24px;
  }
}
