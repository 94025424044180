@import '~theme/variables.less';

.overlayWrap {
  :global {
    .ant-modal {
      padding-bottom: 0 !important;
    }
  }

}

.wrap {

  :global {

    .ant-modal-content {
      min-width: 368px;

    }

    .ant-modal-header {
      border-color: var(--setting-border-color)
    }

    .ant-modal-body {
      padding: 0;
      overflow-y: auto;

      background-color: var(--modal-bg-color2);
      min-height: 480px;
    }
  }

}

.title, .subTitle {
  font-family: @font-family-futura;
  text-align: center;
  color: var(--title-color);
}

.title {
  font-size: 22px;
  line-height: 58px;
}

.subTitle {
  font-size: 18px;
  line-height: 32px;
}

.search {
  margin: 8px 24px;
}

.list {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 18px;
  max-height: 596px;
}

.noDataSectionWrap{
  display: flex;
  justify-content: center;
  height: 354px;
  align-items: center;
}

.noDataSection {
  width: 218px;
  text-align: center;

  > svg {
    path{
      fill: var(--no-trading-data-svg-background);
      stroke: var(--no-trading-data-svg-stroke);
    }
    ellipse{
      fill: var(--no-trading-data-svg-ellipse);
    }
  }

  .noDataTitle {
    margin-top: 5px;
    font-family: @font-family-futura;
    font-size: 18px;
    line-height: 23px;
    color: var(--no-data-text-color);
  }
  .noDataSubTitle {
    margin-top: 8px;
    font-family: @font-family-roboto;
    font-size: 14px;
    line-height: 16px;
    color: var(--no-data-text-color);
  }
}
