@import '~theme/variables.less';

.errorContainer {
  background: var(--loader-bg-color);
  background: var(--error-modal-bg-picture);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-width: @screen-min-width;
  min-height: 102%;

  :global {
    & .ant-empty {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    & .ant-empty-footer {
      margin-top: 24px !important;
    }
  }
}

.maintenanceContainer {
  display: flex;
  background: var(--loader-container-bg-color);
  border: 1px solid var(--main-border-color);
  box-sizing: border-box;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  padding: 40px;
  gap: 38px;
  width: 800px;
  height: 440px;
}

.maintenanceImg {
  display: flex;
  width: 320px;
  height: 360px;
  border-radius: 12px;
  background-color: var(--main-bg-color);
  align-items: flex-end;
  justify-content: center;

  > img {
    height: 360px;
    margin-left: 10px;
  }
}

.button {
  width: 240px;
  height: 48px;

  span {
    line-height: 32px;
  }

  svg {
    Width: 14px;
    Height: 12px;
    margin-right: 8.67px;

    path {
      fill: var(--btn-text-color);
    }
  }
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
}

.title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size-xxl;
  line-height: 28px;
  text-align: center;
  color: var(--main-text-color);
  margin-bottom: 16px;
  padding: 0 40px;
}

.subTitle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: @font-size;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.0125em;
  color: var(--84949A-BCBCBC);
  margin-bottom: 24px;
}
