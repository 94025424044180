@import '~theme/variables.less';

.news:last-child, .instrumentNews:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.news, .instrumentNews {
  border-bottom: 1px solid var(--EAEEF1-191A1C);

  margin-bottom: @margin-xs;

  .tags {
    display: flex;
    flex-wrap: wrap;

    padding-bottom: @padding-xss;

    .tag {
      height: 24px;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      display: flex;
      justify-content: left;
      align-items: normal;

      font-size: @font-size-sm;
      line-height: @line-height-md;
      font-weight: @font-weight-400;

      background-color: var(--EAEEF1-5E5F61);
      color: var(--2d3436-FFFFFF);

      padding: 5px 8px;

      border: 0;
      border-radius: 4px;

      width: 100%;

      &:hover {
        background: var(--B2BEC3-303133) !important;
      }
    }

    .tag_margin {
      margin-right: 8px;
      margin-bottom: 4px;
      margin-top: 4px;
    }
  }

  .text {
    padding-bottom: @padding-xss;

    &.headline, &.instrumentHeadline {
      font-weight: @font-weight-500;

      :global {
        .ant-typography {
          color: var(--2B2D3A-FFFFFF);
          cursor: pointer;
        }

        .ant-typography-secondary {
          color: var(--84949A-BCBCBC);
          cursor: default;
          font-size: @font-size-sm;
          line-height: @line-height-base;
          font-weight: @font-weight-400;
        }
      }
    }

    &.headline {
      display: flex;
      flex-direction: column;
    }

    &.instrumentHeadline {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      > div {
        display: flex;
        color: var(--B2BEC3-BCBCBC);
        margin-left: 6px;
      }
    }

    &.shortText {
      :global {
        .ant-typography {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          font-size: @font-size-sm;
          line-height: @line-height-md;
          font-weight: @font-weight-400;
          color: var(--636E72-BCBCBC);
        }
      }
    }

    &.dateline {
      :global {
        .ant-typography {
          font-size: @font-size-sm;
          line-height: @line-height-md;
          font-weight: @font-weight-400;
          color: var(--B2BEC3-BCBCBC);
        }
      }
    }
  }
}

.updatedLabel {
  width: 54px;
  height: 18px;
  padding: 1.5px 4px;
  background-color: #EFBA58;
  border-radius: 4px;
  margin-left: 8px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
}

.newsSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
}

.instrumentNewsSection {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 14px;
}

.newsDescription {
  display: flex;
  flex-direction: column;
}

.instrumentNewsImg {
  margin-right: 16px;
  max-width: 92px;
  height: 64px;
}

.newsImg {
  margin-right: 8px;
  max-width: 48px;
  height: 40px
}

.instrumentNews {
  padding-right: 12px;
}

.blockClick {
  cursor: pointer;
}

.labelSection {
  margin-top: 3px;
}

.instrumentShortText {
  :global {
    .ant-typography {
      font-size: @font-size;
      line-height: @line-height-base;
      font-weight: @font-weight-400;
      color: var(--636E72-BCBCBC);
    }
  }
}

.newsData {
  display: flex;
}

.modalWrap {

}
