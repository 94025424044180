@import '~theme/variables.less';

.gridWrap{
  position: relative;
}
.title_wrapper {
  display: flex;
  align-items: center;
  height: 65px;
  justify-content: flex-end;
  padding: 24px 24px 24px 0;
  border-bottom: 1px solid var(--main-border-color);

  :global {
    h4.ant-typography {
      .card_title(22px);
      margin-left: @margin-lg;
      margin-bottom: 0;
      margin-right: auto;
    }
  }
}

.header_content {
  display: flex;

  &_first {
    flex: 0 0 61%;
    max-width: 61%;

    @media (max-width: 1600px) {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }

  &_second {
    flex: 0 0 39%;
    max-width: 39%;
    @media (max-width: 1600px) {
      flex: 0 0 30%;
      max-width: 30%;
    }

  }
}

.splitted_table_container {
  :global {
    .ant-card-body {
      padding: 0;
    }
  }

  .splitted_table {
    border: none;
  }
}

.popoverStyle {
  background: var(--F7F7F8-2D2D2D);
  box-shadow: -5px 0 16px -8px rgb(0 0 0 / 0.08),
              -8px 0 16px -14px rgb(0 0 0 / 0.08);
  border-bottom: 1px solid rgba(128, 128, 128, 0.1);
  border-radius: 0;
  z-index: 100;
  width: 222px;
  left: auto;
  right: 55px !important;
  :global {
    .ant-popover-content {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .ant-popover-inner-content {
      padding: 8px 12px;
    }

    .ant-popover-content > .ant-popover-arrow {
      display: none !important;
    }

    .sell_button {
      margin-right: 0;
      margin-left: 5px;
    }
    .ant-popover-inner {
      background: var(--F7F7F8-2D2D2D);
      border: none;
      box-shadow: none !important;
    }
    .buy_button {
      margin: 0 8px;
    }
  }

  .popoverActions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .priceBellIcon {
    display: flex;
    padding-left: 7px;
    cursor: pointer;
  }
}

.expandBtn, .collapseBtn {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  border-radius: 8px;

  &:hover {
    background-color: var(--F8E5C2-111111);
    border: 1px solid var(--F8E5C2-111111);
  }

  &:focus, &:active {
  background-color: var(--EAEEF1-111111);
  border: 1px solid var(--EAEEF1-111111);
  }
}

.collapseBtn, .collapseBtn:hover {
  background-color: var(--EAEEF1-111111);
  border: 1px solid var(--EAEEF1-111111);
}

.expandBtn, .expandBtn:active, .expandBtn:focus {
  background-color: var(--F7F7F8-2D2D2D);
  border: 1px solid var(--F7F7F8-2D2D2D);
  &:hover {
    background-color: var(--F8E5C2-111111);
    border: 1px solid var(--F8E5C2-111111);
  }
}

.expandArrow {
  color: var(--525D61-EFBA58);
  margin-top: 8px;
}

.button {
  height: 32px;
  width: 158px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4px 16px 4px 12px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 16px;
  gap: 8px;

  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size-sm;
  line-height: @line-height-md;
  background: var(--EAEEF1-2D2D2D);
  color: var(--button-color);

  .icon {
    //margin-left: 11px;
    display: flex;
    align-items: center;

    path {
      fill: var(--button-color);
    }
  }
}

.noShadow:after {
  background: none !important;
}

.ricMaxWidth {
  max-width: 300px;
  white-space: nowrap;
}

.noPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.cellFullWidth {
  max-width: fit-content;
  white-space: normal;
  display: flex;
  align-items: center;
  text-align: left;
  flex: 1 auto;
}
