.linkStock {
  color: var(--main-text-color)
}

.pageSubtitle {
  color: var(--525D61-84949A) !important;
}

.positivePrice {
  color: #18954A;
}

.negativePrice {
  color: #F73C30;
}

.priceWithCurrency {
  display: inline-flex;
}

.dealLink {
  text-decoration: underline;
}
