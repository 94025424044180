@import '~theme/variables.less';

:global {
  #dropdown_trading_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.wrapSelectedFilters {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
  }
}

.header {
  position: relative;
  border-bottom: 1px solid var(--main-border-color);
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: var(--B2BEC3-BCBCBC);
  padding: 12px 9px 4px 16px;

  flex-wrap: wrap;

  width: 100%;
  flex: 1;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }

  .search_text {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-right: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 8px;
    font-size: 18px;
    line-height: 23px;
    color: var(--filter-placeholder-color);
  }

  path {
    fill: var(--filter-cross-color);
  }
}

.header_icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #BDBEC2;
}

.cross {
  position: absolute;
  top: 16px;
  right: 8px;
  width: 16px;
  height: 16px;
}

.searchSection {
  margin-bottom: 9px;
}

.searchSection {
  display: flex;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
}

.search_container {
  background-color: var(--filter-container-background);
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 20px;
}

.search_icon {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: end;
  flex: 1;
  justify-self: flex-start;
  cursor: pointer;
  height: 17.06px;
  width: 17.06px;
  margin-left: 11px;
}

.search {
  margin-left: 24px !important;
  margin-right: 24px !important;
  align-items: center;
  display: flex;
  height: auto;
  background: var(--search-placeholder-background);
  border-bottom: 1px solid var(--main-border-color);

  ::placeholder {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    color: var(--B2BEC3-BCBCBC);
  }

  :global {
    .ant-input {
      font-weight: @font-weight-500;
      border-radius: 0;
      font-size: 16px;
      line-height: 22px;
      margin: 5px;

      &::placeholder {
        font-family: @font-family-roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: flex-start;
        color: var(--B2BEC3-BCBCBC);
        text-align: left;
      }

      &:placeholder-shown {
        font-family: @font-family-roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: flex-start;
        color: var(--B2BEC3-BCBCBC);
        text-align: left;
      }
    }
  }

  color: @primary-3;
}

.dropdown_empty {
  height: 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.dropdown {
  padding: 0;
  background: var(--modal-bg-color);
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.12);
  border-radius: 0;

  .content {
    overflow: auto;
    height: 450px;
    overflow-x: hidden;
    padding-top: 10px;
    padding-left: 16px;

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      line-height: @line-height-xl;
      font-weight: @font-weight-500;
      direction: ltr;
      cursor: pointer;
      max-width: 100%;
      min-width: 0;

      &:hover {
        background-color: var(--search-dd-hover);
      }
    }
  }
}

.empty_state_title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  display: flex;
  align-items: center;
  text-align: center;

  justify-content: center;
  color: var(--main-text-color);
}

.empty_state_subtitle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 8px;

  /* grey / 600 */
  justify-content: center;
  color: var(--screener-color);
}

.icon {
  margin-bottom: @margin-lg;
  margin-top: 2 * @margin-lg;

  path{
    fill: var(--no-trading-data-svg-background);
    stroke: var(--no-trading-data-svg-stroke);
  }
  ellipse{
    fill: var(--no-trading-data-svg-ellipse);
  }
}
