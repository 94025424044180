@import '~theme/variables.less';

.modalWrap{

  .errorConfirmationContent {
    max-height: calc(100vh - 600px);
    overflow-y: auto;
    padding: 0 24px;
  }
  :global{
    .ant-modal-content{
      min-width: 408px;
    }
  }
}

.parentBlocks {
  .ant-modal-body {
    padding: 24px 0;
  }
}

.card {
  padding: 9px 0 12px 0;
  &:not(:first-child){
    border-top: 1px solid var(--main-border-color);
  }
}
.footer{
  border-top: 1px solid var(--main-border-color);
  padding: 11px 24px 0 24px;
}
.header{
  justify-content: center;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
  .grayText{
    text-align: center;
  }
}
.parentBlocks{
  display: flex;
  align-items: center;

  :global{
    .ant-modal{
      padding-bottom: 0;
      max-width: 408px;
    }
    .ant-modal-body{
      padding: 24px 0;
    }
  }
  .confirmationContent{
    padding: 0 24px;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
  }
}
.headerDone{
  text-align: center;
  padding-bottom: 24px;
  img{
    margin: 9px 0;
  }
  border-bottom: 1px solid var(--main-border-color);
}
.title, .titleError {
  font-family: @font-family-futura;
  font-size: 22px;
  line-height: 28px;
  color: var(--title-color);
  text-align: center;
}
.title{
  font-weight: 500;
}
.titleError{
  font-weight: 400;
  margin-top: 8px;
}
.blackText{
  font-size: 14px;
  color: var(--main-text-color);
  line-height: 16px;
}
.grayText{
  font-size: 14px;
  color: var(--84949A-B2BEC3);
  line-height: 16px;
}
.row{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;

  > div:nth-child(1) {
    width: 80px;
  }

  > div:nth-child(2) {
    width: 180px;
    text-align: end;
  }
}
.primary16black{
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--main-text-color);
  :global {
    .ant-statistic-content {
      font-size: 16px;
      line-height: 20px;
      color: var(--main-text-color);
    }
    .ant-statistic-content-value-decimal {
      font-size: 16px;
      color: var(--main-text-color);
      line-height: 20px;
    }
    .ant-typography {
      color: var(--main-text-color);
    }
  }
}
.primary16{
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--main-text-color);
  :global {
    .ant-statistic-content {
      font-size: 16px;
      line-height: 20px;
      color: var(--main-text-color);
    }
    .ant-statistic-content-value-decimal {
      font-size: 16px;
      color: var(--main-text-color);
      line-height: 20px;
    }
    .ant-typography {
      color: var(--main-text-color);
    }
  }
}
.primary14black{
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text-color);
  :global {
    .ant-statistic-content {
      font-size: 14px;
      line-height: 16px;
      color: var(--main-text-color);
    }
    .ant-statistic-content-value-decimal {
      font-size: 14px;
      color: var(--main-text-color);
      line-height: 16px;
    }
    .ant-typography {
      color: var(--main-text-color);
    }
  }
}

.primary14{
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text-color);
  :global {
    .ant-statistic-content {
      font-size: 14px;
      line-height: 16px;
      color: var(--main-text-color);
    }
    .ant-statistic-content-value-decimal {
      font-size: 14px;
      color: var(--main-text-color);
      line-height: 16px;
    }
    .ant-typography {
      color: var(--main-text-color);
    }
  }
}
.button{
  margin: 0 auto;
  width: 224px;
}
.error{
  display: flex;
  height: 40px;
  text-align: center;
  color: #e17055;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  margin: 4px 60px 0;
}
.link{
  color: @button-main;
  &:hover{
    color: @button-main;
  }
}
.wrapActions{
  margin-bottom: 8px;
  button {
    height: 40px;
  }
}
.header {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.sub_title_new {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: var(--525D61-FFFFFF);
  margin: 24px 0 16px;
}

.error_box {
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  flex: 1;

  width: 100%;

  background: var(--error-box-bg);

  .box {
    background: #F7DBD4;
    border-radius: 4px 0 0 4px;

    display: flex;
    justify-content: center;
    align-content: center;

    img {
      margin: 10px;
      height: 15px;
      justify-self: center;
      align-self: center;
      display: flex;
    }
  }

  .e_text {
    background: rgba(247, 219, 212, 0.54);
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    justify-content: center;
    flex: 1;
    width: 100%;
    /* Orange / mistake */

    color: #E17055;
  }
}

.wrapAmount {
  border-top: 1px solid var(--EAEEF1-2D2D2D);
  padding: 20px 24px 26px 24px;
}


