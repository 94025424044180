@import '~theme/variables.less';

.wrap{
  background-color: var(--sidebar-btn-color-active);
  position: relative;
}

.header{
  padding: 16px 24px;
  height: 60px;
  border-bottom: 1px solid var(--main-separator-color);

  font-family: @font-family-futura;
  font-size: 22px;
  line-height: 28px;
  color: var(--title-color);
}

.content{
  .list{
    padding: 0 24px;
    height: calc(100vh - 50px - 60px - 64px - 80px);
    overflow-y: auto;
  }
}
.search{
  margin: 16px 24px;
}

.footer{
  margin-top: -1px;
  height: 80px;
  border-top: 1px solid var(--main-separator-color);
  padding: 16px 24px 24px 24px;
  .btn{
    height: 40px;
    width: 252px;
    margin: 0;
  }
}
.empty {
  padding-top: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:nth-child(2) {
    margin-top: 20px;
  }

  .empty_text {
    text-align: center;
    white-space: pre-line;
    color: var(--84949A-BCBCBC);
    line-height: 16px;
  }


path {
  fill: var(--no-trading-data-svg-background);
  stroke: var(--no-trading-data-svg-stroke);
}

ellipse {
  fill: var(--no-trading-data-svg-ellipse);
}
}

.padding0_24 {
  padding: 0 24px;
}

.storyWrap {
  margin-top: 25px;

  & > div {
    padding: 0 24px;
  }
}
