@import '~theme/variables.less';

.wrapper_small {
  border: none !important;
  margin-top: -50px !important;
}

.wrapper {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  margin-top: 25px;
  background: var(--card-bg-color);

  .title {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: space-between;
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
    text-align: left;
    text-transform: capitalize;
    > svg {
      path {
        fill: var(--arrow_KIDW);
      }
    }
  }

  .overview_block {
    margin-top: 20px;
  }
  .contentWrap{
    margin-top: 12px;
  }
}
