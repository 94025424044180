@import '~theme/variables.less';

.wrapper {
  display: flex;
  flex-direction: row;
  background: var(--card-bg-color);
  border: 1px solid var(--EAEEF1-2D2D2D);
  color: var(--84949A-BCBCBC);
  box-sizing: border-box;
  border-radius: 12px;
  height: 160px;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 48px;
}

.loadingWrapper {
  gap: 6px;

  .innerWrapper {
    display: flex;
    margin: 20px;
  }
}

.pricesWrapper, .chartWrapper {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.pricesWrapper {
  padding: 20px 0 20px 24px;
  margin: 4px 0;
}

.chartWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 20px;
  &.inModal {
    margin-top: -11px;
  }
}

.inModal {
  border: none;
  padding: 0 !important;
  margin: 0;
  background: inherit;
}

.chart {
  stroke-width: 0;
  cursor: pointer;
}

.legend-marker {
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  margin-right: 10px;
  background-color: #D9D9D9;
}

.totalAmount {
  margin-bottom: 8px;
  font-family: @font-family-roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: var(--2D2D2D-FFFFFF);
  padding-top: 11px;
}

.highlight {
  display: flex;
  align-items: center;
  background-color: var(--F7F7F8_2D3436);
  border-radius: 16px;
  overflow: auto;
}

.currenciesWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;

  .currencyLegend {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .legendMarkerEmpty {
    background-color: #444;
  }

  .currency, .price {
    font-family: @font-family-roboto;
    color: var(--2D3436-EAEEF1);
    align-items: center;
    line-height: 16px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
  }

  .price {
    justify-content: right;
    text-align: right;
  }
}

.loadingRow {
  grid-column: 2;
  width: 240px;
  height: 14.67px;
  margin-bottom: 4px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.loadingEmpty {
  .loadingRow;
  background: var(--FFF-333333);
}

.loadingFirst {
  .loadingRow;
  grid-row: 1;
}

.loadingSecond {
  .loadingRow;
  grid-row: 2;
}

.loadingThird {
  .loadingRow;
  grid-row: 3;
}

.loadingFourth {
  .loadingRow;
  grid-row: 4;
}

.currenciesPlaceholder, .pricesPlaceholder {
  margin-right: 10px;
  margin-top: 25px;
}

.pricesPlaceholder {
  margin-right: 15px;
}

.chartPlaceholder {
  .loadingRow;
  flex-direction: column;
  height: 100px;
  width: 100px;

}

.tooltip {
  background: var(--tooltip-background);
  color: var(--main-text-color);
  border: 1px solid var(--main-border-color);
}
