@import '~theme/variables.less';


.tableTitle, .activeTableTitle {
  font-size: 12px;
  line-height: 14px;
  font-family: @font-family-futura;
  color: var(--title-financial-noActive-color);
}
.activeTableTitle {
  color:var(--btn-text-color);
}

.collapseAnimation {
  transition: all 0.2s ease-out;
}

.tableTitleSection, .activeTableTitleSection {
  display: flex;
  justify-content: space-between;
  background: var(--button-bg-etf);
  padding: 9px 12.14px 9px 16px;
  cursor: pointer;

  > svg {

    path {
      fill: var(--arrow_financials);
    }
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
  }

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.wrapperForSwitchers {
  display: flex;
  justify-content: space-between;
  padding: 0.85em 1.5em 0.85em 3.5em;
  width: 100%;
}

.tableTitleSectionStylesEnd {
  display: flex;
  justify-content: space-between;
  background: var(--button-bg-etf);
  padding: 9px 12.14px 9px 16px;
  cursor: pointer;
  > svg {

    path {
      fill: var(--arrow_financials);
    }
  }
  border-radius: 0 0 6px 6px;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.activeTableTitleSectionEnd {
  display: flex;
  justify-content: space-between;
  background: #EFBA58;
  padding: 9px 12.14px 9px 16px;
  cursor: pointer;

  > svg {
    transform: rotate(180deg);

    path {
      fill: var(--trading-container-background);
    }
  }
}

.activeTableTitleSection {
  background: #EFBA58;

  > svg {
    transform: rotate(180deg);

    path {
      fill: var(--trading-container-background);
    }
  }
}

.emptyDiv {
  width: 135px;
}

.bodyWrapper {
  padding: 9px 0 9px 20px;
  border-left: 1px solid var(--main-border-color);
  border-right: 1px solid var(--main-border-color);
}

.bodyWrapperEnd {
  padding: 9px 0 9px 20px;
  border-left: 1px solid var(--main-border-color);
  border-right: 1px solid var(--main-border-color);
  border-bottom: 1px solid var(--main-border-color);
  border-radius: 0 0 6px 6px;
}

.checkboxText {
  font-family: @font-family-roboto;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;

  display: flex;
  align-items: center;
  margin-left: 11px;
  color: var(--2D3436_FFFFFF);
}

.reportDateText {
  font-family: @font-family-roboto;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--text-secondary-color);
  justify-content: flex-end;
}

.checkboxWrap {
  display: flex;
  align-items: center;
}
