@import '~theme/variables.less';

.gridWrap{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: calc(100vh - @header-height);
  tr{
    cursor: pointer;
  }
}
