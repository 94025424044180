@import '~theme/variables.less';

.recommendationContainer {
  display: flex;
  flex-direction: column;

  background: var(--card-bg-color);
  border: 1px solid var(--main-border-color);
  border-radius: @border-radius-base;

  margin-top: @margin-lg;
  padding: 16px 24px 37px;
}

.recommendationTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:nth-child(1) {
    font-size: 18px;
    line-height: 23.08px;
    font-family: @font-family-futura;
    color: var(--title-color);
  }
}

.gaugeChartContainer {
  display: flex;
  justify-content: center;
  margin-top: 45px;
}

.gaugeChartContainer > div {
  position: relative;
}

.gaugeChartWithoutArrow > svg > g > g:last-child {
  display: none;
}

.gaugeChart > svg > g > g:last-child {
  path {
    fill: var(--estimate-arrow-color);
  }
  circle {
    fill: var(--estimate-arrow-color);
  }
}

.recommendationInfo {
  margin-top: 3px;
}

.targetPrice, .targetDistance {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.targetPrice {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--tradingDetails-overview-border-color);

  > span:nth-child(1) {
    font-size: 14px;
    line-height: 14px;
    font-family: @font-family-futura;
    color: #84949A;
  }

  > span:nth-child(2) {
    font-family: @font-family-roboto;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--form-text-color);
  }
}

.targetDistance {
  margin-top: 13px;

  > span:nth-child(1) {
    font-size: 14px;
    line-height: 14px;
    font-family: @font-family-futura;
    color: #84949A;
  }

  > span:nth-child(2) {
    font-family: @font-family-roboto;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
}

.strongSellContainer, .sellContainer, .holdContainer, .buyContainer, .strongBuyContainer {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  font-weight: @font-weight-500;
}

.strongSellContainer {
  left: -95px;
  bottom: 17px;
  color: #84949A;
  height: 16px;

  > span:last-child {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.sellContainer {
  color: #84949A;
  height: 16px;
  left: -15px;
  top: 10px;

  > span:last-child {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.holdContainer {
  color: #84949A;
  height: 16px;
  top: -20px;
  left: 85px;

  > span:last-child {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.buyContainer {
  color: #84949A;
  height: 16px;
  right: -35px;
  top: 10px;

  > span:last-child {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.strongBuyContainer {
  color: #84949A;
  height: 16px;
  bottom: 17px;
  right: -117px;

  > span:last-child {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.active {
  color: var(--estimate-option-color);
  font-weight: @font-weight-500;

  > span:last-child {
    svg {
      path {
        fill: var(--estimate-option-color);
      }
    }
  }
}
