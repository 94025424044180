@import '~theme/variables.less';

.search {
  min-width: initial;
  width: 100%;
  margin: 0;
  -webkit-user-select: none;
}

div.tooltip {
  :global {
    .ant-tooltip-inner {
      width: inherit;
    }
  }
}
