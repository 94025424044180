@import '~theme/variables.less';

.valuationContainer {
  display: flex;
  flex-direction: column;

  background: var(--card-bg-color);
  border: 1px solid var(--main-border-color);
  border-radius: @border-radius-base;

  margin-top: @margin-lg;

  padding: 16px 24px 24px 24px;
}

.title {
  font-size: 18px;
  line-height: 23px;
  font-family: @font-family-futura;
  color: var(--title-color);
  margin-bottom: 25px;
}

.valuationSection {
  width: 100%;

  > div:not(:last-child) {
    border-bottom: 1px solid var(--tradingDetails-overview-border-color);
    margin-bottom: 9px;
  }
}

.valuationItem {
  font-family: @font-family-futura;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  > span:nth-child(1) {
    font-size: 14px;
    line-height: 14px;
    color: #84949a;
    width: 90px;
  }

  > span:nth-child(2) {
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 14px;
    color: var(--main-text-color);
    font-family: @font-family-roboto;
  }

  > span:nth-child(3) {
    text-align: center;
    width: 90px;
    color: #84949A;
    font-size: 12px;
    line-height: 14px;
    font-weight: @font-weight-500;
    font-family: @font-family-roboto;
  }
}
