@import '~theme/variables.less';

.row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.info{
  margin-top: 10px;
  display: grid;
  grid-row-gap: 8px;
  padding-right: 1px;
  color: var(--main-text-color);
}
.infoRow{
  font-size: 11px;
  line-height: 13px;
  color: var(--table-column-sorter-arrow-color-active);
}
.availableAmount{
  font-size: 14px;
  color: var(--main-text-color);
}
.totalAmount{
  font-size: 14px;
  color: var(--main-text-color);
}
.valueAmount{
  color: var(--main-text-color);
  :global {
    .ant-statistic-content {
      color: var(--main-text-color);
      font-size: 14px;
      line-height: 16px;

      .ant-statistic-content-value-decimal {
        color: var(--main-text-color);
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
.valueTotal{
  color: var(--main-text-color);
  line-height: 22px;
  font-size: 16px;
  :global {
    .ant-statistic-content {
      color: var(--main-text-color);
      font-size: 16px;
      line-height: 22px;

      .ant-statistic-content-value-decimal {
        color: var(--main-text-color);
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.wrapConfirm{
  margin-right: 1px;
  margin-top: 16px !important;
  margin-bottom: 0 !important;
  :global{
    .button{
      height: 40px;
      width: 100%;
      margin: 0;
    }
  }
}

