@import '~theme/variables.less';

.mainWrapper {
  display: grid;
  //width: 60%;
  grid-template-columns: 49% 49%;
  grid-gap: 16px;
  margin-top: 24px;
}

.mainFullWrapper {
  display: grid;
  //width: 60%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-top: 24px;
}


.newsContainer {
  //width: 412px;
  height: 180px;
  border-radius: 6px;
  cursor: pointer;
}

.headline {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */


  /* just white */

  color: #FFFFFF;

  margin-bottom: 4px;
}

.shorttext {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* just white */

  color: #FFFFFF;

  width:100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin: 0 24px;
  padding-bottom: 16px;
}

.emptyNews {
  background: var(--card-bg-color);
  border-radius: 12px;
  border: 1px solid var(--main-border-color);
  margin-top: 24px;
  height: 388px;
  padding: 16px 24px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyText {
  color: var(--84949A-BCBCBC);
}

:global {
  .ant-empty-image {
    height: auto;
  }
}

  .empty_img {
    path{
      fill: var(--no-trading-data-svg-background);
      stroke: var(--no-trading-data-svg-stroke);
    }
    ellipse{
      fill: var(--no-trading-data-svg-ellipse);
    }
}

.mainWrapperLoading {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-top: 24px;
}

.skeletonWrapper {
  height: 180px;
  border-radius: 6px;
  cursor: pointer;
  background: var(--EAEEF1-2D2D2D);
}
