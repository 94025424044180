@import '~theme/variables.less';

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 160px;
  }

  .cancel_button {
    color: @normal-color;
  }
}
