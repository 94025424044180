@import '~theme/variables.less';

.price {
  &.title {
    line-height: @line-height-xl;
    font-size: @font-size-lg;
    font-style: @font-style;
    font-weight: @font-weight-500;
    color: var(--main-text-color);
    white-space: nowrap;
  }


  &.big {
    color: var(--main-text-color);
    font-size: @font-size-xxl;
  }
}
