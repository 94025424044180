@import '~theme/variables.less';

.notification {
  width: 296px;
  padding: 16px 20px;
  right: 40px;
  border-bottom: 1px solid var(--main-separator-color);
}
.logo{
  display: flex;
  margin-bottom: 5px;
}
  .notificationBlock {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
    color: #84949A;
  }

  .alertLevel {
    margin-left: 10px;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 14px;
    line-height: 16.41px;
    color: var(--allertStock-color);
    width: 189px;
  }

  .notificationWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .mainWrapper {
    margin-top: 34px;
  }

  .priceWrapper {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 16px;
    word-spacing: -2px;
    text-align: right;
    color: #EFBA58;
  }

  .textWrapper {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: var(--allert-value);
  }

  .viewInstr {
    cursor: pointer;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    justify-content: end;
    margin-top: 6px;
    color: #EFBA58;
  }


