@primary-color: #2d3436;
@primary-1: #525d61;
@primary-2: #8D8E95;
@primary-3: #b2bec3;
@primary-4: #636e72;
@normal-color: #dcb695;
@color-black: #000000;
@component-background: #ffffff;
@background-color-base: #f9fafb;
@text-color: @primary-color;
@text-color-secondary: #84949a;//
@select-background: #eaeef1;
@semi-white: #eaeef1;
@blue: #0066FF;
@positive-color: #18954A;
@negative-color: #F73C30;
@border-focus: #e4c7ad;
@border-color: #e2e5ed;
@main-background-color: #f9fafb;
@error-message-color: #e17055;
@background-color-gray: #f1f4f6;
@free-trade-hover-color: #2ABB97;

@line-color: #eaedf3;

@main-background-color: #f9fafb;

@text-title-color: #525d61;

@checkbox-color: @positive-color;
@excitement-color_plus: @positive-color;
@excitement-color_minus: @negative-color;

@button-main: #EFBA58;
@button-secondary: #CF9322;
@button-main-shadow: 0 4px 8px rgba(220, 182, 149, 0.24);
@button-main-hover: #F3CE8A;
@button-main-disabled: #E6E6E7;
@button-text-color: #fdfcfa;
@button-label-hover: #FBF1DD;
@button-label-disabled: #CCCDD0;

@screen-min-width: 1280px;

@header-height: 48px;

@font-size: 14px;
@font-size-lg: 16px;
@font-size-sm: 12px;
@font-size-xl: 18px;
@font-size-xxl: 22px;
@font-size-h2: 24px;
@line-height-h2: 24px;
@line-height-h4: 12px;
@font-family-roboto: 'Roboto', sans-serif;
@font-family-futura: 'Futura PT', sans-serif;
@font-style: normal;
@font-weight-400: 400;
@font-weight-500: 500;
@font-weight-600: 600;
@font-weight-700: 700;
@line-height-sm: 12px;
@line-height-md: 14px;
@line-height-base: 16px;
@line-height-lg: 18px;
@line-height-xl: 22px;
@line-height-xxl: 24px;
@line-height-xxxl: 28px;

@border-radius-base: 12px;
@border-radius-lg: 16px;

@padding-lg: 24px;
@padding-md: 16px;
@padding-sm: 12px;
@padding-xs: 8px;
@padding-xss: 4px;

@margin-lg: 24px;
@margin-md: 16px;
@margin-sm: 12px;
@margin-xs: 8px;
@margin-xss: 4px;

@hover-test-color: #f7f7f7;

@scroll-track-bg: #dfe6e9;

@header-height: 48px;

.card_title(@font-size) {
  font-family: 'Futura PT', sans-serif;
  font-size: @font-size;
  font-weight: 500;
  line-height: 23.08px;
  color: var(--title-color);
}
