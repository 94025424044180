@import '~theme/variables.less';

.checkbox, .disabledCheckbox {
  padding: 8px;

  &:hover {
    background-color: var(--F4F5F6-444444);
  }

  :global {
    .ant-checkbox {
      height: auto;
    }
    .ant-checkbox-wrapper {
      position: relative;
    }
    .ant-checkbox-wrapper-disabled {
      cursor: pointer;
    }
    .ant-checkbox-disabled + span {
      cursor: pointer;
      color: var(--main-text-color);
    }
  }
}

.disabledCheckbox {
  :global {
    .ant-checkbox {
      opacity: 0;
    }
  }
}

.loader {
  position: absolute;
  top: -8px;
  left: -12px;
}

.mainWrapper {
  padding: 4px 0;
}
