@import '~theme/variables.less';

.allocationWrapper {
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.optimizedPortfolio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0 16px;
  gap: 32px;
  background: var(--FFFFFF-111111);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: var(--EAEEF1-2D2D2D);
  border-radius: 0 0 12px 12px;
}

.optimizedPortfolioAllocation, .performanceSummaryWrapper, .monteCarloWrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 24px;
  width: 100%;
}

.titleAndText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: start;
  gap: 8px;
  padding: 0 24px;
}

.chartContainer {
  position: relative;
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--525D61-EAEEF1);
}

.explanationText {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--84949A-BCBCBC);
}

.description {
  font-size: @font-size-sm !important;
  line-height: 12px !important;
  color: var(--2D3436-EAEEF1) !important;
}

.name {
  color: var(--text-secondary-color) !important;
  line-height: 12px !important;
}

.noPadding {
  padding: 0 !important;
}

.allocationTableWrapper {
  width: 85%;

  &.withBorder {
    border-bottom: 1px solid var(--main-border-color);
  }
}

.chart {
  stroke-width: 0;
  cursor: pointer;
}

.pieChart {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main-border-color);
  border-right: 0;
}

.legend-marker {
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  margin-right: 10px;
}

.customLegend, .customLegendMonteCarlo {
  display: grid;
  text-align: center;
  justify-items: center;
  align-items: flex-end;
  grid-template-columns: 0.4fr 0.4fr 0.4fr 1fr;
  grid-gap: 8px;
  margin-bottom: 5px;
  max-height: 232px;
  overflow: auto;
}

.customLegendMonteCarlo {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.customLegendText {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: var(--84949A-BCBCBC);
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.performanceSummaryTableWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.performanceTablePadding {
  padding-right: 30px !important;
}

.tooltip {
  background: var(--tooltip-background);
  color: var(--main-text-color);
  border: 1px solid var(--main-border-color);
}

.link{
  user-select: text;
  text-decoration: underline;
}
