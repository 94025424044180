@import '~theme/variables.less';

.bordered {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  margin-top: 24px;
  padding: 16px 24px 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--card-bg-color);

  .title {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
    text-align: left;
    text-transform: capitalize;
  }
}
