@import '~theme/variables.less';

.wrapContent{
  padding: 16px 0;
  display: flex;
  justify-content: center;
}
.legendContent{
  display: flex;
  grid-gap: 12px;
  grid-template-columns: min-content min-content min-content;
  .legendItem{
    width: 120px;
    z-index: 2;
    padding: 6px 8px;
    height: 26px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    font-family: @font-family-futura;
    font-size: 12px;
    line-height: 14px;
    color: var(--button-hover-text-color);
  }
}
.chartWrap{
  z-index: 1;
  height: 320px;
  position: relative;
}
.chart{
  position: absolute;
  top: -99px;
  left: 50%;
  transform: translateX(-50%);
}
.row{
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  font-family: @font-family-futura;
  font-size: 14px;
  &.bordered{
    border-bottom: 1px solid var(--main-border-color);
  }
  .label{
    z-index: 2;
    color: var(--widget-table-lable);
    line-height: 14px;
  }
  .value{
    font-family: @font-family-roboto;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-raiting-color);
  }
}









