@import '~theme/variables.less';

.title {
  :global {
    h4.ant-typography {
      color: var(--title-color);
      font-weight: @font-weight-400;
      font-size: @font-size-xxl;
      line-height: @line-height-xxxl;
    }
  }
}

.container {
  :global {
    .ant-modal-header {
      border-color: var(--setting-filter-border-color);
    }
    .ant-modal-body {
      max-height: 482px;
      padding: 20px 27px 10px;
      overflow-y: auto;

      @media (max-width: 1440px) {
        max-height: 330px;
      }

      background-color: var(--modal-bg-color);
    }
    .ant-checkbox-disabled+span{
     color:var(--525D61-FFFFFF);
    }
    .ant-checkbox-checked::after {
      border: none;
    }
    .ant-modal-content {
      background-color: var(--modal-bg-color);
    }
    .ant-modal-footer {
      border-color: var(--setting-filter-border-color);
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: #d9d9d9;
    }
  }
  .wrapper {
    margin-bottom: 14px;

    > label {
      height: 18px;
      color: var(--2d3436-FFFFFF);
    }
  }
}

.search_container {
  .search {
    height: 32px;

    :global {
      .ant-input-suffix {
        .ant-input-clear-icon {
          display: none;
        }
      }
    }
  }
}
