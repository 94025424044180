@import '~theme/variables.less';

.card {
  margin: 16px 24px 16px 12px;
  border: 1px solid @button-main !important;
  box-shadow: 0 6px 12px 0 rgba(239, 186, 88, 0.56);
  min-height: 192px;
  min-width: 300px;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .container {
    height: 74px;
    display: flex;
    flex-direction: column;


    &:first-child {
      border-bottom: 1px solid var(--main-border-color);

      > div {
        height: 24px;

        &:first-child {
          margin-bottom: 9px;
        }
      }
    }

    &:last-child > div {
        margin-top: 9px;
      }

    .caption {
      color: var(--table-header-color);
      font-weight: @font-weight-400;
      font-size: @font-size;
      line-height: 16.41px;
    }
  }
}
