@import '~theme/variables.less';

.filters {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}

.filter_wrapper {
  padding: 0 10px;
  flex-direction: row;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.filter_wrapper_with_save_btn{
  display: grid;
  grid-template-columns: 1fr min-content;
}

.search_input {
  width: '100%';
  margin: 0 !important;
  padding: 0 !important;
}

.inner {
  display: flex;
}

.searchbar {
  min-width: 97%;
  min-height: 30px;

  margin: 0!important;
  padding: 0!important;

  background-color: transparent;
  display: flex;
  justify-content: center;

  cursor: text;

  &.background {
    background-color: @background-color-base;
    border: 1px solid @border-color;
    box-shadow: none;
    border-radius: 4px;
  }

  &.bordered {
    .ant-input-affix-wrapper {
      border-radius: 5px;
    }
  }

  :global {
    .ant-input-suffix {
      .ant-input-clear-icon {
        display: none;
      }
    }
  }

  &.magnifier {
    :global {
      .ant-input-suffix {
        .ant-input-clear-icon {
          display: none;
        }
      }
    }
  }

  :global {
    .ant-input-affix-wrapper {
      border: none;
      background-color: transparent;
      font-size: 18px;
      margin-left: 5px !important;

      .ant-input {
        background-color: transparent;
        margin-left: 0 !important;
        margin-right: 0 !important;
        color: var(--main-text-color);
      }
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
  }
}

div.tooltip {
  z-index: 10001;

  :global {
    .ant-tooltip-inner {
      width: max-content;
    }
  }
}
