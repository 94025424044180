
@import '~theme/variables.less';

.tag {
  border-radius: @border-radius-base;
  padding: 0 @padding-lg;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size;
  line-height: @line-height-base;
  color: @primary-2;

  span {
    display: flex;
    svg{
      height: 15px;
    }
  }

  .content{
    display: flex;
    align-items: center;
    padding: 8px 0;
    gap: 11px;
  }

  &.border {
    border: 1px solid var(--screener-color);
    box-sizing: border-box;

    span {
      color: var(--2D3436-919191);
    }
  }

  &.active {
    &.border {
      border: 1px solid var(--screener-active-bg-color);

    }
    background-color: var(--screener-active-bg-color);
    svg{
      path{
        fill: var(--screener-active-color);
      }
    }
  }
}

.wrapDropdown{
  padding-left: 10px;
}

.textContent {
  color: var(--screener-color);
}

.textContentActive {
  color: var(--screener-active-color);
}
