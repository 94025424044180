@import '~theme/variables.less';

.modalWrap{
  :global{
    .ant-modal-content{
      min-width: 328px;
    }
    .ant-modal-body{
      padding: 0;
    }
    .ant-modal-close{
      display: none;
    }
  }
}

.title{
  font-family:@font-family-futura;
  font-size: 22px;
  line-height: 58px;
  text-align: center;
  color: var(--title-color);
}
.info{
  font-family: @font-family-roboto;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text-color);
  text-align: center;
  margin: 16px 20px;
}

.link{
  user-select: text;
  color: @button-main;
  &:hover{
    color: @button-main;
  }
}
.btnWrap{
  padding: 24px;

  button{
    height: 48px;
    width: 100%;
    margin: 0;
  }
}
