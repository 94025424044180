@import '~theme/variables.less';

.tr {
  display: flex;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
  width: 100%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buy {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-700;
  font-size: 14px;
  line-height: 16px;
  color: #18954A;
}

.sell {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 16px;
  color: #F73C30 ;
}

.error_box {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin-bottom: 12px;
  background: var(--error-box-bg);

  .box {
    background: #F7DBD4;
    border-radius: 4px 0 0 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;

    img {
      margin: 10px;
      justify-self: center;
      align-self: center;
      display: flex;
    }
  }

  .e_text {
    background: rgba(247, 219, 212, 0.54);
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    justify-content: center;
    flex: 1;
    width: 100%;
    /* Orange / mistake */

    color: #E17055;
  }
}

.header {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.symbol {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 7px;
}

.title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 22px;
  line-height: 28px;
  color: var(--title-color);
  margin-bottom: 0;
}

.title_container {
  display: flex;
  flex: 1;
}

.price_container {
  display: flex;
  justify-content: space-between;
  flex: 1;

  span {
    display: flex;
    align-items: center;
    font-family: @font-family-roboto;
    font-weight: @font-weight-400;
    font-size: 16px;
    line-height: 22px;
    color: var(--2D3436_FFFFFF);
  }
}

.currency {
  color: var(--2D3436_FFFFFF);
}

.sub_title {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: @text-color-secondary ; //
}

.sub_title_new {
  font-family: 'Futura PT', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23.08px;
  text-align: center;
  color: var(--trading-ticket-title);
  margin: 24px 0 16px;
}

.order {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: @text-color-secondary; //
  display: flex;
  margin: 5px 0;
  justify-content: flex-end;
}

.error_text_sumCashThreshold {
  color: #E86E72;
  display: block;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.error_text_top {
  margin-top: 8px;
}

.error_text_bottom {
  margin-bottom: 8px;
}

.error_text {
  color: #E86E72;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.error_text_small {
  color: #E86E72;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.error_input {
  border: 1px solid #E17055 !important;
}

.got_it {
  margin-top: 5px;
  color: var(--trading-ticket-title);
  width: 100%;
}

.placeholder {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: @text-color-secondary;
  display: contents;

  div:first-child {
    margin-top: 8px;
    a {
      color: @normal-color;
    }
  }
  div:last-child {
    margin-top: 8px;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.balance {
  margin: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  color: var(--84949A-BCBCBC);
}

.quantity {
  font-family: @font-family-roboto;
  display: block;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  color: var(--table-header-color);
  margin: 8px 0;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.total_title {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-700;
  font-size: 14px;
  line-height: 16px;
  color: var(--636E72-BCBCBC);
}

.total_price {
  display: flex;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-700;
  font-size: 16px;
  line-height: 22px;
  color: var(--main-text-color);
}

.amount, .quantityAmt, .portfolioName {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text-color);
}

.date {
  width: 100px;
  text-align: right;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  color: var(--trading-ticket-title);
}

.input {
  :global {

    .ant-input-affix-wrapper {
      background: var(--FFFFFF-191A1C);
      border: 1px solid var(--DFE6E9-525D61);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px 0 10px 16px;
      font-size: 16px;
    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      outline: none;
      box-shadow: none;
    }

    .ant-input {
      border-radius: 0 !important;
      height: 20px;
      background: var(--FFFFFF-191A1C);
      color: var(--2D2D2D-FFFFFF);
    }

    .anticon {

      :first-child {
        margin: 0 10px;
      }

      color: @button-main;
      cursor: pointer;
      font-size: 20px;
    }
  }
}

.instrument_currently_disabled {
  color: #E86E72;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 20px;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.operation_type {
  color: var(--table-header-color);
  font-family: @font-family-roboto;
  font-weight: @font-weight-400;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
}

.error_body {
  margin-top: 8px;
  text-align: center;
}

.notification {
  width: 296px;
  height: 64px;
  border-radius: 4px;
  padding: 16px 20px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  margin-right:40px;

  .logo {
    border-radius: 8px;
  }

  :global {
    .ant-notification-notice-with-icon {
      display: flex;
      align-items: center;
      gap: 5px;

      .ant-notification-notice-message {
        font-size: 14px;
        line-height: 16.41px;
        margin: 0;
        padding: 0;
      }
      .ant-notification-notice-description {
        display: none;
      }
      .ant-notification-notice-icon {
        position: static;
        margin: 0;
      }
    }
  }
}

.portfoliosWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
  border-top: 1px solid var(--EAEEF1-2D2D2D);
}

.portfolio {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;

  /* grey / 600 */

  color: var(--636E72-BCBCBC);
}

.portfolioValue {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 16px;
  line-height: 22px;

  /* Gray / 900 */

  color: var(--2d3436-FFFFFF);
}

.price_title {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 24px;

  color: var(--636E72-BCBCBC);
}

.parentBlocks {
  :global {
    .ant-modal-body {
      padding: 24px 30px 16px;
    }
  }
}

.freeTradeButton {
  background-color: @positive-color;

  &:not(:disabled) {
    &:hover, &:active, &:focus {
      background-color: @free-trade-hover-color !important;
    }
  }
}

.freeTradeRibbon {
  position: absolute;
  top: -20px;
  left: -20px;
}
