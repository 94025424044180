@import '~theme/variables.less';



.title {
  height: 24px;
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 18px;
  line-height: 23px;
  color: var(--main-text-color);
  text-align: center;
  margin-bottom: 32px;
}

.text {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 14px;
    line-height: 16.5px;
    color: var(--title-color);
    text-align: center;
    margin-bottom: 16px;
}

.input {
  margin-bottom: 16px;
  :global {
    .ant-input {
      height: 40px;
      border-radius: 4px !important;
      outline: none;
      box-shadow: none;
    }

  }
}
.controls{
  button {
    font-size: 12px;
    height: 42px;
  }
}

.wrapSaveAs {
  margin-top: 5px;

  .save_as {
    margin: 0;
    font-size: 11px;
    height: 28px;
    white-space: pre;
    padding: 7.5px 13.5px;
    border-radius: 4px;

    &:disabled {
      background: #FFFFFF !important;
      color: @button-label-disabled !important;
      border: 1px solid @button-label-disabled !important;
    }
  }
}


