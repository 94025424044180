@import '~theme/variables.less';

.wrapper_small {
  border: none !important;
  margin-top: -50px !important;
}

.wrapper {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
  background: var(--card-bg-color);
  margin-top: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
    text-align: left;
    text-transform: capitalize;
  }

  .swicher_wrapper {
    display: flex;
    cursor: pointer;
    margin-right: 8px;
  }

  .swicher_title {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 8px;

    /* gold/500 */

    color: #EFBA58;
  }

  .switcherTitle {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    align-items: center;

    /* grey/900 */

    margin-left: 15px;
    color: var(--main-text-color);
  }
}

.ticksColor {
  color: var(--main-text-color);
}

.switchBody {
  background: rgba(255,255,255, .26);
}

.chartView {
  margin-top: 24px;
}
