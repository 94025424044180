@import '~theme/variables.less';



.button {
  height: 32px;
  width: 99px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: @padding-xs @padding-md;
  cursor: pointer;

  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size-sm;
  line-height: @line-height-md;
  text-align: right;
  background: var(--EAEEF1-2D2D2D);
  color: var(--button-color);

  .icon {
    margin-left: 11px;
    display: flex;
    align-items: center;

    path {
      fill: var(--button-color);
    }
  }
}

.historyButton {
  height: 32px;
  width: 96px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 4px;
  padding: @padding-xs @padding-md;
  cursor: pointer;
  margin-right: 24px;

  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: @font-size;
  line-height: @line-height-base;
  text-align: right;
  background: var(--EAEEF1-2D2D2D);
  color: var(--button-color);

  .icon {
    margin-right: 11px;

    path {
      fill: var(--button-color);
      transform: scale(0.90);
    }
  }
}

.dropdown_container {
  display: flex;
  flex-direction: column;

  background: var(--modal-bg-color);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: @padding-sm @padding-md;
    color: var(--button-color);
    font-size: @font-size-sm;

    path {
      fill: var(--button-color)
    }

    &:hover {
      cursor: pointer;
      background-color: var(--active-button-bg-color);
    }
  }
}
