@import '~theme/variables.less';

.click {
  cursor: pointer;
}
.fullWidth{
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  a{
    width: 100%;
  }
}

.loader {
  height: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  position: relative;
  &.sideBar{
    margin: 2.5px;
  }
  .spin{
    position: absolute;
  }
}

.wide {
  max-width: none;
}

.normal {
  max-width: 0;
}

.goToDetailsLink {
  display: flex;
  align-items: center;
}

.cell {
  display: flex;
  align-items: center;

  .title {
    font-family: @font-family-roboto;
    font-weight: @font-weight-400;
    font-style: normal;
    font-size: @font-size-sm;
    color: var(--table-header-color);
    line-height: 13px;

    &:hover {
      background: transparent;
    }
  }

  .description_right {
    max-width: 140px;
    font-family: @font-family-roboto;
    font-weight: @font-weight-500;
    font-size: @font-size;
    color: var(--titleCell-desription-right) ; //@primary-color
    line-height: 16px;
    overflow: hidden;
    text-align: right;
    width: 100%;
    justify-content: flex-end;
    display: flex;

    &:hover {
      background: transparent;
    }
  }

  .description {
    max-width: 140px;
    font-family: @font-family-roboto;
    font-weight: @font-weight-500;
    font-size: @font-size;
    line-height: 13px;
    color: var(--2d3436-FFFFFF);
    word-break: break-word;

    overflow: hidden;

    &:hover {
      background: transparent;
    }
  }

  .descriptionWatch {
    max-width: 140px;
    font-family: @font-family-roboto;
    font-weight: @font-weight-500;
    font-size: @line-height-h4;
    line-height: 13px;
    color: var(--2d3436-FFFFFF);
    word-break: break-word;

    overflow: hidden;

    &:hover {
      background: transparent;
    }
  }

  .title_right {
    font-family: @font-family-roboto;
    font-weight: @font-weight-400;
    font-style: normal;
    font-size: @font-size-sm;
    color: @text-color-secondary;
    line-height: 14px;

    display: flex;

    text-align: right;
    width: 100%;
    justify-content: flex-end;

    overflow: hidden;

    &:hover {
      background: transparent;
    }
  }

  .logo {
    margin-right: @margin-sm;
  }

  .logoTradingTicket {
    margin-right: @margin-xs;
  }

  .logo_right {
    margin-left: @margin-sm;
  }

  :global {
    .anticon {
      font-size: 17px;
      color: @primary-3;
    }
  }

  .star_container {
    margin-right: 10px;
    cursor: pointer;
    &.sideBar{
      margin-right: 6px;
    }
    .areaClickPadding{
      padding: 5px;
    }

    .active {
      color: @button-main;
    }

    .inactive {
      color: @button-secondary;
    }
  }
}

.dragIcon{
  svg{
    path{
      fill: var(--moved-drag-icon);
    }
  }
}
