@import '~theme/variables.less';

.bordered {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  margin-top: 25px;
  padding: 24px !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 236px;
  background: var(--card-bg-color);
  padding: 0 24px;
  gap: 16px;

  .headerDate {
    color: #84949A;
    font-family: @font-family-futura;
    font-size: 14px;
    font-style: normal;
    font-weight: @font-weight-400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: right;
  }

  .titleRating {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
  }
  .titleYears {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 14px;
    line-height: 18px;
    color: var(--title-color);
  }

  .numberText {
    font-family: @font-family-roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: @font-weight-500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--text-raiting-color);
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 69px;

  }

  .blockNumberText {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

    .numberCircle {
      display: inline-block;
      position: relative;
      flex-direction: column;
      width: 40px;
      height: 40px;

      .circle {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        width: 40px;
        position: absolute;
        height: 40px;
        border: 2px solid #EFBA58;

      }
    }
  }

  .valueFive {
    color: var(--card-bg-color);
    background-color: #EFBA58;
  }

  .valueAny {
    color: #EFBA58;;

  }

  .valueSizeNA {
    font-size: 18px;
  }

  .valueSizeAny {
    font-size: 24px;
  }

  .footer {
    font-size: 12px;
    line-height: 14.06px;
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-500;
    color: var(--footer-raiting);
  }

.dropdown_container {
  padding: 0;
  background-color: var(--button-bg-etf);
  box-shadow: 0 0 16px 0 var(--allocation-dropdown-shadow);
  border-radius: 6px;

  :global {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: initial;
    }

    .ant-select-item-option {
      padding: @padding-sm @padding-md;

      &:hover {
        background-color: var(--table-hover-bg);
      }

      .ant-select-item-option-content {
        display: flex;
        justify-content: space-between;
      }
    }

  }
}

.select {
  display: flex;
  align-items: center;
  height: 100%;

  width: 176px;

  :global {
    .ant-select-selector {
      border-radius: 4px !important;
      border: none !important;
      font-weight: @font-weight-700;
      background-color: var(--button-bg-etf) !important;

      .ant-select-selection-item {
        font-family: @font-family-roboto;
        font-weight: @font-weight-400;
        font-size: @font-size;
        line-height: @line-height-base;
        display: flex;
        align-items: center;
        color: var(--main-text-color);
        background: var(--button-bg-etf);

        img {
          display: none;
        }
      }
    }
  }

}
