@import '~theme/variables.less';

.modalWrapper {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }

  .modal {
    .overflow_container {
      overflow-y: auto;
      max-height: 848px;
      padding: 0 24px;
    }
  }
}

.bordered {
  &:not(:last-child) {
    border-bottom: 1px solid var(--main-border-color);
  }
}

.borderedLastElement {
  border-bottom: 1px solid var(--main-border-color);
}

.lightBorder {
  border-bottom: 1px solid var(--tradingDetails-overview-border-color);
}

.nonbordered {
  border: none;
}

.wrapper {
  display: flex;
  align-items: center;

  height: 40px;

  &.linkWrapper {
    padding: 9px 0;
    height: auto;
    align-items: flex-start;
    .titleBlock {
      margin-top: 2px;
    }
  }

  &.height_50 {
    height: 50px;
  }
  .titleBlock {
    flex: 1.3;
    flex-basis: 140px;

    font-family: @font-family-futura;
    line-height: 14px;

    .title {
      font-weight: 400;
      font-size: 14px;
      color: @text-color-secondary;
    }

    .date {
      font-size: 12px;
      font-weight: 500;
      color: var(--keyAttributes-data-color);
      text-transform: capitalize;
    }
  }
  .country {
    flex: 1.3;
    flex-basis: 140px;
    text-align: end;
    font-family: @font-family-roboto;
    line-height: 14px;
    font-weight: 400;
    font-size: 11px;
    color: @text-color-secondary;
  }

  .value {
    &.longValue {
      flex: 2;
      min-width: 150px;
    }
    font-family: @font-family-roboto;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 14px;
    color: var(--main-text-color);
    text-align: end;
  }

  .link {
    font-family: @font-family-futura;
    font-weight: @font-weight-500;
    font-size: @font-size;
    line-height: 18px;
    color: @button-main;
    text-align: end;
    flex: 1;
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  .linkWrap {
    :not(:first-child) {
      margin-top: 4px;
    }
  }

  :global {
    .color-1 div,
    .color-1 span {
      text-transform: uppercase;
      color: #18954a;
    }

    .color-2 div,
    .color-2 span {
      text-transform: uppercase;
      color: #62c3a0;
    }

    .color-3 div,
    .color-3 span {
      text-transform: uppercase;
      color: #c09f6a;
    }

    .color-4 div,
    .color-4 span {
      text-transform: uppercase;
      color: #f68f93;
    }

    .color-5 div,
    .color-5 span {
      text-transform: uppercase;
      color: #f25c63;
    }

    .color-6 div,
    .color-6 span {
      text-transform: uppercase;
      color: #8884d8;
    }
    .color-7 {
      color: var(--main-text-color);
    }
    .color-8 {
      color: #f73c30;
    }
  }

  :global {
    .anticon {
      color: #b2bec3;
      font-size: @font-size-lg;
      margin: 0 10px 0 0;
    }
  }
}
