@import '~theme/variables.less';

.wrapper_small {
  border: none !important;
  margin-top: -50px !important;
}

.wrapper {
  display: grid;
  width: 100%;
  margin-top: 43px;


  .header{
    justify-content: center;
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;

    color: var(--title-color);
  }
  .container {
    display: grid;
    margin-top: 13px;
    grid-row-gap: 4px;
  }

  .name {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */

    display: flex;
    align-items: center;
    text-align: right;

    /* grey / 500 */

    color: #84949A;
  }

  .graf {
    display: grid;
    grid-template-columns: 20% 60% 20%;

    @media (max-width: 1700px) {
      grid-template-columns: 25% 55% 20%;
    }

    @media (max-width: 1460px) {
      grid-template-columns: 30% 45% 25%;
    }
  }

  .value {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */

    display: flex;
    align-items: center;
    justify-content: end;

    /* grey / 900 */

    color: var(--form-text-color);
  }

  .relatedstocks {
    background-color: #F8E5C2;
    height: 12px;
    border-radius: 0 2px 2px 0;
  }
  .relatedstocksMain {
    background-color: #EFBA58;
    height: 12px;
    border-radius: 0 2px 2px 0;
  }
}


