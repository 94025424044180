@import '~theme/variables.less';

.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--card-bg-color);

  border: 1px solid var(--EAEEF1-2D2D2D);
  box-sizing: border-box;
  border-radius: 12px;
  height: fit-content;
  min-height: 252px;
  max-height: 252px;
  justify-content: space-between;
  padding: 8px 24px;
}

.wrapperNoBtn {
  justify-content: flex-start;
}

.buttonText {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 14px;
  line-height: 16px;
  margin-right: 7px;
  color: #EFBA58;
}

.wrapperButton {
  display: flex;
  align-items: center;
  padding: 12px 0 8px;
  cursor: pointer;
  width: max-content;
}

.morePortfoliosInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  font-family: @font-family-roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--84949A-BCBCBC);
  padding: 0 20px;
}

.modalWrapper {
  padding: 12px 5px 8px 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--EAEEF1-2D2D2D);
  }
  display: grid;
  grid-template-columns: auto 1fr;
}

.globalWrapper() {
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
}

.mainWrapper {
  .globalWrapper;
  &:first-child {
    padding-top: 4px;
  }
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
}

.mainElements {
  display: flex;
  flex-direction: column;
}

.mainWrapper, .modalWrapper {
  padding-top: 8px;
  &:hover {
    cursor: pointer;
    background: var(--EAEEF1-2D2D2D);
  }
}

.wrapperWithoutBorderPositions {
  .globalWrapper;
}

.text {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  color: var(--84949A-BCBCBC);
}

.blockInf {
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
}

.blockImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  background: var(--F7F7F8-191A1C);
  border: 1px solid var(--EAEEF1-2D2D2D);
  border-radius: 2px;
}

.imageText {
  color: #EFBA58;
  font-weight: @font-weight-500;
  font-size: 16px;
}

.nameOfPortfolios {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 14px;
  line-height: 16px;
  color: var(--2d3436-FFFFFF);
}

.allPortfoliosModal {
  :global {
    .ant-modal-body {
      padding: 0 !important;
    }
  }
}

.titleAndAllocationWrapper {
  margin: 24px 24px 0 24px;
  padding-top: 24px;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
}

.titleModal {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: var(--525D61-FFFFFF);
}

.valueIsError {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 18px;

  color: #E17055;
}

.value {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  color: var(--84949A-BCBCBC);
}

.positive {
  color: @positive-color;
}

.negative {
  color: @negative-color;
}

.bodyModal {
  overflow-y: auto;
  max-height: 436px;
  padding: 0 17px 0 24px;
}

.wrapperLoading {
  display: grid;
  grid-template-columns: 1fr auto 1fr 1fr;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
  margin-top: 8px;
  &:last-child {
    border-bottom: none;
  }
}

.loadingImage {
  grid-column: 1 ;
  grid-row: 1 / 4;
  width: 32px;
  height: 32px;
  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.loadingFirst {
  grid-column: 2;
  grid-row: 1;
  width: 240px;
  height: 14.67px;
  margin-bottom: 4px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.loadingSecond {
  grid-column: 2;
  grid-row: 2 ;
  width: 240px;
  height: 14.67px;
  margin-bottom: 4px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.loadingThird {
  grid-column: 2;
  grid-row: 3;
  width: 240px;
  height: 14.67px;
  margin-bottom: 8px;

  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.buttonLoading {
  width: 135px;
  height: 24px;
  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
  margin-top: 8px;
}
