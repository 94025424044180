@import '~theme/variables.less';

.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--card-bg-color);
  padding: 16px 24px 24px;

  border-radius: 12px;
  border: 1px solid var(--main-border-color);
  margin-top: 24px;

  .title {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23.08px;
    color: var(--title-color);
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  .description {
    font-family: @font-family-roboto;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: var(--objective-text-color);
  }
}
