@import '~theme/variables.less';

.parentBlocks{
  display: flex;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 328px;
  top:0;
  padding-bottom:0;

  :global {
    .ant-modal-body {
      height: 448px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      min-width: 328px;
    }
  }
}

.titleSection {
  height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 28px;
  justify-content: center;
  font-family: @font-family-futura;
  color: var(--title-color);
  margin-bottom: 8px;
}

.addNewBtnContainer {
  width: 100%;
  padding: 24px;

  > button {
    width: 100%;
    height: 48px;
    margin: 0;
  }
}

.alertsListContainer {
  padding: 0 24px;
  width: 100%;
  height: 278px;
  overflow: auto;

  > div:last-child {
    border-bottom: none;
  }

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
}

.noDataSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    margin-bottom: 5px;

    path{
      fill: var(--no-trading-data-svg-background);
      stroke: var(--no-trading-data-svg-stroke);
    }
    ellipse{
      fill: var(--no-trading-data-svg-ellipse);
    }
  }

  > div {
    font-family: @font-family-futura;
    font-size: 18px;
    line-height: 23px;
    color: var(--no-data-text-color);
  }
}
