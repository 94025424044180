@import '~theme/variables.less';

.wrapper_small {
  border: none !important;
  margin-top: -50px !important;
}

.wrapper {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  height: 208px;
  padding: 16px 24px 24px 24px;
  background: var(--card-bg-color);
  margin-top: 24px;

  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
  }

  .time{
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 14px;
    line-height: 18px;
    color: #84949A;
  }

  .title {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
    text-align: left;
    text-transform: capitalize;
  }

  .overview_block {
    margin-top: 20px;
  }

  .section {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1px;
    grid-template-rows: [row] 19px [row] 60px [row] 30px [row] 20px;
  }

  .section .block {
    grid-column: span 3;
    .value {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: @font-weight-400;
      font-size: 24px;
      line-height: 28px;
      text-align: center;

      color: var(--FFFFFF-111111);
    }
  }

  .section .blockName {
    grid-column: span 3;
    .blockValue {
      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: @font-weight-500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      justify-content: center;

      /* grey / 900 */

      color: var(--form-text-color);
    }
  }

  .section .blockName:nth-child(n + 5) {
    grid-column: span 2;
    .blockValue {
      margin-top: 4px;
      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: @font-weight-500;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      justify-content: center;

      /* grey / 900 */

      color: var(--form-text-color);
    }
  }

  .section .block:nth-child(n + 5) {
    grid-column: span 2;
    .value {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: @font-weight-400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;

      color: var(--FFFFFF-111111);
    }
  }

}

