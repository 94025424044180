@import '~theme/variables.less';

.container {
  display: flex;
  flex-direction: column;

  background: var(--card-bg-color);
  border: 1px solid var(--main-border-color);
  border-radius: @border-radius-base;

  padding: @padding-lg;
  padding-right: @padding-xs;

  margin-top: 18px;

  circle:first-of-type {
    fill: var(--empty-state-bg-color)
  }

  :global {
    h4.ant-typography {
      font-weight: @font-weight-500;
      font-size: @font-size-xl;
      line-height: 23px;
      color: var(--title-color);
    }
  }

  .content {
    overflow: auto;
    min-height: 160px;

    .empty {

      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 16px;

      .empty_text {
        margin-top: 10px;
        color: var(--84949A-BCBCBC);
        text-align: center;
      }
      .empty_img {
        path{
          fill: var(--no-trading-data-svg-background);
          stroke: var(--no-trading-data-svg-stroke);
        }
        ellipse{
          fill: var(--no-trading-data-svg-ellipse);
        }
      }
    }
  }
}
