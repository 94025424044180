@import '~theme/variables.less';

.title {
  text-align: center;
  font-family: @font-family-futura;
  font-weight: 450;
  font-size: 22px;
  line-height: 28px;
  color: var(--525D61-FFFFFF);
}

.columnsTitle {
  margin-top: 29px;
  margin-bottom: 17px;
  display: flex;
  justify-content: flex-end;

  > div {
    font-size: 12px;
    line-height: 14px;
    font-family: @font-family-futura;
    color: var(--84949A-BCBCBC);
    margin-right: 24px;
    width: 48px;
    text-align: center;
  }

  > div:last-child {
    margin-right: 0;
  }
}

.settingsModal {
  :global {
    .ant-modal {
      min-width: 500px !important;
    }
  }
}

.setting {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--EAEEF1_2D2D2D);
  padding: 15px 0 15px 0;

  &:not(.hasControls) {
    border-bottom: 1px solid var(--EAEEF1_2D2D2D);
  }

  &.hasControls {
    padding-bottom: 0;
  }
}

.settingTitle, .settingLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  font-family: @font-family-roboto;
  color: var(--2D3436_FFFFFF);
  align-items: center;

  :global {
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
    .ant-checkbox + span {
      margin-top: 6px;
    }
  }
}

.settingTitle {
  margin-right: 16px;
  align-self: center;
}

.controls {
  &.hasExtraControls {
    border-bottom: 1px solid var(--EAEEF1_2D2D2D);
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 8px;
  }
}

.settingSubtitle {
  display: flex;
  font-size: 10px;
  font-weight: 400;
  color: var(--text-secondary-color);
  margin-top: 12px;
  line-height: 12px;
}

.extraControls {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 25px;
}

.hasSubtitle {
  .extraControls {
    margin-top: 15px;
  }

  .extraControlsContainer {
    margin-top: 0;
  }
}

.extraControlsContainer {
  flex-direction: column;
  display: flex;

  > .row {
    flex-direction: row;
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
    &:not(:last-child) {
      margin-bottom: 23px;
    }
  }
}

.controlsRowTitles {
  display: flex;
  flex-direction: row;
}

.controlsColumnTitles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dropdown {
  align-self: flex-end;
  width: 80px;
}

.settingSwitches {
  display: flex;
  :global{
    .ant-switch:focus, .ant-switch-checked:focus {
      box-shadow: none;
    }
  }

  > div {
    width: 40px;
    margin-right: 36px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  > div:last-child {
    margin-right: 0;
  }
}
