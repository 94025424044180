@import '~theme/variables.less';

.chartContainer, .tradeModeChartContainer {
  display: flex;
  flex-direction: column;

  background: var(--card-bg-color);
  border: 1px solid var(--main-border-color);
  border-radius: @border-radius-base;

  margin-top: @margin-lg;

  padding: 16px 24px;
}

.tradeModeChartContainer {
  margin-top: 0;
  padding: 24px 24px 0 24px;
  border: none;
}

.tradingView {
  height: 95%;
}

.wrap {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 110;
}
.icon{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 44px;

  &:hover {
    background-color: var(--search-dd-hover);

    path {
      fill: var(--2D3436-B2BEC3);
    }
  }

  img {
    width: 20px;
    height: 20px;

    &:hover {
      background-color: var(--search-dd-hover);
    }
  }

  svg {
    path {
       fill: var(--2D3436-B2BEC3);
    }
  }

}

.thirdPartyWrapper {
  z-index: 80;
}

.delayed {
  font-family: @font-family-roboto;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 14px;
  color: var(--636E72-84949A);
  justify-content: flex-end;
  align-items: center;
  display: flex;
  margin-right: 9.33px;
}

.viewDetails {
  color: @button-main;
  cursor: pointer;
}

.delayedContainer {
  z-index: 2;
  height: 5%;
  background: var(--card-bg-color);
  align-items: center;

  > span {
    margin-right: 48px;
  }
}

.tradingViewContainer {
  height: 100%;
}
