@import '~theme/variables.less';

.loaderWrap{
  position: relative;
  height: 36px;
}
.loader{
  position: absolute;
  margin-left: -8px;
}
.wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapperButtonWatchAndAlert {
  display: flex;
  width: 285px;
  @media (max-width: 1242px) {
    flex-direction: column;
    align-items: start;
    margin-right: 8px;
    width: 100%
  }
}

.wrapper {
  background: var(--card-bg-color);
  border: 1px solid var(--main-border-color);
  border-radius: @border-radius-base;
  display: flex;
  padding: 24px;
  min-width: 0;
  //
  //max-height: 270px;


  button {
    color: var(--btn-text-color);
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;


  }
}

.row_2 {
  flex-flow: initial;
  justify-content: space-between;
  @media (max-width: 1242px) {
    justify-content: end;
  }
}

.column_first,
.column_second,
.row_1,
.row_2 {

  .add_to_watchlist_button {
    display: flex;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    color: @button-secondary;
    cursor: pointer;
    text-align: end;
    padding: 10px 20px 10px 0;
    @media (max-width: 1242px) {
      padding-right: 10px;
    }
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > span:nth-child(2) {
      white-space: nowrap;
    }

    svg{
      width: 16px;
      height: 15.3px;
      margin: 0 8px 0 0;
      path{
        fill: @button-secondary;
      }
    }

    :global {
      .anticon {
        color: @button-secondary;
        font-size: @font-size;
      }
    }
  }

  .flex {
    justify-content: left !important;
  }

  .stock {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 14px;
    line-height: 18px;
    color: var(--value-color-portfolio-exchange);
    margin-top: 0;

    white-space: normal;
    text-overflow: ellipsis;
    display: block;
    text-align: center;
    width: fit-content;

  }

  .status {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 15px;
    color: #e17055;
    display: block;
    text-align: center;
    margin-top: 5px;
  }

  .status_open {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 15px;
    color: black;
    display: block;
    text-align: center;
    margin-top: 15px;
  }

  .status_close {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 15px;
    color: #e17055;
    display: block;
    text-align: center;
    margin-top: 15px;
  }

  .quotes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;

    .bidAsk {
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      color: #84949A;
      margin-right: 4px;
    }
  }

  .second_row,
  .first_column {
    display: flex;

    .heading {
      padding-left: @padding-sm;
      min-width: 0;

      :global {
        .ant-row {
          display: flex;
          align-items: center;
        }
      }

      .name {
        font-family: @font-family-futura;
        font-style: normal;
        font-weight: @font-weight-400;
        font-size: @font-size-sm;
        line-height: @line-height-sm;
        color: var(--2D2D2D-84949A);
        margin: 3px 8px 0 0;
        user-select: text;
      }

      .title {
        font-family: @font-family-futura;
        font-style: normal;
        font-weight: @font-weight-400;
        font-size: 16px;
        line-height: 22px;
        color: var(--stock-name-color);
        margin: 3px 0 0;
        user-select: text;
      }

      .type_bg {
        background: @blue;
        padding: 0 10px;
        border-radius: 4px;
        cursor: pointer;
      }

      .type {
        font-family: @font-family-futura;
        font-style: normal;
        font-weight: @font-weight-500;
        font-size: @font-size-sm;
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: left;
        border-radius: 5px;
        margin: 3px 0 0;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .logo {
      border-radius: 8px;
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 64px;
      max-width: 64px;
      align-self: start;

      img {
        width: 64px;
      }
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.38;
}

.instrument_currently_disabled {
  color: @excitement-color_minus;
  padding: 12px 2px;
  font-family: @font-family-futura;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}

.button {
  width: 128px !important;

  height: 40px;
}

.button[disabled] {
  pointer-events: none;
}

.button:last-child {
  width: 200px;
}

.instrument_temporarily_disabled, .close_existing_positions {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #84949A;
  display: flex;
  justify-content: center;
  margin-bottom: 9px;
}

.second_row {
  color: var(--value-color-portfolio-price);
  align-content: center;
  margin-top: 14px ;
  flex-direction: column;

  .largePrice {
    span {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  .worktime {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: @font-size-sm;
    line-height: @font-size;
    color: var(--table-header-color);
    margin: 3px 0;
  }

  .currency {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 14px;
    line-height: 16px;
    color: var(--value-color-portfolio-widget);

  }

  .price {
    display: inline-block;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 14px;
    line-height: 16px;
  }

  :global {
    .anticon svg {
      margin-top: 2px;
      height: 10px;
    }
  }

  .excitement {
    margin-left: 5px;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: @font-size-xl;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }

  .excitement_plus {
    color: @excitement-color_plus;
  }

  .excitement_minus {
    color: @excitement-color_minus;
  }
}
