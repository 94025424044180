@import '~theme/variables.less';

.errorContainer {
  z-index: 2000;
  background-color: var(--loader-bg-color);
  background-image: var(--error-modal-bg-picture);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-width: 1440px;
  min-height: 102%;
  top: -2%;


  :global {
    & .ant-empty {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 379px;

    }

    & .ant-empty-footer {
      margin: 16px 0 48px !important;
    }
  }
}

.connectionContainer {
  display: flex;
  background: var(--loader-container-bg-color);
  border: 1px solid var(--main-border-color);
  box-sizing: border-box;
  border-radius: 12px;
  width: 320px;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-top: 99px;
  margin-bottom: 15.52px;
}

.tagLine {
  margin-bottom: 26.34px;

  path {
    fill: var(--2B2D3A-FFFFFF);
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleError {
  font-size: 18px;
  line-height: 23px;
  font-family: @font-family-futura;
  font-weight: @font-weight-500;
  color: var(--main-text-color);
  margin-bottom: 8px;
}

.button {
  margin-bottom: 65px;

  > span {
    margin-left: 8.67px;
    font-size: 14px;
    line-height: 2px;
  }
  path {
    fill: var(--FFF-2D2D2D);
  }
}

.subTitleError {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--data-subscription-text-color);
  margin-bottom: 16px;
}
