@import '~theme/variables.less';

.page_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;

  background: var(--loader-bg-color);
  background: var(--error-modal-bg-picture);
  background-size: cover;
  min-width: @screen-min-width;
  min-height: 102%;
  top: -2%;
}

.sign_in_container {
  width: 320px;
  height: 360px;
  background: var(--loader-container-bg-color);
  border: 1px solid var(--main-border-color);
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;

  & .logo {
    margin-top: 100px;
    text-align: center;

    img {
      width: 120px;
      height: 22px;
    }
  }

  .logos {
    img {
      margin: 8px;
      height: 48px;
      width: 48px;
    }
  }

  .tagLine {
    margin-top: 15.25px;

    path {
      fill: var(--2B2D3A-FFFFFF);
    }
  }

  .spinWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 69px;

    & .hint {
      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: normal;
      font-size: @font-size-lg;
      line-height: 16px;
      text-align: center;
      color: var(--loader-hint-color);
    }

    & .spinner {
      margin-right: 13px;
    }
  }
}
