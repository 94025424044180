@import '~theme/variables.less';

.wrapper {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  margin-top: 25px;
  background: var(--card-bg-color);
  position: relative;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.selectorWrap{
  position: absolute;
  top: 55px;
  right: 24px;
}

.date{
  font-family: @font-family-futura;
  font-size: 14px;
  line-height: 18px;
  color: #84949A;
}
.title {
  font-family: @font-family-futura;
  font-weight: @font-weight-400;
  font-size: 18px;
  line-height: 23px;
  color: var(--title-color);
}





