@import '~theme/variables.less';

.scheduleWrap{
  width: max-content;
  :global {
    .ant-dropdown-menu {
      border-radius: 12px;
    }
  }
  img{
    margin-top: 2px;
  }
}

.container {
  width: 226px;
  background: var(--sidebar-btn-color-active);
  border: 1px solid var(--stock-schedule-border);
  box-sizing: border-box;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-top: 9px;
  padding-bottom: 8px;

}

.schedule_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.asterisk {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: var(--schedule-asterisk);
  margin-top: 8.5px;
}

.margin {
  //margin: 5px;
  //text-align: right;
}

.time {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 18px;
  color: var(--table-header-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row {
  margin-top: 4px;
  padding: 0 16px;
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 18px;
  color: var(--table-header-color);
  cursor: default;
  //padding: 5px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.opens_in {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 12px;
  display: block;
  text-align: center;
  color: var(--table-header-color);
  width: 210px;
  opacity: .5;
  cursor: default;
}

.flex, .flex_top {
  width: 220px;
  &.buyWidget {
    width: 204px;
  }
  display: flex;
  padding: 0 8px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.flex_top {
  align-items: flex-start;
}

.cursor {
  cursor: pointer;
}

.padding {
  padding-top: 4px;
}

.timeWrap::after {
  content: attr(data-end) ;
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  line-height: 20px;
  position: relative;
  bottom: 0.5em;
  font-size: 0.8em;
}

.notify_button {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid var(--gold-text-color);
  border-radius: 15px;
  padding: 4px 25px;
  float: right;
  margin: 5px 0;
  cursor: pointer;
  width: 128px;
  height: 24px;

  &.buyWidgetNotify {
    width: 124px;
    height: 24px;
    border-radius: 64px;
    font-size: @font-size-sm;
    padding: 3px 11px 5px;
    text-align: center;
    line-height: @line-height-md;
  }
}

.notify_disabled {
  color: var(--table-header-color);
}

.notify_open {
  background-color: var(--gold-text-color);
  color: var(--btn-text-color);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule_dropdown {
  float: left;
  &.buyWidget {
    width: 204px;
  }
}

.title {
  text-align: center;
  font-family: @font-family-futura;
  font-size: 22px;
  padding: 10px;
  color: var(--525D61-FFFFFF);
}

.modalText {
  font-size: 14px;
  line-height: 16px;
  font-family: @font-family-roboto;
  padding: 10px 55px;
  text-align: center;
  color: var(--2D3436_FFFFFF);
}

.wrapActions{
  margin-top: 10px;
  button {
    height: 48px;
  }
}

.button{
  margin: 0 auto;
}

.openIcon {
  margin-right: 5px;
}
