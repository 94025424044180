@import '~theme/variables.less';

.cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: @line-height-base;
  margin-right: 0;
  min-height: 32px;

  .excitement_plus {
    color: @excitement-color_plus;
  }

  .excitement_minus {
    color: @excitement-color_minus;
  }

  .price {
    font-family: @font-family-roboto;
    font-weight: @font-weight-500;
    font-size: @font-size;
    color: var(--main-text-color);
    align-self: flex-end;
    display: flex;
  }

  .space {
    padding-left: 3px;
  }

  .price_change_isRelatedStock {
    font-size: @font-size;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    text-align: right;
    align-self: flex-end;
    display: flex;
  }
  .price_change {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: @font-size-sm;
    text-align: right;
    line-height:14px;

    :global {
      .anticon svg {
        margin-top: 4.5px;
        height: 10px;
      }
    }

    align-self: flex-end;
    display: flex;
  }
}
