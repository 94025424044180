@import '~theme/variables.less';


.tooltip{
  div {
    color: var(--loader-hint-color);
  }

  a {
    display: block;
    color: @button-main;
  }
  :global {
    .ant-tooltip-arrow {
      display: none !important;
    }

    .ant-tooltip-inner {
      padding: 8px 12px;
    }
  }
}
.tooltipTitle {
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.06px;
  text-align: center;
}

