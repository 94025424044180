@import '~theme/variables.less';

.wrap{
  width: 100%;
  border-bottom: 1px solid var(--EAEEF1_191A1C);
  min-height: 56px;
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr min-content;
  grid-column-gap: 8px;
  padding: 8px 0 7px 0;
  &.fromSearchModal{
    border-bottom: 1px solid var(--main-border-color);
    min-height: 48px;
    padding: 8px 24px 7px 24px;
    .description{
      font-size: 12px;
      line-height: 13px;
    }
    .contentRow{
      font-size: 12px;
      .price{
        white-space: nowrap;
        font-size: 14px;
        line-height: 16px;
      }
    }

  }
}
.logo{
  cursor: pointer;
}
.content{
  cursor: pointer;
  display: grid;
  grid-row-gap: 2px;
}
.contentRow{
  color: var(--main-text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 16px;

  .description{
    font-weight: @font-weight-500;
    margin-bottom: 4px;
    word-break: break-word;
    font-size: 14px;
    line-height: 16px;
  }

  .price{
    font-weight: @font-weight-500;
    margin-bottom: 2px;
    margin-left: 8px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 19px;
    display: flex;
  }
  .gold{
    color: var(--gold-text-color);
  }
  .secondary{
    color: var(--text-secondary-color);
    font-size: 12px;
    line-height: 14px;
    .bid{
      font-weight: @font-weight-700;
      line-height: 14px;
    }

  }
  .right{
    text-align: right;
  }
}
.removeIcon{
  padding: 6.25px;
  margin-left: 2px;
  cursor: pointer;
}
