@import '~theme/variables.less';

.wrap{
  background-color: var(--sidebar-btn-color-active);
}

.header{
  padding: 16px 24px;
  height: 60px;
  border-bottom: 1px solid var(--main-separator-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: @font-family-futura;
  font-size: 22px;
  line-height: 28px;
  color: var(--title-color);
}

.icon {
  font-size: 14px;
}

