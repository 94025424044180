@import '~theme/variables.less';

.successOrderWrapper {
  margin-top: 44px;
}

.successOrderTitle {
  > span {
    color: var(--2D3436_FFFFFF);
    width: 198px;
    font-size: 12px;
    line-height: 16px;
    font-family: @font-family-roboto;
    margin-left: 10px;
    font-weight: 500;
  }
}

.successOrderSection, .successOrderPrice {
  display: flex;
  justify-content: space-between;
  line-height: 14px;
  font-size: 12px;
  font-family: @font-family-roboto;
  margin-bottom: 4px;

  > div:nth-child(1) {
    color: #84949A;
  }

  > div:nth-child(2) {
    font-weight: 500;
    color: var(--525D61-FFFFFF);
  }
}

.successOrderPl {
  display: flex;
  justify-content: space-between;
  line-height: 14px;
  font-size: 12px;
  font-family: @font-family-roboto;
  margin-bottom: 4px;

  > div:nth-child(1) {
    color: #84949A;
  }
}

.partialQuantityValue {
  color: #18954A;
}

.successOrderPrice {
  > div:nth-child(2) {
    color: #EFBA58 !important;
  }
}

.viewOrder {
  text-align: end;
  margin-top: 9px;
  line-height: 14px;
  font-size: 12px;
  font-family: @font-family-roboto;
  font-weight: 500;
  color: #EFBA58;
  cursor: pointer;
}

.symbolDescription {
  text-align: right;
}
