@import '~theme/variables.less';

.version {
  font-family: @font-family-futura;
  font-style: normal;
  color: @excitement-color_plus;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-top: 5px;
}

.version_container_title {
  font-family: @font-family-futura;
  font-style: normal;
  color: @primary-2;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 5px;
}

.logger_container {
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  :global {
    .ant-switch, .ant-switch-checked:focus {
      box-shadow: none;
    }
  }
}

.logger_label {
  font-family: @font-family-futura;
  font-style: normal;
  color: @primary-2;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  margin-top: 5px;
}

.timepicker {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;

  /* Gray / 900 */

  color: var(--button-color);
  display: inline-block;
  //width: 168px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #2d3436;
  margin-top: 25px;
}

.extra {
  display: flex;
  justify-content: center;
  align-items: center;

  > div:nth-child(1) {
    display: flex;
    align-items: center;

    :global {
      .ant-dropdown {
        top: 48px !important;
      }
      .ant-input-affix-wrapper {
        width: 156px;
        height: 32px;
        border-radius: 6px;
        padding: 0 6px 0 8px;
        border: 1px solid #84949A;
        font-size: 14px;
        background-color: transparent;
      }
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused {
        box-shadow: none;
        font-size: 14px;
      }
    }
  }
  input {
    font-size: 14px;
    background-color: var(--FFFFFF-2D2D2D);
    color: var(--button-color);
  }
}

.timeSection {
  display: flex;
  align-items: center;
}

.time {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: right;

  /* Gray / 900 */

  color: var(--main-text-color);
  margin-right: 16px;
}

.time_utc {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: right;

  /* Gray / 900 */

  color: var(--main-text-color);
  cursor: pointer;

  :global {
    .anticon {
      color: @button-main
    }
  }
}

.utc {
  margin-right: 5px;
}

.subtitle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  color: #84949a ;
}

.button {
  align-self: flex-end;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: @component-background;
  background: @button-main;
  box-shadow: @button-main-shadow;
  border-radius: 8px;
  padding: @padding-sm 50px;
  width: 100%;
  text-align: center;
  cursor: pointer;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-top: 50px;
  &.buttonReload{
    margin: 8px 0;
    font-size: 12px;
    line-height: 14px;
    padding: 7px 12px;
    width: 103px;
    height: 32px;
    svg{
      margin-right: 8px;
      path {
        fill: var(--FFF-2D2D2D);
      }
    }
  }
}

.poorBtn {
  color: var(--FFF-2D2D2D);
}

.timeZonesDropdown {
  width: 200px;
  height: 210px;
}

.headerWrapper {
  height: 48px;
  position: relative;

  .header {
    padding: 0 !important;
    position: fixed !important;
    width: 100vw;
    top: 0;
    z-index: 190;
    box-shadow: var(--header-box-shadow);

    .ant-page-header-heading-title {
      width: 54px;
      display: flex;
      justify-content: center;
    }

    :global {
      .ant-page-header-heading {
        background-color: var(--header-sidebar-bg-color);
        height: 48px;
        min-width: max-content;
      }

      .ant-page-header-heading-left {
        width: 100%;
        margin: 0;
        display: flex;

        .ant-page-header-heading-title {
          margin: 0;

          @media screen and (min-width: 961px) {
            padding: @padding-md 0 @padding-md @padding-lg;
            line-height: 15px;
          }

          @media screen and (max-width: 960px) {
            width: 54px;
            display: flex;
            justify-content: center;
            margin-right: 0;
            line-height: 17.3px;
          }
        }

        .ant-page-header-heading-sub-title {
          @media screen and (max-width: 960px) {
            padding-left: 0;
          }
          padding-left: 35px;
          min-width: max-content;
        }
      }

      .ant-page-header-heading-extra {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
      }
    }
  }
  .logo {
    @media screen and (max-width: 960px) {
      width: 16px;
      height: 17.3px;
      background-size: contain;
    }

    @media screen and (min-width: 961px) {
      height: 15px;
      background-size: contain;
    }
  }
}



.header-menu {
  justify-content: center;
  align-items: center;
  gap: 16px ;

  @media screen and (max-width: 960px) {
    gap: 5px;
  }

  .header-menu-item {

    @media screen and (max-width: 960px) {
      padding: 0 8px
    }

    cursor: pointer;
    font-weight: @font-weight-500;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    height: 32px;
    background: transparent;
    border-radius: 4px;

    span {
      color: @button-secondary;
    }

    &.active {
      background: #EFBA58;


      span {
        color: var(--FFF-2D2D2D);
      }
    }
  }
}

.profile-menu {
  width: 208px;
  background-color: var(--modal-bg-color);

  > li:nth-child(1) > ul > li:nth-child(1) {
    span {
      color: #EFBA58;
    }
  }

  :global {
    .ant-radio-wrapper {
      display: flex;
      justify-content: space-between;

      .ant-radio {
        order: 2;

        .ant-radio-inner {
          border: 2px solid @primary-3;
          background-color: var(--sidebar-btn-color-active);
          width: 20px;
          height: 20px;
          box-shadow: none;

          &::after {
            background-color: @positive-color;
            width: 10px;
            height: 10px;
            top: 11px;
            left: 11.2px;
          }
        }
        &:hover {
          .ant-radio-inner {
            border-color: @primary-3;
          }
        }
      }
      .ant-radio-checked .ant-radio-inner::after {
        transform: scale(1);
      }
      .ant-radio.ant-radio-checked {
        .ant-radio-checked:hover {
          .ant-radio-inner {
            border-color: @primary-3;
          }
        }
        .ant-radio-inner {
          border-color: @positive-color;
        }
      }

      span:last-child {
        padding-left: 0;
      }
    }

    .ant-typography {
      font-size: @font-size;
      line-height: @line-height-base;
      font-weight: @font-weight-400;
      color: var(--profile-item-title)//#8D8E95  // Todo
    }

    .ant-dropdown-menu-item-group:nth-of-type(n+2) {
      border-top: 1px solid var(--EAEEF1-4E4E4E);
    }
    .ant-dropdown-menu-item-group:last-of-type {
      .ant-dropdown-menu-item-group-title {
        display: none;
      }
    }
    .ant-dropdown-menu-item-group-title {
      color: var(--screener-color);
      font-weight: 500;
      width: 176px;
      height: 40px;
      padding: @padding-sm @padding-md;
    }

    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }
  }

  .profile-menu-item {
    text-align: start;
    padding: @padding-xs @padding-xs @padding-xs @padding-lg;
    height: 40px;
    width: 208px;

    &.active {
      background-color: var(--profile-item-title-active-background) ; // @background-color-base

      span {
        color: var(--profile-item-title) ; //@primary-color
      }
    }

    &:hover {
      background-color: var(--active-button-bg-color);
    }
  }
}

.emptyTimeZone {
  height: 210px;
  width: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--modal-bg-color);
  color: var(--button-color);

  :global {
    .ant-dropdown-menu {
      padding: 0;
    }
  }
}

.utc-menu {
  border-radius: 6px;
  width: 270px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 4px 0;
  max-height: 210px;
  background-color: var(--modal-bg-color);

  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

  :global {
    .ant-dropdown-menu {
      padding: 0;
    }
    .ant-typography {
      font-size: @font-size-lg;
      line-height: 16px;
      font-weight: @font-weight-400;
    }
  }

  .profile-menu-item {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 8px 20px 8px 12px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &.active {
      background-color: var(--active-button-bg-color);

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }

    &:hover {
      background-color: var(--active-button-bg-color);
    }
  }
}

.avatar {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #191A1C;
  cursor: pointer;
  border: 2px solid var(--header-sidebar-bg-color);

  &.active {
    border: 2px solid @button-main;
  }

  &:hover {
    border: 2px solid @button-main;
  }
}

.btnMenuProfile {
  margin-left: 14px;
}

.poorWrap{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  height: 48px;
  width: 365px;
  z-index: 1001;
  background-color: var(--notification-bg-color);
  border: 1px solid var(--main-border-color);
  box-sizing: border-box;
  /* shadow */

  box-shadow: 0 0 16px var(--allocation-dropdown-shadow);
  border-radius: 8px;
  .loader{
    font-size: 25px;
    margin-right: 8px;
    color: var(--ant-spin-color)!important;
  }
  .poorText{
    font-size: 16px;
    line-height: 22px;
    color: var(--data-subscription-header-text-color);
    margin-right: 16px;
  }

}

.notificationClose {
  a {
    top: 9px;
    right: 10.82px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.portfoliosWrapper {
  display: flex;
  align-items: center;
  padding-right: 32px;
}

.portfolio {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  margin-right: 8px;

  color: var(--2D3436-BCBCBC);
}
