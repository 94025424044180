@import '~theme/variables.less';

.container {
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 14px;

  &.hover {
    span {
      &:hover {
        background-color: var(--filter-cross-hover-bg-color);
      }
    }
  }

  span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

  }

  .counter {
    position: absolute;
    justify-content: center;
    display: flex;
    visibility: hidden;
    align-items: center;

    top: 0px;
    right: 0px;
    width: 0px;
    height: 0px;

    border-radius: 0px;
    color: #fff;
    background-color: @normal-color;
    font-size: 0;
    font-weight: @font-weight-400;
    line-height: @line-height-xl;
    opacity: 0;

    transition: 0.65s all cubic-bezier(0, 0, 0.58, 1.5);

    &.visible {
      visibility: visible;
      border-radius: 8px;
      top: -8px;
      right: -8px;
      width: 16px;
      height: 16px;
      opacity: 1;
      font-size: @font-size-sm;
    }
  }
}
