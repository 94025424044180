@import '~theme/variables.less';

.wrapper_small {
  border: none !important;
  padding: 0 24px !important;
}

.wrapper {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px;
  margin-top: 25px;
  background: var(--card-bg-color);

  .table {
    width: 100%;

    th {
      font-family: @font-family-futura;
      font-style: normal;
      font-weight: @font-weight-500;
      font-size: @font-size-sm;
      line-height: @font-size;
      text-align: right;
      text-transform: uppercase;
      color: #636E72;
      padding: 10px;
    }

    td:first-child {
      min-width: 40%;
    }

    td {
      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: @font-weight-500;
      font-size: @font-size-sm;
      line-height: @font-size;
      text-align: right;
      color: var(--main-text-color);
      padding: 0 10px 0 0;

      border-bottom: 1px solid var(--tradingDetails-overview-border-color);
    }
    td:first-child{
      text-align: left;
    }
  }

  .overview_block{
    margin-top: 34.5px;
  }

  .title {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
    text-align: left;
    text-transform: capitalize;
  }

  .description {
    font-size: 14px;
    line-height: 16.41px;
    font-weight: 400;
    color: var(--636E72-BCBCBC);
  }

}

.keyAttributes {
  border-radius: 12px;
  border: 1px solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px;
  margin-top: 25px;
  background: var(--card-bg-color);
  box-sizing: border-box;
}

.descriptionTitle {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: var(--title-color);
  text-align: left;
  text-transform: capitalize
}

.textDescription {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--2D3436-B2BEC3)
}

.marketDataRange {
  width: 100%;
  padding: 0 97.5px;
}



