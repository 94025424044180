@import '~theme/variables.less';

.popoverStyle {
  background: @button-main;
  border-bottom: 1px solid rgba(128, 128, 128, 0.1);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 100;
  width: max-content;
  min-width: 222px;
  right: auto;
  left: 35px !important;
  top: 54px !important;
  :global {
    .ant-popover-content > .ant-popover-arrow {
      display: none !important;
    }
    .ant-popover-inner {
      background: @button-main;
      border: none;
      box-shadow: none !important;

      .ant-popover-inner-content {
        color: #FFF;
      }
    }
  }
}

.content {
    box-sizing: border-box!important;
    transform: translateY(49px) !important;
    top: -1px!important;
    left: 54px !important;
    border-top: 1px solid var(--EAEEF1-111111);
    width: 301px !important;
    height: calc(100vh - @header-height);
    background: var(--sidebar-btn-color-active);
    z-index: 103;
    overflow: hidden;

  border-right: 1px solid var(--EAEEF1-111111);
  -webkit-box-shadow: 15px 0 10px -10px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 15px 0 10px -10px rgba(0, 0, 0, 0.12);
  box-shadow: 15px 0 10px -10px rgba(0, 0, 0, 0.12);



  :global {
    .ant-drawer-content {
      background-color: var(--sidebar-btn-color-active);
    }
    .ant-drawer-content-wrapper{
     box-shadow: none!important;
    }
    .ant-drawer-body{
      overflow: hidden;
    }
  }

  &.hidden {
    display: none;
  }
}

.container {
  height: 100%;
  overflow: hidden;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--main-separator-color);

  :global {
    .ant-menu-vertical {
      border-right: none;
    }
  }

  .menu {
    background-color: var(--header-sidebar-bg-color);
    border-top: 1px solid var(--main-separator-color);

    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    width: 54px;
    max-width: 54px;
    z-index: 150;

    li.item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 5px;
      padding: 0;
      border-radius: 10px;
      font-size: 20px;
      cursor: pointer;
      color: @primary-3;
      background-color: inherit;

      &.active {
        background-color: @button-main;
        path {
          fill: var(--sidebar-btn-color-active);
        }
      }

      &.freeTrades {
        background: #EFBA58;
      }
    }
  }

  .refinitiv {
    width: 40px;
    height: 48px;
    margin: 0 8px 8px 8px;
  }
}
.crossButton {
  svg {
    height: 8.79px;
    width: 8.79px;
  }
  path {
    fill: #B2BEC3;
  }
}
.notification {
  &.marketOpeningNotification {
    width: 325px !important;
  }
  width:296px;
  padding:16px 20px;
  right: 40px;
  :global {
    .anticon.anticon-bell {
      background: #EFBA58;
      padding: 5px;
      font-size: 15px;
      border-radius: 20px;
      color: white;
      margin-right: 10px;
    }
    .ant-notification-notice-content {
      padding: 0 5px;
    }
    .ant-notification-notice-with-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin: 0;
    }

    .ant-notification-notice-icon {
      display: block;
      width: 100%;
      &>div {
        display: flex;
        align-items: center;
      }
      margin-left: 0;
      font-size: 14px;
      line-height: 16.41px;
      text-align: left;
      color: #84949A;
    }
    .ant-notification-notice-message {
      display: none;
    }
    .ant-notification-notice-close{
      right: 13px;
    }
  }
  .notificationBlock {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;

    /* grey / 500 */

    color: #84949A;
  }
  .alertLevel {
    margin-left: 10px;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 14px;
    line-height: 16.41px;
    color: var(--allertStock-color);
    width: 189px;
  }
  .notificationWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .mainWrapper {
    margin-top: 43px;
  }

  .priceWrapper {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    word-spacing: -2px;
    text-align: right;

    /* gold/500 */

    color: #EFBA58;
  }

  .textWrapper {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    text-align: right;

    /* grey/700 */

    color: var(--allert-value);

    &.red{
      color: @negative-color;
    }
    &.green{
      color: @positive-color;
    }

    &.left {
      text-align: left;
    }
  }

  .viewInstr {
    cursor: pointer;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: flex-end;
    text-align: center;
    justify-content: flex-end;
    margin-top: 6px;

    /* gold/500 */

    color: #EFBA58;
  }

  .plainText {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    color: var(--2D3436_FFFFFF);
    flex-direction: row;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
  }

  .bellIcon {
    padding-right: 16px;
  }
}
