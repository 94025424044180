@import '~theme/variables.less';

.ldsRing {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  transform: scale(1.2);
  &.medium{
    transform: scale(0.8);
  }
  &.small{
    transform: scale(0.6);
  }

}
.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 2.85px solid @button-main;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: @button-main transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.ldsSpinner {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  transform: scale(0.85);
  &.medium{
    transform: scale(0.565);
  }
  &.small{
    transform: scale(0.425);
  }

}
.ldsSpinner div {
  transform-origin: 20px 20px;
  animation: ldsSpinner 1.2s linear infinite;
}
.ldsSpinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.5px;
  left: 18.5px;
  width: 3px;
  height: 9px;
  border-radius: 20%;
  background: @button-main;
}
.ldsSpinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.ldsSpinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.ldsSpinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.ldsSpinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.ldsSpinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.ldsSpinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.ldsSpinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.ldsSpinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.ldsSpinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.ldsSpinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.ldsSpinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.ldsSpinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes ldsSpinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

