@import '~theme/variables.less';

.selectWrap{
  display: flex;
  align-items: center;
  :global{
    .arrow-svg{
      path{
        fill: var(--2D3436_EFBA58);
      }
    }
    .ant-select-open{
      .arrow-svg{
        transform: rotate(180deg);
      }
    }
  }
}
.label{
  font-family: @font-family-futura;
  font-size: 14px;
  line-height: 18px;
  color: var(--title-color);
  margin-right: 8px;
  &.fromPortfolio{
    font-size: 18px;
    margin-right: 16px;
  }
}
.dropdownLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkedImage {
  height: 10px;
  margin-right: 9px;
}

.withoutCheckMargin {
  margin-left: 25px;
}

.selectGrey {
  display: flex;
  align-items: center;
  width: 176px;
  &.fromScreener{
    width: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  :global {
    .ant-select-selector {
      border-radius: 4px !important;
      border: none !important;
      background-color: var(--EAEEF1-2D2D2D) !important;
      box-shadow: none!important;
      color: var(--2D3436_BCBCBC);


      .ant-select-selection-item {
        font-family: @font-family-roboto;
        font-weight: @font-weight-400;
        font-size: @font-size;
        line-height: @line-height-base;
        display: flex;
        align-items: center;
        color: var(--2D3436_BCBCBC);
        background: var(--EAEEF1-2D2D2D);

        img {
          display: none;
        }
      }
    }
  }
}

.select {
  display: flex;
  align-items: center;
  width: 176px;
  &.fromScreener{
    width: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  :global {
    .ant-select-selector {
      border-radius: 4px !important;
      border: none !important;
      background-color: var(--EAEEF1_191A1C) !important;
      box-shadow: none!important;
      color: var(--2D3436_BCBCBC);


      .ant-select-selection-item {
        font-family: @font-family-roboto;
        font-weight: @font-weight-400;
        font-size: @font-size;
        line-height: @line-height-base;
        display: flex;
        align-items: center;
        color: var(--2D3436_BCBCBC);
        background: var(--EAEEF1_191A1C);

        img {
          display: none;
        }
      }
    }
  }
}


.dropdown {
  z-index: 89;
  padding: 0;
  background-color: var(--dropdown-bg-color);
  box-shadow: 0 0 16px 0 var(--allocation-dropdown-shadow);
  border-radius: 6px;

  :global {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: initial;
    }
    .ant-select-item-option {
      padding: 10px 16px 10px 12px;
      background-color: var(--dropdown-bg-color);

      &:hover {
        background-color: var(--EAEEF1_191A1C);
      }


      .ant-select-item-option-content {
        color: var(--2D3436_BCBCBC);
        font-weight: normal;
        div{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .ant-checkbox-wrapper {
      color: var(--2D3436_BCBCBC);
      line-height: @line-height-base;
      font-weight: @font-weight-400;

      .ant-checkbox + span {
        padding-left: 10px;
      }

      .ant-checkbox-disabled + span {
        color: var(--title-color);
      }
    }
    .ant-checkbox-inner {
      height: 18px;
      width: 18px;
    }
    input[type=checkbox]:not(:checked) + span {
      background-color: var(--table-bg-color);
      //border-color: var(--B2BEC3-BCBCBC) !important;
    }
  }

}

.footerSelect {
  margin: 12px;
}
