@import '~theme/variables.less';

.relatedStocksContainer {
  background: var(--card-bg-color);
  border: 1px solid var(--main-border-color);
  border-radius: @border-radius-base;

  padding: 16px 24px;

  margin-top: 24px;
}

.title {
  font-size: 18px;
  line-height: 23px;
  color: var(--title-color);
  font-family: @font-family-futura;
  margin-bottom: 16px;
}

.relatedStocksSection {
  display: flex;
  flex-wrap: wrap;

  > div:not(:nth-child(3n)) {
    position: relative;
    padding-right: 24px;
  }

 > div:not(:nth-child(3n)):after {
    content: '';
    position: absolute;
    width: calc(100% - 24px);
    top: 0;
    border-top: 1px solid var(--tradingDetails-overview-border-color);
  }

  > div:nth-child(3n) {
    border-top: 1px solid var(--tradingDetails-overview-border-color);
  }

  > div:nth-child(-n+3){
    margin-top: 0;
    padding-top: 0;
  }
  > div:nth-child(-n+2):after, div:nth-child(3)  {
    border-top: none;
  }
}
.oneBorder{
  display: flex;
  justify-content: space-between;
  flex: 0 33.3%;
  margin-top: 11px;
  align-items: center;
  padding-top: 9.5px;
  height: 47px;

}
.twoBorder{
  display: flex;
  justify-content: space-between;
  flex: 0 33.3%;
  margin-top: 11px;
  align-items: center;
  padding-top: 9.5px;
  height: 47px;
  position: relative;
  padding-right: 24px;


}
.twoBorder:after{
  content: '';
  position: absolute;
  width: calc(100% - 24px);
  top: 0;
  border-top: 1px solid var(--tradingDetails-overview-border-color);
}
.relatedStockSection {
  display: flex;
  justify-content: space-between;
  flex: 0 33.3%;
  margin-top: 11px;
  align-items: center;
  padding-top: 9.5px;
  height: 47px;
  > div:nth-child(3) {
    margin-top: 4px;
    padding-top: 0;
  }
  > div:nth-child(2) {
      div:nth-child(1) {
        font-weight: @font-weight-500;
        margin-top: 4px;
        padding-top: 0;
    }
  }
}

.relatedStock {
  display: flex;
  align-items: center;

  > div:nth-child(1) {
    margin-right: 10px;
  }
}

.itemDescription {
  font-size: 12px;
  line-height: 14px;
  font-family: @font-family-roboto;

  > div:nth-child(1) {
    margin-bottom: 4px;
    font-weight: @font-weight-500;
    color: var(--form-text-color);
    max-width: 224px;
  }

  > div:nth-child(2) {
    color: var(--table-header-color);
  }
}
