@import '~theme/variables.less';

.wrapper {
  display: flex;
}

.filterPeriod {
  .button {
    width: 104px;
    height: 32px;
    padding: 0 12.4px 0 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    text-transform: none;
    border-radius: 4px;
    background-color: var(--EAEEF1-2D2D2D);
    border: none;
    cursor: pointer;


    path {
      fill: var(--2D3436-EFBA58)
    }

    :global {
      .ant-typography {
        height: 24px;
        width: 73px;
        padding-top: 6px;
        text-align: left;

        font-family: @font-family-roboto;
        font-style: normal;
        font-weight: @font-weight-400;
        font-size: 14px;
        line-height: 16px;
        color: var(--2D3436-BCBCBC);

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.active {
  background-color: var(--F7F7F8-444444) !important;
}

.filter {
  .button {
    width: 144px;
    height: 32px;
    margin-right: 20px;
    padding: 0 12.4px 0 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    text-transform: none;
    border-radius: 4px;
    background-color: var(--EAEEF1-2D2D2D);
    border: none;
    cursor: pointer;


    path {
      fill: var(--2D3436-EFBA58)
    }

    :global {
      .ant-typography {
        height: 24px;
        width: 110px;
        padding-top: 6px;
        text-align: left;

        font-family: @font-family-roboto;
        font-style: normal;
        font-weight: @font-weight-400;
        font-size: 14px;
        line-height: 16px;
        color: var(--2D3436-BCBCBC);

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .arrow {
    margin-left: 6px;
  }
}

.menuPeriod {
  width: 130px;
  box-shadow: 0 0 16px 0 #00000014;
  border-radius: 12px;
}

.menu {
  max-width: 120%;

  box-shadow: 0 0 16px 0 #00000014;
  border-radius: 12px;
}

.multMenuPeriod {
  background-color: var(--FFFFFF-2D2D2D);
  border-radius: 4px;
  padding: 4px 0;

  :global {
    .ant-radio-wrapper {
      height: initial;
      color: var(--2D3436-BCBCBC);
      padding: 4px 12px;
    }
  }
}

.multMenu {
  background-color: var(--FFFFFF-2D2D2D);
  border-radius: 4px;

  :global {
    .ant-checkbox-wrapper {
      height: initial;
      color: var(--2D3436-BCBCBC);
    }
  }
}

.symbolAndNameWrapper {
  padding-bottom: 8px;
  cursor: pointer;
  word-break: break-word;
  &:last-child{
    padding-bottom: 0;
  }
}

.searchInput {
  min-width: 180px;
  margin: 0 20px;
  min-height: 32px;
  background-color: var(--EAEEF1-2D2D2D);

  :global {
    .ant-input-suffix {
      margin-top: 3px;
    }
  }

  path {
    fill: var(--2D3436-BCBCBC);
    transform: scale(0.75);
  }
}

.mainWrapper {
  background-color: var(--FFFFFF-2D2D2D);
  width: 150px;
  color: var(--2D3436-BCBCBC);
}

.emptyWrapper {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-left: 6px;
  color: var(--B2BEC3-BCBCBC);
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonCustomPeriod {
  width: 100%;
  height: 32px;

  /* grey / 100 */
  cursor: pointer;

  border-top: 1px solid var(--F7F7F8-444444);
  border-bottom: 0;
  border-right: 0;
  border-left: 0;

  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;

  color: var(--2D3436-BCBCBC);
  background-color: var(--FFFFFF-2D2D2D);

  &:hover {
    border: 1px solid var(--F4F5F6-444444);
    background: var(--F4F5F6-444444);
  }
}

.range {
  border: none;
  border-radius: 4px;
  background: var(--EAEEF1-2D2D2D);
  box-shadow: none;
  :global {
    .ant-picker-input > input {
      color: var(--2d3436-FFFFFF);
    }
    .ant-picker-separator {
      color: var(--2d3436-FFFFFF);
    }
    path {
      fill: var(--2D3436-BCBCBC);
    }
  }
}

.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;

  /* gold/500 */

  color: #EFBA58;
  margin: 26px 0;
}

.textFooter{
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 14px;
  line-height: 12px;
}

.popoverStyle {
  background: var(--FFFFFF-2D2D2D);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding-top: 0;
  :global {

    .ant-popover-inner-content {
      padding: 12px;
      width: 180px;
    }

    .ant-popover-inner {
      background: var(--FFFFFF-2D2D2D);
      border: none;
      box-shadow: none;
    }
  }
}
