@import '~theme/variables.less';

.loaderWrap{
  position: relative;
  height: 24px;
}
.loader{
  top: -8px;
  margin-left: -8px;
  position: absolute;
}
.card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  grid-column-gap: 16px;
  position: relative;
}
.infoBlock{
  display: grid;
  align-content: center;
  .logoWrap{
    display: grid;
    grid-template-columns: max-content 1fr;
    align-content: center;
    align-items: center;
    grid-column-gap: 16px;
  }
  .logo {
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 64px;
    max-width: 64px;
    align-self: start;

    img {
      width: 64px;
    }
  }
  .title {
    font-weight: @font-weight-500;
    font-size: 18px;
    line-height: 21px;
    color: var(--main-text-color);
    margin-bottom: 8px;
    user-select: text;
  }

  .subInfo{
    display: flex;
    align-items: center;
    align-content: center;
  }

  .name {
    font-weight: @font-weight-500;
    font-size: 14px;
    line-height: 16px;
    color: var(--2D2D2D-84949A);
    margin-right: 8px;
    margin-top: 3px;
    user-select: text;
  }

  .exchangeBuyWidget {
    font-family: @font-family-roboto;
    font-style: @font-style;
    font-weight: @font-weight-400;
    font-size: @font-size;
    margin-top: 3px;
    line-height: @line-height-base;
    letter-spacing: -0.0125em;
    color: #84949A;
  }

  .type_bg {
    background: @blue;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
  }

  .type {
    font-family: @font-family-futura;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: left;
    border-radius: 5px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .infoIcon{
    padding: 0 8px 0 0;
  }
  .controlBlock {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .add_to_watchlist_button {
    width: fit-content;
    height: 24px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 14.06px;
    color: @button-secondary;
    cursor: pointer;
    text-align: end;

    margin-right: 18px;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > span:nth-child(2) {
      white-space: nowrap;
    }

    svg{
      width: 16px;
      height: 15.3px;
      margin: 0 8px 0 4px;
      path{
        fill: @button-secondary;
      }
    }
  }
  .spin{
    margin-left: 4px;
  }
}
.priceBlock {
  min-height: 96px;
  width: 224px;
  display: grid;
  color: var(--value-color-portfolio-price);
  align-content: center;

  .priceContent{
    margin-bottom: 16px;
  }

  .label{
    color: var(--table-header-color);
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    margin-bottom: 4px;
    padding-left: 3px;
  }

  .largePrice {
    span {
      font-size: 24px;
      line-height: 24px;
      font-weight: @font-weight-500;
    }
  }

  .atClose {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    color: var(--table-header-color);
    font-family: @font-family-roboto;
    padding-left: 3px;
  }

  :global {
    .anticon svg {
      margin-top: 2px;
      height: 10px;
    }
  }

  .excitement {
    font-weight: @font-weight-500;
    font-size: 20px;
    line-height: 20px;
    margin-left: 3px;
  }

  .excitement_plus {
    color: @excitement-color_plus;
  }

  .excitement_minus {
    color: @excitement-color_minus;
  }
}

.actionBlock{
  display: grid;
  justify-content: center;

  .stock {
    margin-bottom: 15px;
    width: 100%;
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;
    color:var(--title-stock);
    margin-top: 0;

    white-space: normal;
    text-overflow: ellipsis;
    display: block;
    text-align: center;
    text-transform: uppercase;
    cursor: default;
    &.isBuyWidget{
      margin-bottom: 11px;
    }
  }

  .price{
    font-weight: @font-weight-500;
  }
  .scheduleWrap{
    display: grid;
    justify-content: center;
    margin-bottom: 15px;
    &.isBuyWidget{
      margin-bottom: 13px;
    }
  }
  .btnWrap{
    display: grid;
    grid-template-columns: 0fr 0fr;
    justify-content: flex-end;
    grid-column-gap: 16px;
  }
  .button {
    width: 160px !important;
    margin-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
    height: 40px;
  }

  .button[disabled] {
    pointer-events: none;
  }

  .quotes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    color: var(--value-color-portfolio-price);
  }

  .bidAsk {
    font-size: 12px;
    font-weight: @font-weight-500;
    line-height: 14px;
    color: #84949A;
    margin-right: 1px;
  }
  .dash{
    padding-left: 3px;
  }

  .instrument_temporarily_disabled, .close_existing_positions{
    font-size: 12px;
    font-weight: @font-weight-400;
    line-height: 14px;
    color: var(--table-header-color);
  }

  .instrument_temporarily_disabled {
    display: flex;
    justify-content: center;
    margin-bottom: 2px;
  }
  .close_existing_positions{
    max-width: 110px;
    text-align: center;
    margin: 0 auto;
  }

}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.38;
}


