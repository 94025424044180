@import '~theme/variables.less';

.wrapper {
  background: var(--card-bg-color);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px 24px 17px;
  height: 100%;

  .container {
    height: 100%;
    margin-bottom: 10px;
  }

  &.bordered {
    height: 100%;
    border: 1px solid var(--main-border-color);
    border-radius: 12px;
  }
  &.borderedFull {
    border: 1px solid var(--main-border-color);
    border-radius: 12px;
  }
}



.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  :global {
    h4.ant-typography {
      margin-right: @margin-md;
      min-width: 135.2px;
      .card_title(18px);
    }
  }

  .mode_changer {
    width: auto;
    margin-right: @margin-md;
  }
}

.expand_button {
  position: absolute;
  z-index: 80;
  right: 30px;
  transform: translateY(13px);



  &.hidden {
    display: none;
  }
}

.prompt {
  font-size: @font-size-sm;
  line-height: @line-height-md;
  color: var(--performance-prompt-color);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .twr, .mwr {
    span:first-child {
      font-weight: 700;
    }
    span:nth-child(3) {
      text-decoration: underline;
    }
  }
}

.performanceDate {
  color: var(--performance-prompt-color);
  font-size: @font-size-sm;
  line-height: 14px;
  margin-top: 4px;
}

.wrapperLoading {
  background: var(--card-bg-color);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 4px 0;
  height: 100%;

  .container {
    height: 100%;
    margin-bottom: 10px;
  }

  &.bordered {
    height: 100%;
    border: 1px solid var(--main-border-color);
    border-radius: 12px;
  }
  &.borderedFull {
    border: 1px solid var(--main-border-color);
    border-radius: 12px;
  }
}

.mainTitleWrapper {
  display: flex;
  justify-content: space-between;
}

.wrapperTwoLoading {
  display: flex;
}

.globalStyleForLoading() {
  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.titleLoading {
  width: 159.5px;
  height: 24px;
  margin-right: 24px;
  .globalStyleForLoading;
}

.buttonLoading {
  width: 196px;
  height: 24px;

  .globalStyleForLoading;
}

.chartPortfolioLoading {
  .globalStyleForLoading;

  margin: 24px 0 26px;
  min-height: 225px;
}

.chartLoading {
  .globalStyleForLoading;

  margin: 24px 0 26px;
  min-height: 296px;
}

.footerWrapper {
  .globalStyleForLoading;
  width: 653px;
  height: 24px;
}
