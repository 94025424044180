@import '~theme/variables.less';

.wrapper_small {
  border: none !important;
  margin-top: -50px !important;
}

.wrapper {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 25px;
  background: var(--card-bg-color);

  .header {
    display: flex;
    justify-content: space-between;
  }

  .time {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 14px;
    line-height: 18px;
    color: #84949A;
  }

  .title {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
    text-align: left;
    text-transform: capitalize;
  }

  .overview_block {
    margin-top: 20px;
  }

  .gridCol {
    display: grid;
    grid-template-columns: 47% 47%;
    grid-gap: 2px;
    column-gap: 40px;
  }

  .block {

    display: grid;
    grid-template-columns: 20% 20% 60%;
    height: 31px;
    border-bottom: 1px solid var(--main-border-color);

    .blockName {
      font-family: @font-family-futura;
      font-style: normal;
      font-weight: @font-weight-500;
      font-size: 14px;
      line-height: 14px;

      display: flex;
      flex-direction: row;
      align-items: center;

      /* grey / 500 */

      color: #84949A;
    }

    .blockValue {
      font-family: @font-family-roboto;
      font-style: normal;
      font-weight: @font-weight-500;
      font-size: 12px;
      line-height: 14px;

      display: flex;
      flex-direction: row;
      align-items: center;

      /* Black/Base */

      color: var(--performance-text-color);
    }

    .bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .barWrapper {
        width: 1px;
        height: 24px;
        background-color: var(--etf-performance-separators);
        position: relative;

        div {
          height: 12px;
          background: #18954A;
          border-radius: 0 2px 2px 0;

          position: absolute;
          top: 25%;
          max-width: 90px;
          @media (max-width: 1580px) {
            max-width: 60px;
          }
        }
      }

      .neg {
        transform: rotate(0.5turn);
        div {
          background: #F73C30;
          max-width: 90px;
          @media (max-width: 1580px) {
            max-width: 50px;
          }
        }
      }
    }
  }

}

