@import '~theme/variables.less';
@import '~common/application/error/components/ErrorPage.module.less';

.portfolioOptimization {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  :global {
    .ant-collapse-header {
      align-items: center !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 24px;
    }

    .ant-collapse.ant-collapse-icon-position-left, .ant-collapse-content {
      background: var(--FFFFFF-111111);
      border-color: var(--EAEEF1_2D2D2D);
    }

    .ant-collapse.ant-collapse-icon-position-left {
      border-radius: 12px;
    }

    .ant-collapse > .ant-collapse-item {
      border-color: var(--EAEEF1_2D2D2D);

      &.ant-collapse-item-disabled {
        > .ant-collapse-header span {
          background: var(--F7F7F8-2D2D2D);
          opacity: 0.5;
        }
      }
    }


    .ant-collapse-content {
      border-top: 0;
    }

    .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.breadcrumbs {
  height: 32px !important;

  span {
    color: var(--B2BEC3-EAEEF1) !important;
  }

  :global {
    a {
      color: var(--B2BEC3-EAEEF1) !important;
    }
  }
}

.expandIcon {
  width: 24px;
  height: 24px;
  background: var(--EAEEF1-5E5F61);
  border-radius: 6px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.optimizationSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  justify-content: center;

  .optimizationSettingsTitle {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--525D61-EAEEF1);
  }

  .explanationText {
    color: var(--84949A-BCBCBC);
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: -0.0125em;
    max-width: 806px;
    height: 32px;
  }
}

.stepsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  min-width: 100%;
}

.cardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  //padding: 24px 24px 0;
  gap: 16px;
  background: var(--FFFFFF-111111);
  min-width: 32.5%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: var(--EAEEF1-2D2D2D);
  border-radius: 0 0 12px 12px;
  &:first-child {
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-bottom-left-radius: 0;
  }

  &:not(.cardWrapperBig) {
    &:not(:last-child):nth-child(2) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.cardWrapperBig {
  min-width: 66%;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 32px;
  height: calc(100vh - 390px);
  min-width: 100%;
  min-height: 430px;
}

.textAndDescription {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 16px 24px;
  width: 100%;
}

.textAndSearch {
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
}

.cardTitle {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--525D61-EAEEF1);
}

.cardDescriptionBudget {
  margin-top: -8px;
}

.cardDescription {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--84949A-BCBCBC);
}

.budgetWrapper {
  font-family: @font-family-roboto;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: var(--636E72-BCBCBC);
  min-width: 100%;
  gap: 10px;
}

.budgets {
  flex-direction: row;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.budget {
  font-weight: 400 !important;
  color: var(--2D3436_FFFFFF) !important;
  display: inline-flex;
}

.optimizationBudget {
  font-family: @font-family-roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 100%;
  color: var(--84949A-BCBCBC);
}

.resetWrapper {
  position: sticky;
  bottom: 0;
  margin: 0 8px 0 24px;

  > button, button:hover {
    width: 100%;
    bottom: 0;
    margin-left: 0;
    margin-bottom: 12px;
    background: var(--F7F7F8-2D2D2D) !important;
    color: var(--525D61-EAEEF1);
  }

  > button:disabled {
    background: var(--button-disabled-bg) !important;
  }
}

.generateBtn {
  width: 100%;
  margin-bottom: 24px;

  button{
    height: 48px;
    width: 100%;
    margin: 0;
  }
}

.input {
  :global {
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      background: var(--FFFFFF-191A1C);
      border-color: var(--error-color);
      color: var(--error-color);

      .ant-input-prefix, .ant-input {
        color: var(--error-color);
      }
    }

    .ant-input-input-wrap {
      width: calc(100% - 75px);
      font-size: 16px;
      line-height: 22px;
    }

    .ant-input-affix-wrapper {
      background: var(--FFFFFF-191A1C);
      border: 1px solid var(--DFE6E9-525D61);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px 0 10px 16px;
      font-size: 16px;
      width: 100%;
    }

    .ant-input-prefix {
      color: var(--2D2D2D-FFFFFF);
    }
    .ant-input-handler,
    .ant-input-handler-wrap:hover .ant-input-handler,
    .ant-input-handler-up:hover,
    .ant-input-handler-up:hover,
    .ant-input-handler-down:hover {
      height: 100% !important;
      border: none;
      outline: none;
    }

    .ant-input-handler-wrap{
      opacity: 1;
      display: flex;
      width: 60px;
      border-radius: 1px;
      background: var(--FFFFFF-191A1C);
      margin-right: 10px;
      flex-direction: row-reverse;
    }

    .ant-input-prefix {
      margin-inline-end: 1px;
    }

    .ant-input-handler:active {
      background: var(--FFFFFF-191A1C);
    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      outline: none;
      box-shadow: none;
    }

    .ant-input {
      border-radius: 0 !important;
      height: 20px;
      background: var(--FFFFFF-191A1C);
      color: var(--2D2D2D-FFFFFF);
      display: flex;
      align-items: center;
    }

    .ant-input-suffix {
      .anticon {
        color: @button-main;
        cursor: pointer;
        font-size: 20px;
        margin-right: 15px;

        &.anticon-minus {
          margin-right: 25px;
        }

        &[disabled] {
          color: gray;
          cursor: default;
          pointer-events: none;
        }
      }
    }

    .ant-input-handler-up-disabled,
    .ant-input-handler-down-disabled {
      cursor: default;
    }
  }
}

.errorBody {
  margin-top: 8px;
  text-align: center;
}

.errorText {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--error-color);
}

.optimizationBudgetLabel {
  display: block;
  margin-bottom: 10px;
}

.chipBtns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;

  .leftBtns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
    flex: none;
    order: 0;
    flex-grow: 1;
  }

  .chipBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 4px;
    border: 1px solid var(--B2BEC3-444);
    border-radius: 16px;
    font-family: @font-family-roboto;
    font-weight: @font-weight-500;
    font-size: @font-size-sm;
    line-height: @line-height-md;
    color: var(--525D61-E6E6E6);
    cursor: pointer;

    &.disabled {
      cursor: default;
      background: var(--EAEEF1-444444);
      opacity: 0.38;
      pointer-events: none;
    }

    &:hover:not(.disabled) {
      background: #F5DBAC;
      color: #CF9322;
    }
  }
}

.instrumentSelectorWrapper {
  width: 100%;
}

.portfolioInstruments {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 220px);
  overflow: auto;
  padding-right: 16px;
  width: 100%;
  flex: 1;
  margin-top: -20px;

  .instrumentsWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding: 0 28px;;
  }
}

.elementWrapper {
  display: flex;
  justify-content: space-between;
  padding: 9px 0;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
  gap: 5px;
  cursor: pointer;

  &.noEvents {
    cursor: default;
    pointer-events: none;
  }
}

.noPointer {
  cursor: default;
}

.noBorder {
  border-bottom: none;
}

.imgAndTextWrapper {
  display: flex;
  align-items: center;
}

.selectCheckbox {
  margin-right: 16px;
  :global {
    .ant-checkbox {
      &:not(.ant-checkbox-checked) {
        .ant-checkbox-input + .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
          border: 2px solid var(--B2BEC3-444);
          background-color: var(--main-bg-color);
        }
      }
    }
  }
}

.selectCheckbox {
  &.disabled {
    :global {
      .ant-checkbox {
        &:not(.ant-checkbox-checked) {
          .ant-checkbox-input + .ant-checkbox-inner,
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            opacity: 0.38;
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }
  }
}

.wrapperText {
  margin-left: 12px;
}

.titleDescr {
  font-family: @font-family-roboto;
  font-weight: @font-weight-500;
  font-size: @font-size-sm;
  line-height: 14.06px;
  color: var(--2d3436-FFFFFF);

  &.disabled {
    color: var(--84949A-BCBCBC);
    opacity: 0.38;
  }
}

.titleName {
  font-family: @font-family-roboto;
  font-weight: @font-weight-400;
  font-size: @font-size-sm;
  line-height: 14.06px;
  color: var(--84949A-B2BEC3);

  &.disabled {
    color: var(--84949A-BCBCBC);
    opacity: 0.38;
  }
}

.excitement {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
}

.secondary{
  color: var(--text-secondary-color);
  font-size: 12px;
  line-height: 14px;
}

.right{
  text-align: right;
}

.price {
  font-weight: 500;
}

.prices {
  text-align: right;
  color: var(--2D3436_FFFFFF);

  &.disabled {
    font-family: @font-family-roboto;
    font-weight: @font-weight-400;
    font-size: @font-size-sm;
    line-height: @line-height-md;
    display: flex;
    align-items: center;
    color: var(--84949A-BCBCBC);
    width: 130px;
    opacity: 0.6;
  }
}

.noDataSectionWrap{
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.noDataSection {
  width: 218px;
  text-align: center;

  > svg {
    path{
      fill: var(--no-trading-data-svg-background);
      stroke: var(--no-trading-data-svg-stroke);
    }
    ellipse{
      fill: var(--no-trading-data-svg-ellipse);
    }
  }

  .noDataTitle {
    margin-top: 5px;
    font-family: @font-family-futura;
    font-size: 18px;
    line-height: 23px;
    color: var(--no-data-text-color);
  }
  .noDataSubTitle {
    margin-top: 8px;
    font-family: @font-family-roboto;
    font-size: 14px;
    line-height: 16px;
    color: var(--no-data-text-color);
  }
}

.errorDescription {
  white-space: break-spaces;
}

.errorContainerHeight {
  height: 342px;
}

.errorWrapper {
  background: var(--FFFFFF-111111);
  border: 1px solid var(--main-border-color);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0 88px 0 82px;
  width: 464px;
  height: 342px;
  text-align: center;

  .error {
    line-height: 18px;
    color: var(--84949A-BCBCBC)
  }

  .imageContainer {
    rect {
      fill: var(--error-svg-circle-color)
    }
  }

  .button {
    width: 240px;
    height: 48px;
    margin: 0;

    span {
      line-height: 32px;
    }

    svg {
      width: 14px;
      height: 12px;
      margin-right: 8.67px;

      path {
        fill: var(--FDFCFA-2D2D2D);
      }
    }
  }
}

.noPointerEvents {
  pointer-events: none;
  user-select: none;
}

.logoDisabled {
  opacity: 0.38;
}
