@import '~theme/variables.less';

.gridWrap{
  min-width: 1358px;
  position: relative;
  min-height: calc(100vh - @header-height);
  margin-bottom: 10px;
  padding: 24px;
  tr{
    cursor: pointer;
  }
}

.gridNewsAndGainer {
  display: grid;
  grid-template-columns: 60% 40%;
}

.gridNews {
  display: grid;
  grid-template-columns: 100%;
}

.gridScheme {
  display: flex;
  gap: 24px;
  margin-top: 24px;

  #full_screen_icon {
    margin-top: 35px;
  }

  .portfoliosWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
  }

  .portfoliosChartWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
  }
}
