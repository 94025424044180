@import '~theme/variables.less';

.header_content {
  position: relative;

  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 8px 24px 0 20px;

  border-bottom: 1px solid var(--main-border-color);

  transition: all 0.2s ease-out;
  opacity: 1;
  height: auto;

  &.hidden{
    //display: none;
    padding: 0 24px 0 20px;
    min-height: 0;
    max-height: 0;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .screeners_container {

    .screeners {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      &.close {
        height: 40px;
      }

      .screener {
        height: 32px;
        border-radius: 16px;
        padding: 0 @padding-sm;
        font-size: @font-size;
        line-height: @line-height-base;
        margin-right: 8px;
        margin-top: 0;
        margin-bottom: 8px;
        color: @primary-color;
        cursor: pointer;
      }
    }
  }

  .mode_changer {
    width: auto;
  }

  .show_more {
    position: relative;

    width: 100%;
    height: 20px;

    display: flex;
    justify-content: center;

    border-bottom: 1px solid var(--main-border-color);

    .button {
      position: absolute;
      top: 6px;
      rect {
        stroke: var(--main-border-color);
        fill: var(--screeners-show-more-arrow-bg);
      }
      path {
        fill: var(--screeners-show-more-arrow);
      }
    }
  }

  :global {
    .ant-dropdown-menu {
      background-color: var(--dropdown-bg-color);
    }

    .ant-dropdown-menu-item {
        color: var(--main-text-color);

        &:hover {
          background-color: var(--active-button-bg-color);
        }
    }
  }
}

.marker{
  width: 10px;
  height: 10px;
  background: @positive-color;
  border-radius: 8px;
}
