@import '~theme/variables.less';

.wrapper {
  min-width:328px;
  top:0;
}
.parentBlocks{
  display: flex;
  align-items: center;
  z-index: 2000;
}
.wrapperHeader {
  display: flex;
  width: 100%;

  > svg {
    cursor: pointer;
  }
}

.bidAsk{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 4px;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 16px;
  color: var(--636E72-BCBCBC);
  & >div{
    text-align: center;
  }
}

.titleModal {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 22px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  /* grey/700 */

  color: var(--title-color);
}

.wrapperStockInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  border-bottom: 1px solid var(--main-border-color);
  padding-bottom: 15px;
}

.logoWithDescription {
  display: flex;
}

.description {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;

  /* grey / 900 */
  color: var(--main-text-color);
}

.name {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;

  /* grey / 500 */

  color: var(--84949A-BCBCBC);

}

.wrapperStockNameDesc {
  margin-left: 8px;
}

.wrapperPrice {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;

  /* grey / 900 */

  color: var(--main-text-color);
}

.textNotify {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;

  /* grey / 500 */

  color: var(--84949A-BCBCBC);
}

.textIs {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-top: 12px;

  /* grey / 500 */

  color: var(--84949A-BCBCBC);
}

.textEqual {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;

  /* identical to box height */

  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;

  /* grey / 500 */

  color: var(--84949A-BCBCBC);
}

.input {
  :global {
    .ant-input-affix-wrapper {
      background: var(--FFF-333333) !important;
      border: 1px solid var(--B2BEC3-4a4a4a);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px 0 10px 16px;
      color: var(--value-color-portfolio-widget);
    }
    .ant-input-prefix{
      margin-right: 0;
      font-size: 16px;
    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      outline: none;
      box-shadow: none;
    }
    .ant-input {
      background: var(--FFF-333333);
      border-radius: 0 !important;
      height: 20px;
      color: var(--value-color-portfolio-widget);
      font-family: @font-family-roboto;
      font-size: 16px;
      line-height: 22px;
    }

    .ant-input-affix-wrapper-status-error .ant-input-prefix {
      color: var(--value-color-portfolio-widget);
    }

    .anticon {

      :first-child {
        margin: 0 10px;
      }

      color: @button-main;
      cursor: pointer;
      font-size: 20px;
    }
  }
}
.button{
  margin: 0 auto;
  width: 100%;
}
.error{
  display: flex;
  height: 40px;
  text-align: center;
  color: #e17055;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  margin: 4px 60px 0;
}
.link{
  color: @button-main;
  &:hover{
    color: @button-main;
  }
}
.wrapActions{
  margin-top: 24px;
  button {
    height: 48px;
  }
}

.error_text {
  color: #E86E72;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.error_text_small {
  color: #E86E72;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.error_input {
  border: 1px solid #E17055 !important;
}


