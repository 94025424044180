@import '~theme/variables.less';

.errorOrderTitle {
  > span {
    color: var(--2D3436_FFFFFF);
    width: 198px;
    font-size: 14px;
    line-height: 16px;
    font-family: @font-family-roboto;
    margin-left: 10px;
    font-weight: 500;
  }
}

.errorOrderWrapper {
  margin-top: 44px;
}

.retryBtn {
  font-family: @font-family-roboto;
  font-style: @font-style;
  font-weight: @font-weight-500;
  font-size: @font-size-sm;
  line-height: @line-height-md;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
  margin-top: 13px;
  cursor: pointer;
  color: var(--gold-text-color);
}

.errorOrderItem, .errorOrderReason {
  display: flex;
  justify-content: space-between;
  line-height: 14px;
  font-size: 12px;
  font-family: @font-family-roboto;
  margin-bottom: 4px;
  text-align: right;

  > div:nth-child(1) {
    color: #84949A;
  }

  > div:nth-child(2) {
    font-weight: 500;
    color: var(--525D61-FFFFFF);
  }
}

.errorOrderReason {
  > div:nth-child(2) {
    color: #E17055 !important;
  }
}
