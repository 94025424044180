@import '~theme/variables.less';

.logo_32 {
  min-width: 32px;
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: @font-weight-500;
  font-size: 9px;
  letter-spacing: -0.041px;
  color: #84949A;
  font-family: @font-family-roboto;
}

.logo_64 {
  min-width: 64px;
  font-family: @font-family-futura;
  width: auto;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: @font-weight-700;
  font-size: 10px;
  letter-spacing: -0.041px;
  color: #84949A;
  border-radius: 8px;
}

.logo_40 {
  min-width: 40px;
  font-family: @font-family-futura;
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: @font-weight-600;
  font-size: 5px;
  letter-spacing: -0.041px;
  color: #84949A;
  border-radius: 8px;
  line-height: 22px;;
}
.logo_24 {
  min-width: 24px;
  font-family: @font-family-futura;
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: @font-weight-600;
  font-size: 5px;
  letter-spacing: -0.041px;
  color: #84949A;
  border-radius: 8px;
  line-height: 22px;;
}
