@import '~theme/variables.less';

.bordered {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  margin-top: 24px;
  padding: 16px 24px 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--card-bg-color);

  .title {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
    text-align: left;
    text-transform: capitalize;
  }
}

.wrapperCompanyInf {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
}

.wrapperCompanyInfWithoutBorder {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}

.nameStyle {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 14px;
  color: #84949A;
}

.wrapperCountryValue {
  display: flex;
  flex-direction: column;
}

.valueStyle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 14px;
  color: var(--2D2D2D-FFFFFF);
  text-align: end;
}

.countryStyle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 11px;
  line-height: 13px;
  color: #84949A;
  text-align: end;
}
