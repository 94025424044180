@import '~theme/variables.less';

.marketDataRangeContainer,
.stockMarketDataRangeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 91.5px 0 91.5px;
}

.stockMarketDataRangeContainer {
  margin: 8px 0 0;
}

.marketDataRangeTitle {
  font-family: @font-family-futura;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 17.95px;
  color: var(--title-color);
  margin-bottom: 20px;
}

.marketDataMainSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rangeSection {
  position: relative;
  width: 100%;
  height: 12px;
  background: var(--background-market-range);
  border-radius: 2px;
  margin: 4px 8px 0 8px;
}

.currentRangeSection {
  position: absolute;
  height: 12px;
  background: #efba58;
  border-radius: 2px;
  width: 20%;
  left: 191px;
}

.minPriceSection,
.maxPriceSection {
  > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: @font-family-futura;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;

    > div:nth-child(1) {
      color: @text-color-secondary;
      margin-bottom: 4px;
    }

    > div:nth-child(2) {
      color: var(--2D3436_FFFFFF);
      font-size: 14px;
    }
  }
}

.marketDataPolygon {
  position: absolute;
  top: -17px;
  left: 231px;

  > div {
    position: relative;
  }
}

.marketDataCurrent {
  position: absolute;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 18px;
  left: -15px;
  bottom: -34px;
  color: var(--market-data-price-color);
  font-family: @font-family-futura;
  cursor: pointer;
}
