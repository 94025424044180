.paginationContainer {
  display: flex;
  justify-content: center;
  margin: 33px 0;
  position: relative;
  height: 32px;

  .paginationItem {
    height: 24px;
    text-align: center;
    margin: auto 10px;
    color: var(--84949A-BCBCBC);
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    line-height: 24px;
    font-size: 14px;
    width: 24px;
    font-weight: 500;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: var(--F7F7F8-444444);
      cursor: pointer;
    }

    &.selected {
      background-color: #EFBA58;
      color: var(--FFF-2D2D2D);
    }

    &.disabled {
      pointer-events: none;

      > div::before {
        border-right: 1.9px solid var(--84949A-BCBCBC);
        border-top: 1.9px solid var(--84949A-BCBCBC);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.perPageDropdown {
  width: 52px;
  min-width: 52px !important;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background-color: var(--modal-bg-color);
  padding: 4px 0;
}

.perPageDropdownItem {
  padding: 8px;
  cursor: pointer;

  span {
    color: var(--2D3436_BCBCBC);
    font-weight: 400;
  }

  &.perPageDropdownItemActive {
    background-color: var(--F7F7F8-444444);

    span {
      font-weight: 500;
    }
  }

  &:hover {
    background-color: var(--F7F7F8-444444);
  }
}

.perPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 24px;

  > span {
    font-size: 14px;
    line-height: 16px;
    color: var(--84949A-BCBCBC);
  }
}

.perPageSection {
  margin-left: 12px;
  border-radius: 6px;
  background-color: var(--EAEEF1-2D2D2D);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7.5px 8px;

  > span {
    color: var(--2D3436-EFBA58);
  }

  path {
    fill: var(--2D3436-EFBA58);
  }

  .perPageArrow {
    margin-left: 4px;
  }
}

.perPageActive {
  background-color: var(--F7F7F8-444444) !important;
}

.leftArrowSection {
  display: flex;
  margin-right: 12px;

  path {
    fill: var(--84949A-BCBCBC)
  }

  > div:nth-child(1) {
    margin-right: 0;
  }

  > div:nth-child(2) {
    margin-left: 4px;
  }
}

.rightArrowSection {
  display: flex;
  margin-left: 12px;

  path {
    fill: var(--84949A-BCBCBC)
  }

  > div:nth-child(1) {
    margin-right: 4px;
  }

  > div:nth-child(2) {
    margin-left: 0;
  }
}
