@import '~theme/variables.less';

.wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 30% 30% 40%;
  margin-top: 24px;
  background: var(--F7F7F8-191A1C);
  border-radius: 8px;
}

.title {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  color: var(--2D3436_FFFFFF);
  text-align: left;
}

.elementWrapper {
  display: flex;
  justify-content: space-between;
  padding: 21px 24px 21px 24px;
  height: 100%;
  align-items: center;
}

.elementWrapperWithoutAllBorder {
  border: 0;
  grid-column: 3;
  grid-row: 1 / 3;
}

.elementWrapperWithoutBorder {
  position: relative;
}

.elementWrapperWithoutBorderRight,
.elementBlock {
  position: relative;
}

.elementWrapperWithoutBorderRight:before,
.elementBlock:before {
  content: "";
  position: absolute;
  right: 5%;
  bottom: 0;
  height: 1px;
  width: 90%;
  border-bottom: 1px solid var(--EDEDED-525D61);
}

.elementWrapperWithoutBorder:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 65%;
  width: 1px;
  border-right: 1px solid var(--EDEDED-525D61);
}

.elementBlock:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 65%;
  width: 1px;
  border-right: 1px solid var(--EDEDED-525D61);
}

.value {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 14px;
    line-height: 16px;
    color: var(--2D3436_FFFFFF);
    text-align: right;
}

:global {
  .color-1 {
    text-transform: uppercase;
    color: #18954A;
  }

  .color-2 {
    text-transform: uppercase;
    color: #62C3A0;
  }

  .color-3 {
    text-transform: uppercase;
    color: #C09F6A;
  }

  .color-4 {
    text-transform: uppercase;
    color: #F68F93;
  }

  .color-5 {
    text-transform: uppercase;
    color: #F25C63;
  }

  .color-6 {
    text-transform: uppercase;
    color: #8884d8;
  }
  .color-7 {
    color: var(--main-text-color)
  }
  .color-8 {
    color: #F73C30
  }
}

.warningWrapper {
  width: 540px;
  height: 64px;

  background: var(--F7F7F7-191A1C);
  border-radius: 8px;

  display: flex;
  align-items: center;
  margin: 24px auto 0;
}

.warningText {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;

  text-align: center;
  padding: 16px 24px;

  /* grey / 500 */

  color: #84949A;
}

.account {
  display: contents;
  font-weight: @font-weight-700;
}

.accountWithPositionWrapper {
  display: contents;
}

.accountWithPosition {
  cursor: pointer;
  color: #EFBA58;
}
