@import '~theme/variables.less';

.error_container {
  background-color: var(--loader-bg-color);
  background-image: var(--error-modal-bg-picture);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-width: @screen-min-width;
  min-height: 102%;
  top: -2%;
  left: 0;

  :global {
    & .ant-empty {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 379px;

    }

    & .ant-empty-footer {
      margin: 16px 0 48px !important;
    }
  }
}

.sign_in_container {
  background: var(--loader-container-bg-color);
  border: 1px solid var(--main-border-color);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0 88px 0 82px;
  width: 480px;
  height: 379px;
  text-align: center;
  /* padding-bottom: 48px;*/

  .image_container {
    rect {
      fill: var(--error-svg-circle-color)
    }
  }

  .button {
    width: 240px;
    height: 48px;

    span {
      line-height: 32px;
    }

    svg {
      Width: 14px;
      Height: 12px;
      margin-right: 8.67px;

      path {
        fill: var(--btn-text-color);
      }
    }
  }
}

.title_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: var(--title-color);
}

.subtitle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: @margin-xs;
  color: var(--loader-hint-color);
  a {
    color: var(--gold-text-color);
    user-select: text;
  }
}
