@import '~theme/variables.less';

.split{
  border-top: 1px solid var(--allocation-dropdown-separator);
}

.title {
  display: flex;
  align-items: center;

  :global {
    h4.ant-typography {
      margin: 0 @margin-md 0 0;
      .card_title(18px);
    }
  }
}

.card {
  height: 100%;
  background-color: var(--card-bg-color);

  :global {
    .ant-card-body {
      min-height: 240px;
      padding: 16px 24px;

      @media (max-width: 1600px) {
        padding: 16px 12px 16px 24px;
      }
    }
  }
}
:global {
  .recharts-label{
    fill: var(--recharts-color) !important;
    stroke: none;
    color: transparent !important;
  }

}

.checkbox {
  input[type="checkbox"] {
    height: 18px;
    width: 18px;
  }
}


.chart {
  stroke-width: 0;
  cursor: pointer;
}

.tag {
  border-radius: 4px;
  height: 40px;
  Padding: 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.tooltip {
  background: var(--tooltip-background);
  color: var(--main-text-color);
  border: 1px solid var(--main-border-color);
}
//.tag::after {
//  content: '';
//  width: 0;
//  height: 0;
//  display: block;
//  position: absolute;
//  z-index: 10;
//  border: 0;
//
//  border-left: 14px solid transparent;
//  border-right: 14px solid transparent;
//  margin-left: -14px;
//  left: 50%;
//  border-top: 14px solid var(--allocation-tooltip-bg);
//  bottom: -8px;
//}
