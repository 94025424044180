@import '~theme/variables.less';

.wrapper {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  padding: 16px 24px 24px 24px;
  margin-top: 24px;
  background: var(--card-bg-color);
  position: relative;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: @font-family-futura;
  font-weight: @font-weight-400;
  font-size: 18px;
  line-height: 23px;
  color: var(--title-color);
}
.secondaryText {
  color: var(--text-secondary-color);
  font-weight: 450;
  font-size: 14px;
  font-family: @font-family-futura;
}
