@import '~theme/variables.less';

.customLegend{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-top: 16px;
  margin-bottom: 8px;
  max-height: 232px;
  overflow: auto;
}

.legend {
  display: flex;
  align-items: center;
  padding: 0 13px;
  background-color: var(--F7F7F8_1D1D1D);
  border: 1px solid var(--card-bg-color);
  border-radius: @border-radius-lg;
  height: 32px;
  overflow: auto;
  cursor: pointer;
  .type{
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .typeValue{
    font-size: 12px;
  }

  //max-width: 99%;

  &.hover{
    border-color: var(--allocation-legend-hover-border);
  }

  &:hover {
    border-color: var(--allocation-legend-hover-border);
  }

  .legend-marker {
    min-width: 8px;
    min-height: 8px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
  }

  span {
    padding-top: 2px;
    color: var(--main-text-color);
    line-height: 14px;
    text-transform: capitalize;
  }

  > span:last-child {
    margin-left: auto;
  }
}


.chart {
  stroke-width: 0;
  cursor: pointer;
}

.tag {
  border-radius: 4px;
  height: 40px;
  padding: 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: capitalize;
}

.tooltip {
  background: var(--tooltip-background);
  color: var(--main-text-color);
  border: 1px solid var(--main-border-color);
}
//.tag::after {
//  content: '';
//  width: 0;
//  height: 0;
//  display: block;
//  position: absolute;
//  z-index: 10;
//  border: 0;
//
//  border-left: 14px solid transparent;
//  border-right: 14px solid transparent;
//  margin-left: -14px;
//  left: 50%;
//  border-top: 14px solid var(--allocation-tooltip-bg);
//  bottom: -8px;
//}
