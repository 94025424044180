@import '~theme/variables.less';

.mainWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 95% 5%;
  border: 1px solid var(--EAEEF1_2D2D2D);
  background: var(--FFFFFF-111111);
  border-radius: 12px;
}

.mainWrapperWithoutGid {
  width: 100%;
  border: 1px solid var(--EAEEF1_2D2D2D);
  background: var(--FFFFFF-111111);
  border-radius: 12px;
}

.generalWrapper() {
  height: 64px;
  //width: 250px;
  display: grid;
  border-right: 1px solid var(--EAEEF1_2D2D2D);
}

.cardWrapper {
  .generalWrapper;
  padding: 8px 16px;
}

.cardWrapperLowPadding {
  .generalWrapper;
  padding: 4px 16px;
}

.nameWrapper {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  grid-column: 1 / 3;
  grid-row: 1;
  cursor: pointer;

  /* grey / 500 */

  color: var(--84949A-BCBCBC);
}

.priceWrapper {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  grid-column: 1 / 3;
  grid-row: 2;
  /* identical to box height */

  /* Black/Base */

  color: var(--2D2D2D-FFFFFF);

}

.priceChangeWrapper {
  grid-column: 1 / 3;
  grid-row: 3;
}

.sparklWrapper {
  width: 100px;
  grid-column: 3;
  grid-row: 1 / 4;
}

.buttonsWrapper {
  display: grid;
  grid-template-columns: 45% 10% 45%;
}

.arrowLeft {
  display: flex;
  justify-content: end;
  -webkit-justify-content: flex-end;
  align-items: center;
}

.arrowRight {
  display: flex;
  align-items: center;
}

.mainLoadingWrapper {
  display: flex;
}

.skeletonWrapper {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr;
  padding: 10px 16px;
  border-right: 1px solid var(--EAEEF1-2D2D2D);
}

.generalSkeleton() {
  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.firstSkeleton {
  grid-column: 1 / 3;
  grid-row: 1;
  width: 104px;
  height: 12px;
  .generalSkeleton;
}

.secondSkeleton {
  grid-column: 1 / 3;
  grid-row: 2 ;
  width: 104px;
  height: 12px;
  .generalSkeleton;
}

.thirdSkeleton {
  grid-column: 1 / 3;
  grid-row: 3;
  width: 104px;
  height: 12px;
  .generalSkeleton;
}

.fourthSkeleton {
  width: 72px;
  height: 44px;
  grid-column: 3 ;
  grid-row: 1 / 4;
  margin-left: 24px;
  .generalSkeleton;
}

.overflowLoadingWrapper {
  display: flex;
  overflow: hidden;
  //background: linear-gradient(270deg, rgba(255, 255, 255, 0) 50%, #bb5858 100%)
}

.skeletonGradient {
  display: flex;
  align-items: center;
  padding: 10px 16px;
}

.skeletonButtons {
  width: 16px;
  height: 16px;
  z-index: 999999;
  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}
