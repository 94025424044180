@import '~theme/variables.less';

.instrumentHistoryContainer {
  display: flex;
  flex-direction: column;

  background: var(--card-bg-color);
  border: 1px solid var(--main-border-color);
  border-radius: @border-radius-base;

  margin-top: @margin-lg;
  tr {
    cursor: pointer;
  }
}

.instrumentHistoryHeader {
  display: flex;
  justify-content: space-between;
  padding: 20.5px 24px;
  align-items: center;
  border-bottom: 1px solid var(--main-border-color);
}

.instrumentHistoryTitle {
  font-family: @font-family-futura;
  font-size: 18px;
  line-height: 23.08px;
  color: var(--title-color);
}

.viewAll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #EAEEF1;
  border-radius: 6px;
  padding: 9px 12px 9px 16px;
  font-family: @font-family-roboto;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 14.06px;
  color: var(--trade-history-btn-color);
  background: var(--trade-history-btn-bg);
  cursor: pointer;

  > div:nth-child(1) {
    margin-right: 9.73px;
  }

  > svg path {
    fill: var(--trade-history-btn-color);
  }
}

.footerButton {
  margin: 8px 24px 24px 24px;
  background: #EAEEF1;
  border-radius: 6px;
  text-align: center;
  padding: 9px 0;
  font-family: @font-family-roboto;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 14.06px;
  color: var(--trade-history-btn-color);
  background: var(--trade-history-btn-bg);
  cursor: pointer;
}

.instrumentHistoryGrid {
  :global {
    .ant-table-body {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    .ant-table-body > table {
      margin-bottom: 24px !important;
    }
    .ant-table-tbody > tr:last-child > td {
      border-bottom: 1px solid var(--main-border-color);
    }
  }
}
