@import '~theme/variables.less';

.search {
  min-width: initial;
  width: 100%;
  margin: 0;
}

div.tooltip {
  :global {
    .ant-tooltip-inner {
      width: inherit;
    }
  }
}

.checkbox {
  input[type=checkbox]:not(:checked) + span {
    background-color: var(--table-bg-color);
    //border-color: var(--B2BEC3-BCBCBC) !important;
  }
  :global{
    .ant-checkbox + span{
      color:var(--2d3436-FFFFFF);
    }
  }

  padding-top: 16px;

  &:nth-child(2) {
    padding-top: 15px;
  }
}
