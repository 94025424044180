@import '~theme/variables.less';

.gridWrap{
  position: relative;
  height: 100%;
}

.star_container, .areaClickPadding {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

:global {
  #watchlist_grid {
    &.disabledDrag{
      tr{
        -webkit-user-drag: none;
      }
      img{
        -webkit-user-drag: none;
      }
    }
    thead{
      tr{
        #watchlist_price_change {
          padding-right: 8px;
        }
        #watchlist_price_change, #watchlist_symbol {
          font-weight: @font-weight-400;
        }
        -webkit-user-drag: none;
        td:first-child{
          padding: 8px 8px 8px 9px;
          width: 10%;
        }
        td:nth-of-type(2) {
          width: 40%;
        }
        td:nth-of-type(3) {
          width: 40%;
        }
        td:first-child:has(> div) {
          cursor: pointer;
        }
      }
    }
    td {
      cursor: pointer;
      position: relative;
    }
    .ant-table-thead > tr > th::before{
      content: none;
    }
    td, th, #table_title {
      background-color: var(--sidebar-btn-color-active);

      &.selected {
        background-color: red !important;
      }
    }
    .ant-table-wrapper {
      --isBordered: 1px solid var(--setting-border-color);
    }
    .ant-table-body{
      background-color: var(--modal-bg-color);
    }

    tr:hover {
      td {
        background-color: var(--table-hover-bg);
      }
    }
    tr:not(:nth-of-type(1)){
      td{
        a{
          display: grid;
        }
      }
      td:first-child{
        padding: 7px;
        text-align: center !important;
      }
      td:nth-child(2){
        a{
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
    .ant-table-thead > tr > th {
      padding: 0px;
    }
    .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
      padding: 7px;
    }
  }
}

.grid {
  height: 100%;

  :global {
    .ant-table-placeholder {
      pointer-events: none;
    }

    .ant-table-footer {
      display: none;
      padding: 0;
      background-color: red !important;
    }

    .ant-table-body {
      max-height: calc(100vh - 145px) !important;
      background-color: var(--modal-bg-color);
      overflow: auto !important;
      padding-bottom: 10px;
    }
  }
}
.wrapTitle{
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 8px;
  padding: 9px 0;
}
