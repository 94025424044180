@import '~theme/variables.less';

.wrap {
  height: calc(100vh - @header-height);
  background-color: var(--trading-container-background);
  position: relative;
}
.wrapHeader{
  border-bottom: 1px solid var(--main-border-color);
}

.breadcrumbs {
  padding: 8px 14px;
  border-bottom: 1px solid var(--main-border-color)
}
