@import '~theme/variables.less';

.bordered {
  border-radius: @border-radius-base;
  border: 1px solid var(--main-border-color);
  margin-top: 24px;
  padding: 20px 24px 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--card-bg-color);
  margin-left: 24px;

  .title {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 18px;
    line-height: 23px;
    color: var(--title-color);
    text-align: left;
    padding-bottom: 10px;
  }

}

.blockCharts {
  min-height: 49px;
  height: 49px;
  display: flex;
  flex-direction: row;
  color: var(--84949A-BCBCBC);
  align-items: center;
  border-bottom: 1px solid var(--main-border-color);

}

.price {
  width: 76px;
  display: flex;
  justify-content: center
}

.name {
  width: 132px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 32px;
  font-weight: @font-weight-500;
  font-size: 14px;
  font-family: @font-family-roboto;
  color: var(--84949A-BCBCBC);
  line-height: 16px;
}

.chart {
  flex-grow: 2;
  max-width: calc(100% - 208px);
  flex-basis: calc(100% - 208px);
}

.positive {
  color: @positive-color;
}

.negative {
  color: @negative-color;
}

.emptyBlock {
  background: var(--card-bg-color);
  border-radius: 12px;
  border: 1px solid var(--main-border-color);
  margin-top: 24px;
  margin-left: 24px;
  height: 388px;
  padding: 16px 24px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainColorAndRadius() {
  background: var(--EAEEF1-2D2D2D);
  border-radius: 4px;
}

.wrapperLoading {
  display: flex;
  justify-content: space-between;
  padding: 8px 0 9px 0;
  border-bottom: 1px solid var(--EAEEF1-2D2D2D);
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
}

.loadingTitle {
  .mainColorAndRadius;
  height: 24px;
  width: 160px;
  margin-bottom: 20px;
}

.loadingName {
  .mainColorAndRadius;
  height: 20px;
  width: 136px;
}

.loadingPrice {
  .mainColorAndRadius;
  width: 80px;
  height: 20px;
}

.loadingChart {
  .mainColorAndRadius;
  width: 160px;
  height: 32px;
}
