@import '~theme/variables.less';

.border {
  border: 1px solid var(--main-border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

.height_64 {
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  border-radius: 4px;
  align-self: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.height_40 {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 3px;
  align-self: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}


.pointer {
  cursor: pointer;
}

.height_32 {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 2px;
  align-self: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.height_24 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  align-self: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}


