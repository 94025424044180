@import '~theme/variables.less';

.wrapper {
  display: flex;
  flex-direction: column;

  background: var(--card-bg-color);
  border: 1px solid var(--main-border-color);
  border-radius: @border-radius-base;

  margin-top: @margin-lg;
}

.wrapperTitle {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 18px;
  line-height: 23px;

  color: var(--title-color);

  margin: 20.5px 0 20.5px 24px;
}

.wrapperDividends {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.wrapperNameAndAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 24px;
  border-top: 1px solid var(--main-border-color);
}

.dividendsReceived {
  display: flex;
  justify-content: space-between;
  grid-row: row 2;
  grid-column: 1/4;
  padding: 16px 24px;
  border-top: 1px solid var(--main-border-color);
  border-bottom: 1px solid var(--main-border-color);
}

.name {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 14px;
  word-wrap: break-word;
  max-width: 200px;

  color: #84949A;
}

.value {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: 14px;
  line-height: 14px;

  color:var(--performance-text-color);

}

.instrumentHistoryGrid {
  :global {
    .ant-table-body {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    .ant-table-body > table {
      margin-bottom: 24px !important;
    }
    .ant-table-tbody > tr:last-child > td {
      border-bottom: 1px solid var(--main-border-color);
    }
  }
}

.footerButton {
  margin: 8px 24px 24px 24px;
  background: #EAEEF1;
  border-radius: 6px;
  text-align: center;
  padding: 9px 0;
  font-family: @font-family-roboto;
  font-weight: @font-weight-500;
  font-size: 12px;
  line-height: 14.06px;
  color: var(--trade-history-btn-color);
  background: var(--trade-history-btn-bg);
  cursor: pointer;
}
