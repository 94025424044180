@import '~theme/variables.less';

.worktime {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: @font-size-sm;
  line-height: @font-size;
  text-transform: uppercase;
  color: var(--table-header-color);
  margin: 10px;
  padding: 5px;
}

.footer_content {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex: 1;
  margin-right: 30px;

  h1 {
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: @font-weight-500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: @text-color-secondary;
  }
}

.flex {
  display: flex;
}

.margin_20 {
  margin-top: 45.3px;
}

.keyObj {
  padding: 0 24px;
}

.bottom_container {
  display: flex;
  justify-content: right;
  align-items: center;

  .more_button_container {
    align-items: center;
    margin: 24px 24px 48px 24px;

    button {
      margin: 0;
      width: 120px;
      height: 40px;
    }
  }
}

.icon {
  margin-bottom: @margin-lg;
  margin-top: 2 * @margin-lg;

  path{
    fill: var(--no-trading-data-svg-background);
    stroke: var(--no-trading-data-svg-stroke);
  }
  ellipse{
    fill: var(--no-trading-data-svg-ellipse);
  }
}

.empty_state_title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  display: flex;
  align-items: center;
  text-align: center;

  justify-content: center;
  color: var(--no-data-text-color);
}

.empty_state_subtitle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 8px;

  /* grey / 600 */
  justify-content: center;
  color: var(--no-data-text-color);
}

.content {
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 1;

  :global {
    .ant-btn-clicked:after {
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      animation: none !important;
    }
  }

  .active {
    border: none;
    background: #f0dfd1;
  }
}

.title_container {
  margin-left: 16px;
  overflow: hidden;
}

.header_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 24px 0 24px;

}

.wrapContainer{
  border-left: 1px solid var(--main-border-color);
  overflow-y: auto;
}

.title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size-xxl;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: var(--stock-name-color);
}

.sub_title {
  font-family: @font-family-roboto, sans-serif;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 12px;
  line-height: 14px;
  color: var(--table-header-color);
  display: flex;
  align-items: center;
}

.price_change {
  border-radius: 4px;

  font-family: @font-family-roboto, sans-serif;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
}

.positive {
  color: @positive-color;
}

.negative {
  color: @negative-color;
}

.spin {
  position: relative !important;
}

.spin_cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.logo {
  align-items: center;
  display: flex;
  cursor: pointer;

  img {
    width: 64px;
  }
}

.type_bg {
  background: @blue;
  padding: 0 10px;
  border-radius: 4px;
}

.type {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size-sm;
  line-height: @font-size;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
  border-radius: 4px;
  padding: 2px 0;
  margin-left: @margin-xs;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instrument_currently_disabled {
  color: @excitement-color_minus;
  padding: 12px 2px;
  font-family: @font-family-futura;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  display: block;
}
.wrap_schedule{
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.dragIcon {
  cursor: move;
}
