.wrapPage{
  position: relative;
  margin-bottom: 24px;
}
.wrapChart {
  margin-top: 25px;
}

.footer {
  display: flex;

  > div:nth-child(1) {
    margin-right: 24px;
  }
  > div:nth-child(2) {
    width: 100%;
  }
}

.footerWithOneBlock {
  display: block;

  > div:nth-child(1) {
    margin-right: 0;
  }
}

.logo {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
