@import '~theme/variables.less';

.overlayWrap {
  :global {
    .ant-modal {
      padding-bottom: 0;
    }
  }

}

.wrap {
  :global {
    .ant-modal-content {
      min-width: 328px;
    }
    .ant-modal-body {
      padding: 0;
      overflow-y: auto;
      background-color: var(--modal-bg-color2);
    }
  }

}
.header{
  margin: 16px 24px 0 24px;
  font-family: @font-family-futura;
  font-size: 14px;
  line-height: 18px;
  color: var(--2D3436_FFFFFF);
  text-align: center;
}
.red{
  color: @negative-color;
}
.green{
  color: @positive-color;
}
.gray {
  color: var(--84949A-BCBCBC);
  font-size: 12px;
  line-height: 14px;
}
.content{
  padding: 8px 24px 0 24px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  .label, .value {
    font-family: @font-family-roboto;
    font-size: 12px;
    line-height: 14px;
  }
  .label{
    white-space: nowrap;
    color: var(--84949A-BCBCBC);
    margin-right: 24px;
  }
  .value{
    font-weight: 500;
    text-align: right;
    color: var(--2D3436_FFFFFF);
    a {
      color: var(--2D3436_FFFFFF);
    }
  }
}
.ric{
  display: flex;
  cursor: pointer;
  align-items: center;
  color: var(--2D3436_FFFFFF);
}
.linkStock{
  color: var(--2D3436_FFFFFF);
}

.button{
  width: calc(100% - 48px);
  height: 40px;
  margin: 12px 24px 24px;
  svg {
    margin-right: 8px;

    path {
      fill: var(--FFF-2D2D2D);
    }
  }
}
