@import '~theme/variables.less';

.menu {
  display: flex;
  flex-direction: row;
  height: calc(100% - 34px);
}

.column {
  flex: 1;
  overflow: auto;
  height: 100%;


}

.border {
  border-right: 1px solid var(--main-border-color);
}

.scroll {
  padding: 0;
}

.item {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Gray / 900 */

  color: var(--button-color);

  padding: 7px 24px;

  &:hover {
    background: var(--search-dd-hover);
    cursor: pointer;
  }

  &.hasSelected {
    padding: 8px;
  }
}

.active {
  background: var(--search-dd-hover) !important;
}

.dropdown {
  height: 300px;
  width: 400px;

  padding: 0;

  background: var(--modal-bg-color);
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.12);
  border-radius: 0;

  .content {

    height: 100%;

    .row {
      display: flex;
      align-items: center;

      padding-left: 16px;

      padding-top: 6px;
      padding-bottom: 6px;

      line-height: @line-height-xl;
      font-weight: @font-weight-500;

      direction: ltr;
      cursor: pointer;

      max-width: 100%;
      min-width: 0;

      &:hover {
        background-color: @hover-test-color;
      }

    }
  }
}

.header {
  border-bottom: 1px solid var(--main-border-color);
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: var(--B2BEC3-BCBCBC);
  cursor: default;

  padding: 5px 10px;

  flex-wrap: wrap;

  width: 100%;
  flex: 1;
  justify-content: space-between;

  margin-left: 3px;

  path {
    fill: var(--filter-cross-color);
  }
}

.header_icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #BDBEC2;
}

.search_text {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}
