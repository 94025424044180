@import '~theme/variables.less';

.wrapper {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.marker {
  background: var(--dropdown-highlighting-color);
  border-radius: 2px;
}

.title {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--search-dd-title);
  margin: 0 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fix {
  min-width: 0;
  display: flex;
}

.sub_title {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text-color);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo_20 {
  min-width: 20px;
  width: 20px;
  max-width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 4px;
  letter-spacing: -0.041px;
  color: #c19b7b;
  font-family: 'Futura PT', sans-serif;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.height_20 {
  width: 20px;
  height: 20px;
  min-height: 20px;

  border-radius: 2px;
  background: #FFFFFF;
  border: 1px solid #CCCDD0;

  display: flex;

  justify-content: center;
  align-items: center;

  img {
    max-width: 20px;
    max-height: 20px;
    border-radius: 2px;
    padding: 1px;
  }
}
