@import '~theme/variables.less';

.content{
  background-color: var(--sidebar-btn-color-active);
  font-family: @font-family-roboto;
  height: calc(100vh - 159px);
  overflow-y: auto;
  overflow-x: hidden;
}
.header {
  padding: 16px;
  color: var(--data-subscription-header-text-color);
  border-bottom: 1px solid var(--EAEEF1-191A1C);

  .title{
    font-family: @font-family-futura;
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    color: var(--2d3436-FFFFFF);
  }
  .subtitle {
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: @font-weight-400;
    font-size: 12px;
    line-height: 14px;
    margin: 12px 0;
    color: var(--2d3436-FFFFFF);

  }
}

.subscriptionWrap{
  display: grid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer {
  padding: 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
  max-height: 50%;
  overflow: auto;
  border-top: 1px solid var(--EAEEF1-191A1C);
}

.info{
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 11px;
  line-height: 13px;

  /* grey / 500 */

  color: var(--84949A-BCBCBC);
}

.infoRow {
  margin-top: 8px;
}
