@import '~theme/variables.less';

.title {
  :global {
    h1.ant-typography {
      font-size: 22px;
      font-weight: @font-weight-500;
      font-style: normal;
      line-height: 28px;
      text-align: center;
      color: var(--main-text-color);
      width: 172px;
      margin-bottom: 16px;
    }
  }
  margin-top: 65px;
}
.modalDep {
  :global {
    .ant-modal-content{
      min-width: 800px;
    }
    .ant-modal-body {
      padding: 40px 0 0 0;
    }
  }
}

.imgPoints{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.firstSlide {
  margin-left: 40px;
}
.img{
  width:320px;
  height:360px;
  margin:0 16px
}
.MainBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.firstBlock{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.right{
  cursor: pointer;
}

.secondBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 362px;
  height: 106px;
  margin-right: 40px;
  margin-left: 38px;
}

.left {
  min-width: 25px;
}

.leftArrow {
  cursor: pointer;
  transform: rotate(180deg);
  margin-left: 16px;
}

.pointsBlock{
  height: 6px;
   width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
}

.points {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #CCCDD0;
 margin: 0 5px;
}
.selectPoint {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #2D3436;
  margin: 0 5px;
}

.center {
  text-align: center;
  color: var(--table-header-color);
  width: 260px;
}


