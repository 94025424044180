@import '~theme/variables.less';


.radioGroup {
  width: 100%;
}

.wrapperRadio {
  width: 100%;

  :global {
    .ant-radio-wrapper {
      display: flex;

      .ant-radio {
        .ant-radio-inner {
          border: 2px solid @primary-3;
          background-color: var(--sidebar-btn-color-active);
          width: 20px;
          height: 20px;
          box-shadow: none;

          &::after {
            background-color: @positive-color;
            width: 10px;
            height: 10px;
            top: 11px;
            left: 11.2px;
          }
        }
        &:hover {
          .ant-radio-inner {
            border-color: @primary-3;
          }
        }
      }
      .ant-radio-checked .ant-radio-inner::after {
        transform: scale(1);
      }
      .ant-radio.ant-radio-checked {
        .ant-radio-checked:hover {
          .ant-radio-inner {
            border-color: @primary-3;
          }
        }
        .ant-radio-inner {
          border-color: @positive-color;
        }
      }
    }
  }
}

.radioBox {
  width: 100%;
  &:hover {
    background: var(--F4F5F6-444444);
  }
}
