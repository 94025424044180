.fullPageLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
  user-select: none;
  z-index: 999;

  &.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(16, 16, 16, 0.5);
    z-index: 999;

    .spinner {
      position: absolute;

      height: 100%;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.container {
  height: 100%;

  &.loading {
    position: relative;
    clear: both;
    overflow: hidden;
    opacity: 0.3;
    user-select: none;
    pointer-events: none;

    .spinner {
      position: absolute;

      height: 100%;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
