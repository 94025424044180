@import '~theme/variables.less';

.searchbar {
  min-width: 255px;
  min-height: 30px;

  margin: 0;

  background-color: var(--EAEEF1-191A1C);
  border-radius: 4px;
  color: var(--main-text-color);
  display: flex;
  justify-content: center;

  .crossButton {
    path {
      fill: var(--filter-cross-color);
    }
  }
  &.newVariant{
    height: 32px;
    background: var(--search-bg-color);
    border: 1px solid var(--search-border-color);
    box-sizing: border-box;
    border-radius: 4px;
  }

  &.background {
    background-color: var(--modal-bg-color);
    border: 1px solid var(--setting-filter-border-color);
    box-shadow: none;
    border-radius: 4px;
  }

  &.bordered {
    .ant-input-affix-wrapper {
      border-radius: 5px;
    }
  }

  :global {
    .ant-input-suffix {
      .ant-input-clear-icon {
        display: none;
      }
    }
  }

  &.magnifier {
    :global {
      .ant-input-suffix {
        .ant-input-clear-icon {
          display: none;
        }
      }
    }
  }

  :global {
    .ant-input-affix-wrapper {
      border: none;
      background-color: transparent;
      font-size: 18px;

      .ant-input {
        border-radius: 0 !important;
        background-color: transparent;
        color: var(--main-text-color);
      }
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
  }
}

div.tooltip {
  z-index: 10001;

  :global {
    .ant-tooltip-inner {
      width: max-content;
    }
  }
}
