@import '~theme/variables.less';

.page_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;

  background: var(--loader-bg-color);
  background: var(--error-modal-bg-picture);
  background-size: cover;
  min-width: @screen-min-width;
  min-height: 102%;
  top: -2%;
}

.sign_in_container {
  background: var(--loader-container-bg-color);
  border: 1px solid var(--main-border-color);
  box-sizing: border-box;
  border-radius: 12px;
  width: 280px;
  height: 360px;
  text-align: center;

  & .logo {
    margin-top: 72px;
    text-align: center;

     img {
       width: 170px;
       height: 22px;
     }
  }

  .logos {
    img {
      margin: 8px;
      height: 48px;
      width: 48px;
    }
  }

  .unsupported {
    font-family: Futura PT, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: var(--main-text-color);
    margin: 30px 62px 10px;
  }

  .use {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--loader-hint-color);
    margin: 8px 30px 54px;
  }

  .download {
    color: @button-main;
    font-weight: 700;
  }

  & .hint {
    margin-top: 80px;
    margin-bottom: 10px;
    font-family: @font-family-roboto;
    font-style: normal;
    font-weight: normal;
    font-size: @font-size;
    line-height: 16px;
    text-align: center;
    color: var(--loader-hint-color);
  }

  & .button {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px;
    margin-bottom: 60px;

    :global {
      .button {
        padding: 20px 90px;
      }
    }
  }
}
