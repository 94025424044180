@import '~theme/variables.less';

.controls {
  margin: 12px 28px -16px 28px;

  > button {
    height: 42px;
  }

  > button:nth-child(2) {
    margin-top: 8px !important;
  }
}

.filter_delete_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :global {
    .ant-checkbox {
      margin-left: 10px;
    }
    .ant-checkbox-wrapper > span:nth-child(2) {
      padding: 0 10px;
    }
  }
}

.filter_delete_title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--main-text-color);
  margin: 5px 25px;
}

.filter_delete_text {
  text-align: center;
  margin: 14px 28px 0 28px;
}

.filter_delete_description {
  text-align: center;
  margin: 14px 28px 0 28px;
}

.filter_delete_checkbox {
  display: flex;
  align-items: center;
  color: var(--table-header-color);
  margin-top: 20px;
}

.filter-wrapper {
  height: calc(100vh - 48px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.filter_popover_body {
  padding: 15px 15px;
  max-height: 30vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter_popover_footer {
  padding: 12px 24px;
  border-top: 1px solid var(--main-border-color);

  :global {
    #button {
      width: 128px;
      height: 32px;
      margin: auto;
      padding: 0;
    }
  }
}

.filter_popover_title {
  display: flex;
  justify-content: space-between;
  width: 100%;

  path {
    fill: var(--filter-cross-color);
  }
}

.default {
  background: var(--filter-background-color-empty) !important; //#E6E6E7
  border: 1px solid var(--filter-tag-border-color-empty) !important;
}

:global {
  .filter_popover, .filter_popover_menu {

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-title {
      font-family: @font-family-futura;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      /* identical to box height */

      display: flex;
      align-items: center;
      cursor: default;

      color: var(--filter-placeholder-color);
      padding: 8px 10px 8px 15px;
      border-bottom: 1px solid var(--main-border-color);
    }

    .ant-popover-inner {
      background: var(--modal-bg-color);
      /* shadow */
      width: fit-content;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
  }
}

.tag {
  height: 28px;
  background: var(--filter-background-color-active); //#CCCDD0
  border-radius: 6px;
  //border-color: #2db7f5;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 6px 0 6px 10px;
  margin: 5px;

  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  color: var(--filter-title-color); //#2B2D3A
  border: none;//!important
  cursor: pointer;

  path {
    fill: var(--search-cross-color);
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: 0 0 172px;
  border-bottom: 1px solid var(--main-border-color);
  padding: @padding-sm;

  .title {
    :global {
      h4.ant-typography {
        font-weight: @font-weight-500;
        font-size: 22px;
        line-height: 18px;
        margin: 0;
      }
    }
  }

  .sort_icon {
    height: 42px;
    border: 1px solid var(--main-border-color);
    border-radius: 4px;
    padding: 11px;
    cursor: pointer;
  }

  .clear-all {
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;

    :global {
      span.ant-typography {
        font-size: @font-size-sm;
        line-height: @line-height-md;
        font-weight: @font-weight-500;
        color: @primary-3;
      }
    }
  }
}

.content {
  flex: 1 1 auto;
  overflow: auto;


  padding: @padding-sm;
  border-bottom: 1px solid var(--main-border-color);
}

.apply-block {
  flex: 0 0 auto;
  padding: @padding-sm;
}
