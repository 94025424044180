@import '~theme/variables.less';

.card {
  padding: 14px 16px 18px 16px !important;
  border-bottom: 1px solid var(--EAEEF1-191A1C);
  &.highLite{
    border-bottom: 1px solid rgba(239, 186, 88, 0.3);
    border-top: 1px solid rgba(239, 186, 88, 0.3);
    background-color: var(--highLited-subscription-bundle);
  }
  .wrapCheckBox {
    display: flex;
    font-size: @font-size-sm;
    line-height: 14px;
    color: var(--main-text-color);

    .checkbox {
      margin: 3px 11px 3px 3px;
      :global{
        .ant-checkbox-disabled .ant-checkbox-inner {
          background-color: #8BCAA4;
        }
      }
    }
    .exchangename{
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 2px;
      max-width: 240px;
    }
    .country{
      font-size: 14px;
      line-height: 16px;
    }
  }
  .autoRenewSwitch {
    margin-right: 4px;
  }
}
span.status {
  color: var(--B2BEC3-BCBCBC);

  &.active {
    color: @positive-color;
  }
}

.cardRow{
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  font-size: 14px;
  margin-top: 8px;
  .cardRowTitle{
    color: var(--table-header-color);
  }
  .cardRowValue{
    color: var(--main-text-color);
    :global {
      .ant-statistic-content {
        color: var(--main-text-color);
      }
      .ant-typography {
        color: var(--main-text-color);
      }
    }
  }
}
.canceledInfo{
  margin-top: 12px;
  display: grid;
  grid-template-columns: 32px 1fr;
  align-content: center;
  align-items: center;
  background: var(--allocation-dropdown-separator_dark);
  border-radius: 8px;
  .infoIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    background-color: var(--data-subscription-part);
    img{
      width: 16.67px;
      height: 16.67px;
    }
  }
  .textInfo {
    color: var(--data-subscription-text-color);
    font-size: 12px;
    line-height: 14px;
    margin: 0 16px;
    text-align: center;
  }

}
