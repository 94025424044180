@import '~theme/variables.less';

.wrapAmount {
  display: flex;
  justify-content: space-between;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 14px;
  line-height: 16px;
  /* Gray / 900 */

  color: var(--2d3436-FFFFFF);
}

.wrapAmountValue {
  display: flex;
  flex-direction: column;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  align-items: end;
  text-align: right;

  /* Gray / 900 */

  color: var(--2d3436-FFFFFF);
}
