@import '~theme/variables.less';

.error_container {
  background: var(--loader-bg-color);
  background: var(--error-modal-bg-picture);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-width: @screen-min-width;
  min-height: 102%;
  top: -2%;

 :global {
   & .ant-empty {
     text-align: center;
     justify-content: center;
     align-items: center;
     display: flex;
     flex-direction: column;
   }
   & .ant-empty-footer {
     margin-top: 24px !important;
   }
 }
}

.no_active_container {
  display: flex;
  background: var(--loader-container-bg-color);
  border: 1px solid var(--main-border-color);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 24px;
}

.no_active_img {
  display: flex;
  width: 330px;
  height: 297px;
  border-radius: 12px;
  background-color: var(--main-bg-color);
  align-items: flex-end;

  > img {
    height: 270px;
    margin-left: 10px;
  }
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.log_out {
  position: absolute;
  right: 0;
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size;
  line-height: 16px;
  color: var(--table-header-color);
  cursor: pointer;
}

.title {
  font-family: @font-family-futura;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size-xxl;
  line-height: 28px;
  text-align: center;
  color: var(--main-text-color);
  margin-top: 56px;
  margin-bottom: 12px;
  padding: 0 40px;
}

.subTitle {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: @font-weight-500;
  font-size: @font-size;
  line-height: 16px;
  text-align: center;
  color: var(--table-header-color);
  margin-bottom: 50px;
}

.description {
  font-family: @font-family-roboto;
  font-style: normal;
  font-weight: normal;
  font-size: @font-size;
  line-height: 16px;
  text-align: center;
  color: var(--title-color);
  width: 246px;
}
