@import '~theme/variables.less';

.wrap{
  background-color: var(--sidebar-btn-color-active);
}

.header{
  padding: 16px 24px;
  height: 60px;
  border-bottom: 1px solid var(--main-separator-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: @font-family-futura;
  font-size: 22px;
  line-height: 28px;
  color: var(--title-color);
}
.notificationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.content{
    height: calc(100vh - 50px - 60px);
    overflow-y: auto;

  .empty {
    padding-top: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div:nth-child(2) {
      margin-top: 20px;
    }

    .empty_text {
      text-align: center;
      white-space: pre-line;
      color: var(--84949A-BCBCBC);
      line-height: 16px;
    }
  }

  path {
    fill: var(--no-trading-data-svg-background);
    stroke: var(--no-trading-data-svg-stroke);
  }

  ellipse {
    fill: var(--no-trading-data-svg-ellipse);
  }
}

.padding0_24 {
  padding: 0 24px;
}

.storyWrap {
  margin-top: 25px;

  & > div {
    padding: 0 24px;
  }
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
}
