@import '~theme/variables.less';

.font-styles(@color, @fs, @lh) {
  color: @color;
  font-size: @fs;
  font-weight: 400;
  line-height: @lh;
  white-space: pre-wrap;
}

.parentBlocks {
  display: flex;
  align-items: center
}

.modal {

  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-carousel .slick-list {
      @media screen and (min-width: 1440px) and (max-height: 1100px) {
        max-height: 930px;
      }
      @media screen and (min-width: 1440px) and (max-height: 957px) {
      max-height: 730px;
      }
      @media screen and (min-width: 1440px) and (max-height: 760px) {
        max-height: 600px;
      }
    }
  }

  .title {
    padding: 24px 24px 0;

    .headline {
      margin-bottom: 4px;
      max-width: 95%;

      :global {
        .ant-typography {
          .font-styles(var(--main-text-color), 24px, 28.13px);
        }
      }
    }

    .dateline {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      :global {
        .ant-typography-secondary {
          .font-styles(var(--table-header-color), 16px, 22px);
        }
      }
    }

    .marginBottom {
      margin-bottom: 16px;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;

      .tag {
        height: 24px;
        padding: 5px 8px;
        margin-right: 0;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .font-styles(var(--2d3436-FFFFFF), 12px, 14px);

        background-color: var(--EAEEF1-5E5F61);
        border: none;
        border-radius: 4px;

        cursor: pointer;

        &:hover {
          background: var(--B2BEC3-303133) !important;
        }
      }
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;


    .empty_text {
      font-family: @font-family-roboto;
      font-weight: @font-weight-400;
      color: #B2BEC3;
      padding-top: 10px;
      line-height: 16px;
      font-size: @font-size;
    }

    path {
      fill: var(--no-trading-data-svg-background);
      stroke: var(--no-trading-data-svg-stroke);
    }

    ellipse {
      fill: var(--no-trading-data-svg-ellipse);
    }
  }
  .content {
    margin: 0 24px 16px;
    padding-right: 6px;

    max-height: 70vh;
    @media (max-width: 1440px) {
      max-height: 52vh;
    }

    overflow-y: auto;

    .newsText {
      margin-top: 16px;
      color: var(--main-text-color);

      a {
        color: var(--main-text-color);
      }

      html, body {
        min-width: unset;
        background-color: transparent;
        color: var(--main-text-color);
        line-height: 16.41px;

        a {
          color: var(--main-text-color) !important;
        }
      }
    }
  }
}
